import {Pipe, PipeTransform} from '@angular/core';
import {formatDistance} from 'date-fns'
import {es} from 'date-fns/locale'


@Pipe({
  name: 'formatDistance',
  standalone: true,
})
export class GetFormatDistancePipe implements PipeTransform {
  transform(value?: string): string {
    if (!value) return '-';

    try {
      return formatDistance(new Date(value), new Date(), { addSuffix: true, locale: es });
    } catch (ex) {
      return '-';
    }
  }
}
