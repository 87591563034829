export const PLATFORM_CONFIG = {
  DATE: {
    FORMAT: 'dd/MM/yyyy',
  },
  PAGINATOR: {
    ITEMS_PER_SCROLL: 40,
  },
  DATABASE: {
    DEFAULT_COUNTRY_ID: '42z1RJBkUo-P1Am3pGad2', // México Id
    GUANAJUATO_STATE_ID: 'WqahXWa_7NvI8WcuAYZsg', // Guanajuato Id
    DEFAULT_CURRENCY: 'SMqKCjMXekvCDLpPDZT8C' // PESO MEXICANO
  },
  SKELETON: {
    DELAY: 200
  }
};
