import {AuthState} from '@auth/state/auth.state';
import {PlatformState} from '@shared/state/platform.state';
import {LoaderState} from '@loader/state/loader.state';
import {StateClassInternal} from '@ngxs/store/src/internal/internals';
import {CatalogState} from '@platform/state/catalog.state';
import {UserState} from '@user/state';
import {
  OrganizationCertificationState,
  OrganizationState,
  OrganizationMemberState,
  OrganizationContactState, OrganizationGovernmentState,
} from '@organization/state';
import {SidebarState} from '@sidebar/state/sidebar.state';
import {MenuState} from '@menu/state/menu.state';
import { SupplierState } from '@supplier/state/supplier.state';
import { BuyerState } from '@buyer/state/buyer.state';
import {ProductState} from '@product/state/product.state';
import {ServiceState} from '@service/state/service.state';
import {PhoneState} from './features/phone/state/phone.state';
import {OpportunityState} from '@opportunity/state/opportunity.state';
import {NotificationState} from './features/notification/state/notification.state';
import {DocumentState} from '@file-record/state/document.state';
import {GlobalConfigState} from './features/global/state/global-config.state';
import {ConnectionRequestState} from '@connection-request/state/connection-request.state';
import {EventState} from '@event/state/event.state';
import {OrganizationSupplierNumberState} from '@organization/state/organization-supplier-number.state';
import {TrainingState} from '@training/state/training.state';
import {AccountCheckState} from '@shared/components/ui-elements/account-check/state/account-check.state';

export const NGX_STATES: StateClassInternal[] = [
  PlatformState,
  LoaderState,
  AuthState,
  CatalogState,
  UserState,
  OrganizationState,
  OrganizationMemberState,
  SidebarState,
  MenuState,
  OrganizationCertificationState,
  SupplierState,
  BuyerState,
  ProductState,
  ServiceState,
  OrganizationContactState,
  PhoneState,
  OpportunityState,
  NotificationState,
  DocumentState,
  GlobalConfigState,
  ConnectionRequestState,
  EventState,
  OrganizationSupplierNumberState,
  OrganizationGovernmentState,
  TrainingState,
  AccountCheckState,
]
