import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot} from '@angular/router';
import {OrganizationService} from '@organization/services/organization.service';
import {AuthService, StorageService} from '@shared/services';
import {NzMessageService} from 'ng-zorro-antd/message';
import {PLATFORM_MESSAGES} from '@shared/config';
import {StorageItemEnum} from '@shared/services/storage/enums/storage-item.enum';
import {environment} from '@env/environment';

export const hasOrganizationGuard: CanActivateFn = async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> => {
  const authService: AuthService = inject(AuthService);
  const storageService: StorageService = inject(StorageService);
  const organizationService: OrganizationService = inject(OrganizationService);
  const router: Router = inject(Router);
  const nzMessageService: NzMessageService = inject(NzMessageService);

  let hasSession: boolean = authService.hasCredentials();
  if (!hasSession) {
    storageService.clear();
    storageService.set(StorageItemEnum.RETURN_TO_URL, state.url);
    await router.navigate([environment.DEFAULT_PAGE_TO_LOAD]);
    return false;
  }

  try {
    const userBelongsToOrganization: boolean = await organizationService.userBelongsToAnyOrganization();
    hasSession = authService.hasCredentials();
    // checar en LS si la cuenta fue eliminada
    if (!hasSession) {
      storageService.clear();
      await router.navigate([environment.DEFAULT_PAGE_TO_LOAD]);
      nzMessageService.warning(PLATFORM_MESSAGES.USER.DELETED_ACCOUNT);
      return false;
    }
    if (!userBelongsToOrganization) {
      await router.navigate(['/', 'auth', 'register'], {queryParams: {step: 'organizationType'}});
      nzMessageService.warning(PLATFORM_MESSAGES.ORGANIZATION.COMPLETE_REGISTRATION);
      return false;
    }

    if (!organizationService.isActiveOrganization()) {
      storageService.clear();
      await router.navigate([environment.DEFAULT_PAGE_TO_LOAD]);
      nzMessageService.warning(PLATFORM_MESSAGES.ORGANIZATION.DELETED);
      return false;
    }

    return true;
  } catch (err) {
    // Go to default page to load
    storageService.clear();
    await router.navigate([environment.DEFAULT_PAGE_TO_LOAD]);
    return false;
  }
};
