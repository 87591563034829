import {ErrorTypeEnum} from '@shared/class/errors/enums/error-type.enum';
import {ErrorException} from '@shared/class/errors/error.exception';

export class BadRequestException extends ErrorException {
  constructor(message: string = 'Solicitud incorrecta', internalCode?: string) {
    super(message);
    this.name = ErrorTypeEnum.BAD_REQUEST;
    this.httpCode = 400;
    this.internalCode = internalCode;
  }
}
