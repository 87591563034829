export namespace LoaderActions {
  const state: string = 'Loader';

  export class Show {
    static readonly type: string = `[${state}] Show`;
    constructor() {}
  }

  export class Hide {
    static readonly type: string = `[${state}] Hide`;
    constructor() {}
  }
}
