import {IAnyObject} from '@shared/interfaces';
import {EventGuestRoleEnum} from '@event/enums';

export const EVENT_GUEST_ROLE_VALUES: IAnyObject = {
  [EventGuestRoleEnum.EXHIBITOR]: 'Expositor',
  [EventGuestRoleEnum.MAIN_SPEAKER]: 'Expositor principal',
  [EventGuestRoleEnum.VIP_GUEST]: 'Invitado VIP',
  [EventGuestRoleEnum.PARTNER]: 'Asociado',
  [EventGuestRoleEnum.PANELIST]: 'Panelista',
  [EventGuestRoleEnum.SPONSOR]: 'Patrocinador',
  [EventGuestRoleEnum.BUYER]: 'Comprador',
  [EventGuestRoleEnum.SUPPLIER]: 'Proveedor',
}
