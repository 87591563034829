<section class="dashboard" [class.!bg-cyan-900]="(organizationType$ | async) === OrganizationTypeEnum.BUYER">
  <header>
    <main-menu></main-menu>
    <!-- message organization validation  -->
    <warning-message
      *ngIf="organization?.validationStatus === OrganizationValidationStatusEnum.MANUALLY_REJECTED || organization?.validationStatus === OrganizationValidationStatusEnum.REJECTED_BY_THE_SYSTEM"
      [global]="true"
      [message]="'Tu empresa se encuentra actualmente bloqueada, no podrás realizar acciones dentro de la plataforma, puedes ponerte en contacto con el administrador.'"></warning-message>

    <info-message
      *ngIf="organization?.validationStatus === OrganizationValidationStatusEnum.TO_BE_VALIDATED"
      [global]="true"
      [message]="'Tu empresa se encuentra actualmente en periodo de validación. Pronto podrás tener acceso a todas las características de la plataforma.'"></info-message>
    <!-- / message organization validation  -->
  </header>
  <main [class.has-important-message]="organization?.validationStatus === OrganizationValidationStatusEnum.TO_BE_VALIDATED || organization?.validationStatus === OrganizationValidationStatusEnum.MANUALLY_REJECTED || organization?.validationStatus === OrganizationValidationStatusEnum.REJECTED_BY_THE_SYSTEM">
    <router-outlet></router-outlet>
  </main>
  <footer></footer>
</section>

<account-check></account-check>

<!--<wz-chatbot />-->
