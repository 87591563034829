import {Injectable} from '@angular/core';
import {StorageItemEnum} from '@shared/services/storage/enums/storage-item.enum';
import {IStorageCredentials, IStorageOrganization, IStorageUser} from '@shared/services/storage/interfaces';
import {IOrganizationWithRelations} from '@organization/interfaces';
import {ICredentials} from '@auth/interfaces';
import {IUserWithRelations} from '@user/interfaces';
import {OrganizationTypeEnum} from '@organization/enums';
import {Store} from '@ngxs/store';
import {OrganizationActions, OrganizationState} from '@organization/state';
import {firstValueFrom} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  constructor(
    private store: Store
  ) {}

  get<T>(key: StorageItemEnum): T | null {
    const item: string | null = localStorage.getItem(key);
    if (!item) return null;

    try {
      return JSON.parse(item);
    } catch (ex) {
      return null;
    }
  }

  set<T>(key: StorageItemEnum, value: T): void {
    localStorage.setItem(key, JSON.stringify(value));
  }

  remove(key: StorageItemEnum): void {
    localStorage.removeItem(key);
  }

  clear(): void {
    localStorage.clear();
  }


  setUser(user: IUserWithRelations): void {
    this.set<IStorageUser>(StorageItemEnum.USER, {
      id: user.id!,
      name: user.name,
      lastName: user.lastName,
      email: user.email,
      profilePicture: user.Media?.[0]?.filePath,
    });
  }

  getUser(): IStorageUser | null {
    return this.get<IStorageUser>(StorageItemEnum.USER);
  }

  setCredentials(credentials: ICredentials): void {
    this.set<IStorageCredentials>(StorageItemEnum.CREDENTIALS, {
      accessToken: credentials.accessToken,
      refreshToken: credentials.refreshToken,
    });
  }

  getCredentials(): IStorageCredentials | null {
    return  this.get<IStorageCredentials>(StorageItemEnum.CREDENTIALS);
  }

  setOrganization(organization: IOrganizationWithRelations): void {
    this.set<IStorageOrganization>(StorageItemEnum.ORGANIZATION, {
      id: organization.id!,
      tradeName: organization.tradeName,
      organizationType: organization.organizationType, // BOTH - buyer | supplier
      legalPersonalityType: organization.legalPersonalityType,
      legalName: organization.legalName,
      acceptanceOfTerms: organization.acceptanceOfTerms,
      deletedAt: organization.deletedAt,
      validationStatus: organization.validationStatus!,
    });
  }

  getOrganization(): IStorageOrganization | null {
    return this.get<IStorageOrganization>(StorageItemEnum.ORGANIZATION);
  }

  setOrganizationType(): void {
    const currentType: OrganizationTypeEnum | null = this.get<OrganizationTypeEnum>(StorageItemEnum.ORGANIZATION_TYPE);
    if (currentType) return;
    const organization: IStorageOrganization | null = this.get<IStorageOrganization>(StorageItemEnum.ORGANIZATION);
    this.set<OrganizationTypeEnum>(StorageItemEnum.ORGANIZATION_TYPE, organization?.organizationType === OrganizationTypeEnum.BOTH ? OrganizationTypeEnum.BUYER : organization?.organizationType!);
    this.store.dispatch(new OrganizationActions.SetOrganizationType(currentType!));
  }

  async toggleOrganizationType(): Promise<void> {
    let currentType: OrganizationTypeEnum | null = this.get<OrganizationTypeEnum>(StorageItemEnum.ORGANIZATION_TYPE);

    const newType: OrganizationTypeEnum = currentType === OrganizationTypeEnum.BUYER ? OrganizationTypeEnum.SUPPLIER : OrganizationTypeEnum.BUYER;
    this.set<OrganizationTypeEnum>(StorageItemEnum.ORGANIZATION_TYPE, newType);
    await firstValueFrom(this.store.dispatch(new OrganizationActions.SetOrganizationType(newType)));
  }
}
