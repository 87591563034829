import {ActionPermitEnum} from '@shared/enums/action-permit.enum';
import {IAnyObject} from '@shared/interfaces';

export const ROLE_PERMISSIONS_VALUES: IAnyObject<string> = {
  [ActionPermitEnum.CREATE]: 'Creación',
  [ActionPermitEnum.UPDATE]: 'Actualización',
  [ActionPermitEnum.DELETE]: 'Eliminación',
  [ActionPermitEnum.READ]: 'Visualización',
  [ActionPermitEnum.MANAGE]: 'Administrar',
  [ActionPermitEnum.ALL]: 'Todos los permisos'
};

