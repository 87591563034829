import {Component, Input} from '@angular/core';

@Component({
  selector: 'error-message',
  template: `
    <p class="p-4 bg-red-50 flex items-center gap-2 rounded-lg text-red-500 text-sm">
      <i class="text-lg las" [ngClass]="this.icon"></i>
      {{ this.message }}
    </p>
  `,
})
export class ErrorMessageComponent {
  @Input() icon: string = 'la-times';
  @Input({required: true}) message!: string;
}
