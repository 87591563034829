import {PrismaFilter} from '@shared/services/base/interfaces/prisma-filter.interface';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {environment} from '@env/environment';
import {BaseService} from '@shared/services';
import {ErrorHandlerService} from '@shared/services/error-handler.service';
import {
  IEvent,
  IEventDate,
  IEventFilter,
  IEventWithRelations,
  IRsvpOrganization,
  IRsvpOrganizationCreate
} from '@event/interfaces';
import {DataBaseServiceResponse} from '@shared/services/base/interfaces/data-base-service-response.interface';
import {firstValueFrom} from 'rxjs';
import {UtilityClass} from '@shared/class/utils';
import {PLATFORM_CONFIG} from '@shared/config';
import {DocumentUtilClass} from '@file-record/document-util.class';

export interface IEventState {
  events: IEventWithRelations[];
  upcomingEvents: IEventWithRelations[];
  publicEvents: IEventWithRelations[];
  selectedId: string | null;
  count: number;
  thereAreMoreEvents: boolean;

  rsvpOrganizations: IRsvpOrganization[];
  rsvpOrganizationSelected?: string;
}

const _DEFAULT_EVENT: IEventState = {
  events: [],
  upcomingEvents: [],
  publicEvents: [],
  selectedId: null,
  count: 0,
  thereAreMoreEvents: true,

  rsvpOrganizations: [],
  rsvpOrganizationSelected: undefined,
}

export namespace EventActions {

  export class GetRsvpOrganization {
    static readonly type: string = '[Event] Get Rsvp Organization';
    constructor(public organizationId: string, public filters: PrismaFilter<IRsvpOrganization>) { }
  }

  export class PostRsvpOrganization {
    static readonly type: string = '[Event] Post Rsvp Organization';
    constructor(public organizationId: string, public data: IRsvpOrganizationCreate) { }
  }

  export class DownloadAttachment {
    static readonly type: string = '[Event] Download Event Media';
    constructor(public id: string, public eventMediaId: string, public mimeType: string, public fileName: string) {}
  }

  export class FindUpcoming {
    static readonly type: string = '[Event] Find Upcoming';
    constructor() {}
  }

  export class Find {
    static readonly type: string = '[Event] Find';
    constructor(public filters: IEventFilter, public isAddedTo?: boolean) {}
  }

  export class FindAvailable {
    static readonly type: string = '[Event] Find Available';
    constructor(public eventFilter: IEventFilter, public isAddedTo?: boolean) {}
  }

  export class FindPublic {
    static readonly type: string = '[Event] Find Public';
    constructor() {}
  }

  export class Count {
    static readonly type: string = '[Event] Count';
    constructor(public filters: PrismaFilter<IEventWithRelations>) {}
  }

  export class FindById {
    static readonly type: string = '[Event] FindById';
    constructor(public id: string, public filters?: PrismaFilter<IEventWithRelations>) {}
  }

  export class SetEventSelectedId {
    static readonly type: string = '[Event] Set Event Selected Id';
    constructor(public id: string) {}
  }
  export class Clear {
    static readonly type: string = '[Event] Clear';
    constructor() {}
  }

}

@State<IEventState>({
  name: 'EventState',
  defaults: _DEFAULT_EVENT
})
@Injectable()
export class EventState {
  private readonly SERVER: string = environment.SERVER;
  constructor(
    private baseService: BaseService,
    private errorHandlerService: ErrorHandlerService,
  ) {}

  @Selector()
  static getEvents({events}: IEventState): IEventWithRelations[] {
    return events;
  }

  @Selector()
  static getUpcomingEvents({upcomingEvents}: IEventState): IEventWithRelations[] {
    return upcomingEvents;
  }

  @Selector()
  static getRsvpOrganization({rsvpOrganizations}: IEventState): IRsvpOrganization[] {
    return rsvpOrganizations;
  }

  @Selector()
  static getPublicEvents({publicEvents}: IEventState): IEventWithRelations[] {
    return publicEvents;
  }

  @Selector()
  static getEventSelected({events, selectedId}: IEventState): IEventWithRelations | null {
    return events.find(({id}): boolean => id === selectedId) ?? null;
  }

  @Selector()
  static getCount({count}: IEventState): number {
    return count;
  }

  @Selector()
  static getThereAreMoreEvents({thereAreMoreEvents}: IEventState): boolean {
    return thereAreMoreEvents;
  }

  @Action(EventActions.FindUpcoming)
  async FindUpcoming({patchState, getState}: StateContext<IEventState>): Promise<void> {
    const response: DataBaseServiceResponse<IEventWithRelations[]> = await firstValueFrom(this.baseService.get<IEventWithRelations[]>(`${this.SERVER}/events/upcoming`, void 0));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);
    patchState({
      upcomingEvents: response.entity!,
      selectedId: null,
    });
  }

  @Action(EventActions.Find)
  async find({patchState, getState}: StateContext<IEventState>, {filters, isAddedTo}: EventActions.Find): Promise<void> {
    const response: DataBaseServiceResponse<IEventWithRelations[]> = await firstValueFrom(this.baseService.get<IEventWithRelations[]>(`${this.SERVER}/events/client`, filters));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);
    const events: IEventWithRelations[] = isAddedTo ? [...getState().events].concat(response.entity ?? []) : response.entity ?? [];
    patchState({
      events,
      selectedId: null,
      thereAreMoreEvents: !(response.entity && response.entity.length < PLATFORM_CONFIG.PAGINATOR.ITEMS_PER_SCROLL)
    });
  }

  @Action(EventActions.FindAvailable)
  async findAvailable({patchState, getState}: StateContext<IEventState>, {eventFilter, isAddedTo}: EventActions.FindAvailable): Promise<void> {
    const response: DataBaseServiceResponse<IEventWithRelations[]> = await firstValueFrom(this.baseService.get<IEventWithRelations[]>(`${this.SERVER}/events/available?skip=${eventFilter.skip ?? 0}&take=${eventFilter.take ?? 0}&search=${eventFilter.search}`));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);
    const events: IEventWithRelations[] = isAddedTo ? [...getState().events].concat(response.entity ?? []) : response.entity ?? [];
    patchState({
      events,
      selectedId: null,
      thereAreMoreEvents: !(response.entity && response.entity.length < PLATFORM_CONFIG.PAGINATOR.ITEMS_PER_SCROLL)
    });
  }

  @Action(EventActions.FindPublic)
  async findPublic({patchState, getState}: StateContext<IEventState>): Promise<void> {
    const response: DataBaseServiceResponse<IEventWithRelations[]> = await firstValueFrom(this.baseService.get<IEventWithRelations[]>(`${this.SERVER}/events/public`));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);
    const publicEvents: IEventWithRelations[] = (response.entity ?? []).reverse();
    patchState({
      publicEvents,
      selectedId: null,
    });
  }

  @Action(EventActions.FindById)
  async findById({patchState, getState}: StateContext<IEventState>, {id, filters}: EventActions.FindById): Promise<void> {
    const response: DataBaseServiceResponse<IEventWithRelations> = await firstValueFrom(this.baseService.get<IEventWithRelations>(`${this.SERVER}/events/${id}`, filters));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({
      events: UtilityClass.updateOrPushItems<IEventWithRelations>(getState().events, response.entity!, 'id'),
      selectedId: response.entity!.id!,
    });
  }

  @Action(EventActions.Count)
  async count({patchState}: StateContext<IEventState>, {filters}: EventActions.Count): Promise<void> {
    const response: DataBaseServiceResponse<number> = await firstValueFrom(this.baseService.count<IEventWithRelations>(`${this.SERVER}/events/client/count`, filters));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({count: response.entity ?? 0});
  }

  @Action(EventActions.SetEventSelectedId)
  async setEventSelectedId({patchState}: StateContext<IEventState>, {id}: EventActions.SetEventSelectedId): Promise<void> {
    patchState({
      selectedId: id
    });
  }

  @Action(EventActions.DownloadAttachment)
  async downloadAttachment(_: StateContext<IEventState>, {id, eventMediaId, mimeType, fileName}: EventActions.DownloadAttachment): Promise<void> {
    const response: DataBaseServiceResponse<Blob> = await firstValueFrom(this.baseService.download(`${this.SERVER}/events/${id}/media/${eventMediaId}/download`, mimeType));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);
    DocumentUtilClass.download(response.entity!, fileName);
  }

  @Action(EventActions.GetRsvpOrganization)
  async getRsvpOrganization({ patchState }: StateContext<IEventState>, {organizationId, filters}: EventActions.GetRsvpOrganization): Promise<void> {
    const response: DataBaseServiceResponse<IRsvpOrganization[]> = await firstValueFrom(this.baseService.get<IRsvpOrganization[]>(`${this.SERVER}/organizations/${organizationId}/rsvp`, filters));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({ rsvpOrganizations: response.entity! });
  }

  @Action(EventActions.PostRsvpOrganization)
  async postRsvpOrganization({ patchState }: StateContext<IEventState>, {organizationId, data}: EventActions.PostRsvpOrganization): Promise<void> {
    const response: DataBaseServiceResponse<IRsvpOrganization> = await firstValueFrom(this.baseService.post(`${this.SERVER}/organizations/${organizationId}/rsvp`, data));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({ rsvpOrganizations: [ response.entity! ] });
  }


  @Action(EventActions.Clear)
  async clear({patchState}: StateContext<IEventState>): Promise<void> {
    patchState(_DEFAULT_EVENT);
  }


}
