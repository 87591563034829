import {
  animate,
  animateChild,
  AnimationTriggerMetadata,
  group,
  query, state,
  style,
  transition,
  trigger
} from '@angular/animations';

export const ContainerRotate: AnimationTriggerMetadata = trigger('rotate', [
  state('loading', style({
    transform: 'rotate(360deg)'
  })),
  transition('* => loading', [
    style({
      transform: 'rotate(0deg)',
    }),
    group([
      query('@icon', animateChild({})),
      animate('8s'),
    ])
  ]),
  transition('void => before-load', [
    style({ transform: 'scale(0)' }),
    animate('0.3s ease-in', style({ transform: 'scale(1)' })),
  ]),
]);
