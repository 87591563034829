import {Injectable} from '@angular/core';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {
  ICreateOrUpdateSupplierNumberList,
  IOrganization,
  IOrganizationSupplierNumber,
  IOrganizationSupplierNumberCreateDto,
  IOrganizationSupplierNumberUpdateDto,
  IOrganizationSupplierNumberWithRelations,
} from '@organization/interfaces';
import {BaseService} from '@shared/services';
import {ErrorHandlerService} from '@shared/services/error-handler.service';
import {DataBaseServiceResponse} from '@shared/services/base/interfaces/data-base-service-response.interface';
import {firstValueFrom} from 'rxjs';
import {environment} from '@env/environment';
import {PrismaCountFilter, PrismaFilter} from '@shared/services/base/interfaces/prisma-filter.interface';
import {UtilityClass} from '@shared/class/utils/utility.class';
import {omit as _omit} from 'lodash';

export interface IOrganizationSupplierNumberState {
  list: IOrganizationSupplierNumberWithRelations[];
  selectedId: string;
  count: number;

  statesSelectedIds: string[];
}

const _DEFAULT_DATA: IOrganizationSupplierNumberState = {
  list: [],
  selectedId: '',
  count: 0,
  statesSelectedIds: []
}

export namespace OrganizationSupplierNumberActions {
  export class GetList {
    static readonly type: string = '[OrganizationSupplierNumber] Get Organization Supplier Number List Action';
    constructor(public organizationId: string, public filter?: PrismaFilter<IOrganizationSupplierNumberWithRelations>) {}
  }

  export class GetCount {
    static readonly type: string = '[OrganizationSupplierNumber] Get Organization Supplier Number Count';
    constructor(public organizationId: string, public filters?: PrismaCountFilter<IOrganizationSupplierNumberWithRelations>) {
    }
  }

  export class GetById {
    static readonly type: string = '[OrganizationSupplierNumber] Get Organization Supplier Number By Id Action';
    constructor(public organizationId: string, public id: string, public filter?: PrismaFilter<IOrganizationSupplierNumberWithRelations>) {}
  }

  export class Create {
    static readonly type: string = '[OrganizationSupplierNumber] Create Organization Supplier Number Action';
    constructor(public organizationId: string, public supplierNumber: IOrganizationSupplierNumberCreateDto) {}
  }

  export class Update {
    static readonly type: string = '[OrganizationSupplierNumber] Update Organization Supplier Number Action';
    constructor(public organizationId: string, public id: string, public supplierNumber: IOrganizationSupplierNumberUpdateDto) {}
  }

  export class Delete {
    static readonly type: string = '[OrganizationSupplierNumber] Delete Organization Supplier Number Action';
    constructor(public organizationId: string, public id: string) {}
  }

  export class PostPatch{
    static readonly type: string = '[OrganizationSupplierNumber] Post Patch';
    constructor(public organizationId: string, public supplierNumberList: ICreateOrUpdateSupplierNumberList) {
    }
  }

  export class SetList {
    static readonly type: string = '[OrganizationSupplierNumber] Set List Action';
    constructor(public list: IOrganizationSupplierNumberWithRelations[]) {}
  }

  export class Reset {
    static readonly type: string = '[OrganizationSupplierNumber] Reset';
  }
}

@State<IOrganizationSupplierNumberState>({
  name: 'OrganizationSupplierNumberState',
  defaults: _DEFAULT_DATA
})
@Injectable()
export class OrganizationSupplierNumberState {
  private readonly SERVER: string = environment.SERVER;
  constructor(
    private baseService: BaseService,
    private errorHandlerService: ErrorHandlerService,
  ) {
  }

  @Selector()
  static getOrganizationSupplierNumberList(state: IOrganizationSupplierNumberState): IOrganizationSupplierNumberWithRelations[] {
    return state.list;
  }

  @Selector()
  static getCertificationSelected({list, selectedId}: IOrganizationSupplierNumberState): IOrganizationSupplierNumberWithRelations | undefined {
    return list.find((organization: IOrganizationSupplierNumberWithRelations): boolean => organization.id === selectedId);
  }

  @Selector()
  static getCount({count}: IOrganizationSupplierNumberState): number {
    return count;
  }

  @Action(OrganizationSupplierNumberActions.GetList)
  async getList({patchState}: StateContext<IOrganizationSupplierNumberState>, {organizationId, filter}: OrganizationSupplierNumberActions.GetList): Promise<void> {
    const response: DataBaseServiceResponse<IOrganizationSupplierNumberWithRelations[]> = await firstValueFrom(this.baseService.get<IOrganizationSupplierNumberWithRelations[]>(`${this.SERVER}/organizations/${organizationId}/supplier-numbers`, filter ?? {}));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);
    patchState({
      list: response.entity ?? []
    })
  }

  // @Action(OrganizationSupplierNumberActions.GetCount)
  // async GetCount(
  //   {patchState}: StateContext<IOrganizationSupplierNumberState>,
  //   {organizationId ,filters}: OrganizationSupplierNumberActions.GetCount
  // ): Promise<void> {
  //   const response: DataBaseServiceResponse<number> = await firstValueFrom(this.baseService.count<IOrganizationSupplierNumber>(`${this.SERVER}/organizations/${organizationId}/certifications/count`, filters));
  //   if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);
  //
  //   patchState({certificationCount: response.entity ?? 0});
  // }

  @Action(OrganizationSupplierNumberActions.GetById)
  async getById({patchState, getState}: StateContext<IOrganizationSupplierNumberState>, {organizationId, id, filter}: OrganizationSupplierNumberActions.GetById): Promise<void> {
    const response: DataBaseServiceResponse<IOrganizationSupplierNumberWithRelations> = await firstValueFrom(this.baseService.get(`${this.SERVER}/organizations/${organizationId}/supplier-numbers/${id}`, filter));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({
      list: UtilityClass.updateOrPushItems<IOrganizationSupplierNumber>(getState().list, response.entity!, 'id'),
      selectedId: id
    });
  }

  @Action(OrganizationSupplierNumberActions.Create)
  async create({patchState, getState}: StateContext<IOrganizationSupplierNumberState>, {organizationId, supplierNumber}: OrganizationSupplierNumberActions.Create): Promise<void> {
    const response: DataBaseServiceResponse<IOrganizationSupplierNumber> = await firstValueFrom(this.baseService.post(`${this.SERVER}/organizations/${organizationId}/supplier-numbers`, supplierNumber));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);
    patchState({
      list: UtilityClass.updateOrPushItems<IOrganizationSupplierNumber>(getState().list, response.entity!, 'id'),
      selectedId: response.entity?.id
    });
  }

  @Action(OrganizationSupplierNumberActions.Update)
  async update({patchState, getState}: StateContext<IOrganizationSupplierNumberState>, {organizationId, id, supplierNumber}: OrganizationSupplierNumberActions.Update): Promise<void> {
    const response: DataBaseServiceResponse<IOrganizationSupplierNumber> = await firstValueFrom(this.baseService.patch(`${this.SERVER}/organizations/${organizationId}/supplier-numbers/${id}`, supplierNumber));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);
    patchState({
      list: UtilityClass.updateOrPushItems<IOrganizationSupplierNumber>(getState().list, response.entity!, 'id'),
      selectedId: id
    });
  }

  @Action(OrganizationSupplierNumberActions.Delete)
  async delete({patchState, getState}: StateContext<IOrganizationSupplierNumberState>, {organizationId, id}: OrganizationSupplierNumberActions.Delete): Promise<void> {
    const response: DataBaseServiceResponse<IOrganization> = await firstValueFrom(this.baseService.delete(`${this.SERVER}/organizations/${organizationId}/supplier-numbers/${id}`));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);
    patchState({list: UtilityClass.deleteItemByProp<IOrganizationSupplierNumber>(getState().list, 'id', id)});
  }

  @Action(OrganizationSupplierNumberActions.PostPatch)
  async postPatch(
    {patchState, getState, dispatch}: StateContext<IOrganizationSupplierNumberState>,
    {organizationId, supplierNumberList}: OrganizationSupplierNumberActions.PostPatch
  ): Promise<void> {
    const createPromise: Promise<IOrganizationSupplierNumberWithRelations | null>[] = supplierNumberList.create.map((value: IOrganizationSupplierNumberCreateDto) => {
      return new Promise(async (resolve: (value: IOrganizationSupplierNumberWithRelations | null) => void): Promise<void> => {
        const supplierNumber: DataBaseServiceResponse<IOrganizationSupplierNumber> = await firstValueFrom(this.baseService.post<IOrganizationSupplierNumber>(`${this.SERVER}/organizations/${organizationId}/supplier-numbers`, value));
        if (supplierNumber.error) {
          return resolve(null);
        }

        resolve({
          ...supplierNumber.entity!,
        });
      });
    });

    const updatePromise: Promise<IOrganizationSupplierNumberWithRelations | null>[] = supplierNumberList.update.map((value: IOrganizationSupplierNumberUpdateDto) => {
      return new Promise(async (resolve: (value: IOrganizationSupplierNumberWithRelations | null) => void): Promise<void> => {
        const supplierNumber: DataBaseServiceResponse<IOrganizationSupplierNumber> = await firstValueFrom(this.baseService.patch<IOrganizationSupplierNumber>(`${this.SERVER}/organizations/${organizationId}/supplier-numbers/${value.id!}`, _omit(value, ['id'])));
        if (supplierNumber.error) {
          return resolve(null);
        }

        resolve({
          ...supplierNumber.entity!,
        });
      });
    });
    const promises: Promise<IOrganizationSupplierNumberWithRelations | null>[] = [...updatePromise, ...createPromise];
    if (promises.length === 0) return;

    const responses: (IOrganizationSupplierNumberWithRelations | null)[] = await Promise.all(promises);
    const successResponses: IOrganizationSupplierNumberWithRelations[] = responses.filter((item: IOrganizationSupplierNumberWithRelations | null): boolean => item !== null) as IOrganizationSupplierNumberWithRelations[];

    let list: IOrganizationSupplierNumberWithRelations[] = [...getState().list];

    successResponses.forEach((certification: IOrganizationSupplierNumberWithRelations, i): void => {
      list = UtilityClass.updateOrPushItems<IOrganizationSupplierNumberWithRelations>(list, certification, 'id');
    });

    patchState({list});
  }

  @Action(OrganizationSupplierNumberActions.SetList)
  setList({patchState}: StateContext<IOrganizationSupplierNumberState>, {list}: OrganizationSupplierNumberActions.SetList): void {
    patchState({
      list: [...list]
    });
  }

  @Action(OrganizationSupplierNumberActions.Reset)
  reset({setState}: StateContext<IOrganizationSupplierNumberState>): void {
    setState(_DEFAULT_DATA);
  }
}
