import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {DashboardTemplate} from '@shared/templates/dashboard/dashboard.template';
import {hasOrganizationGuard} from '@shared/guards';
import {AuthTemplate} from '@shared/templates/auth/auth.template';

const dashboardRoutes: Routes = [
  { path: 'profile', loadChildren: () => import('@profile/profile.module').then(m => m.ProfileModule) },
  { path: 'organizations/:organizationId', loadChildren: () => import('@organization/organization.module').then(m => m.OrganizationModule) },
  { path: 'events', loadChildren: () => import('@event/event.module').then(m => m.EventModule) },
  { path: 'opportunities', loadChildren: () => import('@opportunity/opportunity.module').then(m => m.OpportunityModule) },
  // { path: 'suppliers', loadChildren: () => import('@supplier/supplier.module').then(m => m.SupplierModule) },
  // { path: 'buyers', loadChildren: () => import('@buyer/buyer.module').then(m => m.BuyerModule) },
  { path: 'connection-request', loadChildren: () => import('@connection-request/connection-request.module').then(m => m.ConnectionRequestModule) },
  // { path: 'training', loadChildren: () => import('@training/training.module').then(m => m.TrainingModule) },
  { path: '**', redirectTo: 'profile', pathMatch: 'full'}
];

const routes: Routes = [
  { path: 'auth', component: AuthTemplate, loadChildren: () => import('@auth/auth.module').then(m => m.AuthModule)},
  { path: '', children: dashboardRoutes, component: DashboardTemplate, canActivate: [hasOrganizationGuard]},
  { path: '**', redirectTo: 'auth' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    paramsInheritanceStrategy: 'always',
    bindToComponentInputs: true,
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
