import {Component, OnInit} from '@angular/core';
import {PageHandler} from '@shared/interfaces';
import {RouterOutlet} from '@angular/router';
import {NzCarouselModule} from 'ng-zorro-antd/carousel';
import {CommonModule} from '@angular/common';
import {firstValueFrom, Observable} from 'rxjs';
import {Select, Store} from '@ngxs/store';
import {AuthActions} from '@auth/state';
import {ExceptionHandler} from '@shared/decorators';
import {NzMessageService} from 'ng-zorro-antd/message';
import {GlobalConfigActions, GlobalConfigState} from '../../../features/global/state/global-config.state';
import {ISlideItem} from '../../../features/global/interfaces';
import {PrismaFilter} from '@shared/services/base/interfaces/prisma-filter.interface';

@Component({
  selector: 'auth-template',
  templateUrl: './auth.template.html',
  styleUrls: ['./auth.template.scss'],
  imports: [
    RouterOutlet,
    NzCarouselModule,
    CommonModule,
  ],
  standalone: true,
})
export class AuthTemplate implements OnInit, PageHandler {
  @Select(GlobalConfigState.getSlideItemList) slideItemList$!: Observable<ISlideItem[]>;
  constructor(
    private store: Store,
    private nzMessageService: NzMessageService,
  ) {
  }

  ngOnInit(): void {
    this.request().then();
  }

  @ExceptionHandler()
  async request(): Promise<void> {
    const startAt: Date = new Date();
    startAt.setHours(0,0,0,0);
    const endAt: Date = new Date(startAt.getFullYear(), startAt.getMonth(), startAt.getDate()+1, 0, 0, 0, -1);
    const filters: PrismaFilter<ISlideItem> = {
      where: {
        AND: [
          {enabled: true},
          {
              OR: [
                {
                  startAt: {lte: startAt},
                },
                {
                  startAt: {in: null}
                }
              ]
          },
          {
            OR: [
              {
                endAt: {gte: endAt},
              },
              {
                endAt: {in: null}
              }
            ]
          }
        ]
      }
    }
    await firstValueFrom(this.store.dispatch(new AuthActions.SetStorage()));
    await firstValueFrom(this.store.dispatch(new GlobalConfigActions.GetSlideItemList(filters)));
  }
}
