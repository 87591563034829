import {Injectable} from '@angular/core';
import {IMenuItem} from '@menu/interfaces/menu-item.interface';
import {StorageService} from '@shared/services';
import {ISidebarItem} from '@sidebar/interfaces';
import {OrganizationTypeEnum} from '@organization/enums';
import {IStorageOrganization} from '@shared/services/storage/interfaces';
import {Select, Store} from '@ngxs/store';
import {OrganizationState} from '@organization/state';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  @Select(OrganizationState.getOrganizationType) organizationType$!: Observable<OrganizationTypeEnum>;
  constructor(
    private storageService: StorageService,
    private store: Store
  ) {}

  buildDefaultMainMenu(): IMenuItem[] {
    const storageOrganization: IStorageOrganization | null = this.storageService.getOrganization()
    if (!storageOrganization) return [];
    const organizationType: OrganizationTypeEnum | null = this.store.selectSnapshot(OrganizationState.getOrganizationType);
    return [
      {text: 'Mi empresa', route: `/organizations/${storageOrganization.id}/details`},
      {text: 'Proveedores', route: `/suppliers`, hide: true}, // solo si el usuario es comprador
      {text: 'Compradores', route: `/buyers`, hide: true}, // solo si el usuario es proveedor
      {text: 'Oportunidades', route: `/opportunities`, hide: organizationType === OrganizationTypeEnum.SUPPLIER},
      {text: 'Oportunidades', route: `/opportunities/public`, hide: organizationType === OrganizationTypeEnum.BUYER},
      {text: 'Eventos', route: `/events`},
      {text: 'Agenda', route: `/connection-request`},
    ];
  }

  buildDefaultSidebarMenu(): ISidebarItem[] {
    const storageOrganization: IStorageOrganization | null = this.storageService.getOrganization();
    if (!storageOrganization) return [];
    let organizationType: OrganizationTypeEnum | null = this.store.selectSnapshot(OrganizationState.getOrganizationType);
    return [
      {
        key: '1',
        name: 'Mi cuenta',
        icon: 'las la-user',
        route: '/profile'
      },
      {
        key: '2',
        name: 'Mi agenda',
        icon: 'las la-calendar',
        route: '/connection-request'
      },
      {
        key: '3',
        name: 'Mi empresa',
        icon: 'las la-building',
        children: [
          {
            name: 'Perfil empresarial',
            key: '3-0',
            route: `/organizations/${storageOrganization.id}/details`
          },
          {
            name: 'Expediente digital',
            key: '3-2',
            route: `/organizations/${storageOrganization.id}/records`
          },
          {
            name: 'Oferta',
            key: '3-3',
            hide: organizationType === OrganizationTypeEnum.BUYER,
            children: [
              {name: 'Productos', key: '3-3-0', route: `/organizations/${storageOrganization.id}/offers/products`, hide: organizationType === OrganizationTypeEnum.BUYER,},
              {name: 'Servicios', key: '3-3-1', route: `/organizations/${storageOrganization.id}/offers/services`, hide: organizationType === OrganizationTypeEnum.BUYER,}
            ]
          },
        ]
      },
      {
        key: '4',
        name: 'Colaboradores',
        icon: 'las la-user-friends',
        route: `/organizations/${storageOrganization.id}/collaborators`
      },
      {
        key: '5',
        name: 'Proveedores',
        icon: 'las la-boxes',
        route: '/suppliers/list',
        hide: true
      },
      {
        key: '6',
        name: 'Compradores',
        icon: 'las la-user-shield',
        route: '/buyers/list',
        hide: true
      },
      {
        key: '7',
        name: 'Contactos',
        icon: 'las la-phone-alt',
        route: `/organizations/${storageOrganization.id}/contacts`,
      },
      {
        key: '8',
        name: 'Oportunidades',
        icon: 'las la-handshake',
        route: `/opportunities`,
        hide: organizationType === OrganizationTypeEnum.SUPPLIER
      },
      {
        key: '9',
        name: 'Oportunidades',
        icon: 'las la-handshake',
        route: `/opportunities/public`,
        hide: organizationType === OrganizationTypeEnum.BUYER
      },
      {
        key: '9',
        name: 'Eventos',
        icon: 'las la-calendar-check',
        route: `/events`
      },
    ];
  }
}
