import {Component, OnInit} from '@angular/core';
import {NzDropDownModule} from 'ng-zorro-antd/dropdown';
import {NzAvatarModule} from 'ng-zorro-antd/avatar';
import {NzTypographyModule} from 'ng-zorro-antd/typography';
import {NzButtonModule} from 'ng-zorro-antd/button';
import {Router, RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';
import {NzInputModule} from 'ng-zorro-antd/input';
import {NzLayoutModule} from 'ng-zorro-antd/layout';
import {Select, Store} from '@ngxs/store';
import {firstValueFrom, Observable} from 'rxjs';
import {ExceptionHandler, Loader} from '@shared/decorators';
import {AuthActions, AuthState} from '@auth/state';
import {PlatformActions} from '@shared/state/platform.state';
import {SidebarActions, SidebarState} from '@sidebar/state/sidebar.state';
import {MenuActions, MenuState} from '@menu/state/menu.state';
import {IMenuItem} from '@menu/interfaces/menu-item.interface';
import {FirstUrlFromMediaPipe, GetInitialLetterPipe} from '@shared/pipes';
import {DeveloperException} from '@shared/class/errors';
import {NzMessageService} from 'ng-zorro-antd/message';
import {NzPopoverModule} from 'ng-zorro-antd/popover';
import {TagTypeEnum} from '@shared/components/ui-elements/tag/enums';
import {NotificationState} from '../../../../features/notification/state/notification.state';
import {INotification} from '../../../../features/notification/interfaces';
import {NoResultComponent} from '@shared/components/ui-elements/no-result/no-result.component';
import {IStorageCredentials} from '@shared/services/storage/interfaces/storage-credentials.interface';
import {IStorageOrganization, IStorageUser} from '@shared/services/storage/interfaces';
import {OrganizationActions, OrganizationState} from '@organization/state';
import {IOrganizationWithRelations} from '@organization/interfaces';
import {OrganizationTypeEnum} from '@organization/enums';
import {StorageService} from '@shared/services';
import {OpportunityTypeEnum} from '@opportunity/enums';
import {NzModalRef, NzModalService} from 'ng-zorro-antd/modal';
import {MenuService} from '@menu/services/menu.service';
import {PLATFORM_MESSAGES} from '@shared/config';
import {ISidebarItem} from '@sidebar/interfaces';
import {cloneDeep} from 'lodash';
import {environment} from '@env/environment';
import {LoaderActions} from '@loader/state/loader-actions.state';

@Component({
  selector: 'main-menu',
  templateUrl: './main-menu.component.html',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    NzDropDownModule,
    NzAvatarModule,
    NzTypographyModule,
    NzButtonModule,
    NzInputModule,
    NzLayoutModule,
    FirstUrlFromMediaPipe,
    GetInitialLetterPipe,
    NzPopoverModule,
    NoResultComponent,
  ],
  providers: [NzModalService],
  styles: [`
    .active {
      &:after {
        content: '';
        @apply absolute -bottom-5 left-1/2 -translate-x-1/2 bg-white w-5/6 h-1 rounded-t-[5px];
      }
    }

    .mobile-active {

      &:after {
        content: '';
        @apply absolute top-1/2 -translate-y-1/2 right-0 bg-white w-1 h-5/6  rounded-l-[5px];
      }
    }
  `]
})
export class MainMenuComponent implements OnInit {
  @Select(AuthState.getCredentials) credentials$!: Observable<IStorageCredentials>;
  @Select(AuthState.getUser) user$!: Observable<IStorageUser>;
  @Select(OrganizationState.getOrganizationType) organizationType$!: Observable<OrganizationTypeEnum>;
  @Select(OrganizationState.getOrganizationSelected) organization$!: Observable<IOrganizationWithRelations>;

  @Select(MenuState.getSidebarItems) sidebarHasItems$!: Observable<ISidebarItem[]>;
  @Select(MenuState.getItems) menuItems$!: Observable<IMenuItem[]>;
  @Select(NotificationState.getNotifications) notifications$!: Observable<INotification[]>;

  storageOrganization!: IStorageOrganization | null;
  constructor(
    private store: Store,
    private router: Router,
    private nzMessageService: NzMessageService,
    private storageService: StorageService,
    protected modalService: NzModalService,
    private menuService: MenuService
  ) {}

  async ngOnInit(): Promise<void> {
    this.storageOrganization = this.storageService.getOrganization();
  }

  async onLateralMenuToggle(): Promise<void> {
    await firstValueFrom(this.store.dispatch(new SidebarActions.Toggle()));
  }

  async onLogout(): Promise<void> {
    await firstValueFrom(this.store.dispatch(new LoaderActions.Show()));
    await firstValueFrom(this.store.dispatch(new AuthActions.Logout()));
    await firstValueFrom(this.store.dispatch(new PlatformActions.Reset()));
    await firstValueFrom(this.store.dispatch(new LoaderActions.Hide()));
    await this.router.navigate([environment.DEFAULT_PAGE_TO_LOAD]);
  }

  @ExceptionHandler('info')
  utilMethod(): void {
    throw new DeveloperException();
  }

  @Loader(700)
  async onChangeOrganizationType(): Promise<void> {
    await this.storageService.toggleOrganizationType();
    await this.router.navigate(['/', 'profile']);
    await firstValueFrom(this.store.dispatch(new MenuActions.Set(
      this.menuService.buildDefaultMainMenu(),
      this.menuService.buildDefaultSidebarMenu()
    )));
  }

  onModalWantBeOtherType(): void {
    const currentType: OrganizationTypeEnum | null = this.store.selectSnapshot(OrganizationState.getOrganizationType);
    const modal: NzModalRef = this.modalService.confirm({
      nzTitle: `<h4 class="font-semibold text-gray-700">¿Desea convertir su cuenta para también ser ${currentType === OrganizationTypeEnum.BUYER ? 'proveedor' : 'comprador'}?</h4>`,
      nzContent: `<p class="text-gray-500">Esta operación no se podrá deshacer</p>`,
      nzCancelText: 'Cancelar',
      nzOkText: 'Aceptar',
      nzOnOk: async (): Promise<void> => {
        try {
          await this.wantBeOtherType();
        } catch (err) {
          this.nzMessageService.error(PLATFORM_MESSAGES.GENERAL.TRY_LATER);
        } finally {
          modal.close();
        }
      },
      nzOnCancel: (): void => {
        return;
      }
    });
  }

  @Loader(700)
  private async wantBeOtherType(): Promise<void> {
    const storageOrganization: IStorageOrganization | null = this.storageService.getOrganization();
    await firstValueFrom(this.store.dispatch(new OrganizationActions.ConvertToBothType(storageOrganization?.id!)));
    await this.storageService.toggleOrganizationType();
    await this.router.navigate(['/', 'profile']);
    await firstValueFrom(this.store.dispatch(new MenuActions.Set(
      this.menuService.buildDefaultMainMenu(),
      this.menuService.buildDefaultSidebarMenu()
    )));
    this.storageOrganization = this.storageService.getOrganization();
  }
  protected readonly TagTypeEnum = TagTypeEnum;
  protected readonly OrganizationTypeEnum = OrganizationTypeEnum;
  protected readonly OpportunityTypeEnum = OpportunityTypeEnum;
}
