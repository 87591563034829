import {IAnyObject} from '@shared/interfaces';
import {PublicationStatusEnum} from '@shared/enums';

export const PUBLICATION_STATUS_VALUES: IAnyObject<string> = {
  [PublicationStatusEnum.PUBLISHED]: 'Publicado',
  [PublicationStatusEnum.DRAFT]: 'Borrador',
  [PublicationStatusEnum.ARCHIVED]: 'Archivado',
  [PublicationStatusEnum.REJECTED]: 'Rechazado',
  [PublicationStatusEnum.DELETED]: 'Eliminado'
}
