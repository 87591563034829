import {Action, Selector, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {BaseService} from '@shared/services';
import {PrismaFilter} from '@shared/services/base/interfaces/prisma-filter.interface';
import {DataBaseServiceResponse} from '@shared/services/base/interfaces/data-base-service-response.interface';
import {firstValueFrom} from 'rxjs';
import {environment} from '@env/environment';
import {ErrorHandlerService} from '@shared/services/error-handler.service';
import {
  IBudgetRequest,
  IBudgetRequestCreateDto,
  IBudgetRequestReplyDto,
  IJoinOpportunityRequest,
  IJoinOpportunityRequestWithRelations,
  IOpportunity,
  IOpportunityAcceptedSupplierWithRelations,
  IOpportunityMatchWithRelations, IOpportunityReply,
  IOpportunityResolutionCreatedDto,
  IOpportunityResolutionWithRelations,
  IOpportunityWithRelations,
  IProductRequestWithRelations, IPublicOpportunityFilters,
} from '@opportunity/interfaces';
import {UtilityClass} from '@shared/class/utils';
import {PLATFORM_MESSAGES} from '@shared/config';
import {Router} from '@angular/router';
import {NzMessageService} from 'ng-zorro-antd/message';
import {DocumentUtilClass} from '@file-record/document-util.class';

export interface IOpportunityState {
  list: IOpportunityWithRelations[];
  selectedId: string | null;
  count: number;

  opportunityMatches: IOpportunityMatchWithRelations[];
  opportunityMatchSelectedId: string | null;

  joinOpportunityRequest: IJoinOpportunityRequestWithRelations[];
  joinOpportunityRequestSelectedId: string | null;

  budgetRequest: IProductRequestWithRelations[];
  budgetRequestSelectedId: string | null;

  resolutions: IOpportunityResolutionWithRelations[];
  resolutionsSelectedId: string | null;

  supplierAccepted: IOpportunityAcceptedSupplierWithRelations[];
  supplierAcceptedSelectedId: string | null;

  opportunityReply: IOpportunityReply | null;
}

const _DEFAULT_OPPORTUNITY: IOpportunityState = {
  list: [],
  selectedId: null,
  count: 0,

  opportunityMatches: [],
  opportunityMatchSelectedId: null,

  budgetRequest: [],
  budgetRequestSelectedId: null,

  joinOpportunityRequest: [],
  joinOpportunityRequestSelectedId: null,

  resolutions: [],
  resolutionsSelectedId: null,

  supplierAccepted: [],
  supplierAcceptedSelectedId: null,

  opportunityReply: null,
}

export namespace OpportunityActions {
  export class GetList {
    static readonly type: string = '[Opportunity] Get Opportunity List';
    constructor(public organizationId: string, public filter?: PrismaFilter<IOpportunityWithRelations>) {}
  }

  export class CountList {
    static readonly type: string = '[Opportunity] Count Opportunity List';
    constructor(public organizationId: string, public filter?: PrismaFilter<IOpportunityWithRelations>) {}
  }

  export class Create {
    static readonly type: string = '[Opportunity] Create Opportunity';
    constructor(public organizationId: string, public opportunity: Partial<IOpportunity>) {}
  }

  export class Update {
    static readonly type: string = '[Product] Update Opportunity';
    constructor(public organizationId: string, public opportunityId: string, public opportunity: Partial<IOpportunity>) {}
  }

  export class GetById {
    static readonly type: string = '[Opportunity] Get Opportunity By Id';
    constructor(public organizationId: string, public opportunityId: string, public filter?: PrismaFilter<IOpportunityWithRelations>, public setBudgetRequest?: boolean) {}
  }

  export class Delete {
    static readonly type: string = '[Opportunity] Delete Opportunity';
    constructor(public organizationId: string, public opportunityId: string) {}
  }

  export class SetOpportunitySelected {
    static readonly type: string = '[Opportunity] Set Opportunity By Id';
    constructor(public id: string | null) {}
  }

  export class Finalize {
    static readonly type: string = '[Opportunity] Finalize Opportunity';
    constructor(public organizationId: string, public opportunityId: string) {}
  }

  export class BudgetRequests {
    static readonly type: string = '[Opportunity] Budget Requests';
    constructor(public opportunityId: string, public budgetRequest: IBudgetRequestCreateDto) {}
  }

  export class BudgetRequestsApply {
    static readonly type: string = '[Opportunity] Budget Requests Apply';
    constructor(public opportunityId: string, public supplierId: string) {}
  }

  export class BudgetRequestById {
    static readonly type: string = '[Opportunity] Budget Request By Id';
    constructor(public opportunityId: string, public budgetRequestId: string) {}
  }

  export class GetBudgetRequestsReply {
    static readonly type: string = '[Opportunity] Get Budget Requests Reply';
    constructor(public opportunityId: string, public budgetRequestId: string, public joinOpportunityRequestId?: string) {}
  }

  export class BudgetRequestsReply {
    static readonly type: string = '[Opportunity] Budget Requests Reply';
    constructor(public opportunityId: string, public budgetRequestId: string, public formData: FormData) {}
  }

  export class DownloadQuoteDocument {
    static readonly type: string = '[Document] Download Quote Document';
    constructor(public opportunityId: string, public budgetRequestId: string, public fileName: string, public mimeType: string) {
    }
  }

  export class GetPublicList {
    static readonly type: string = '[Opportunity] Get Opportunity Public List';
    constructor(public filter?: IPublicOpportunityFilters) {}
  }

  export class CountPublicList {
    static readonly type: string = '[Opportunity] Count Opportunity Public List';
    constructor(public filter?: IPublicOpportunityFilters) {}
  }

  export class ApplyTo {
    static readonly type: string = '[Opportunity] Apply To';
    constructor(public organizationId: string, public opportunityId: string, public joinOpportunity: Partial<IJoinOpportunityRequest>) {}
  }

  export class CreateResolution {
    static readonly type: string = '[Opportunity] Create Resolution';
    constructor(public organizationId: string, public opportunityId: string, public resolution: IOpportunityResolutionCreatedDto) {}
  }

  export class AcceptedSupplier {
    static readonly type: string = '[Opportunity] Accepted Supplier';
    constructor(public organizationId: string, public opportunityId: string, public resolutionId: string, public supplier: FormData) {}
  }

  export class GetResolution {
    static readonly type: string = '[Opportunity] Get Resolution';
    constructor(public organizationId: string, public opportunityId: string, public resolutionId: string) {}
  }

  export class DownloadResolution {
    static readonly type: string = '[Opportunity] Download Resolution';
    constructor(public organizationId: string, public opportunityId: string, public resolutionId: string, public acceptedSupplierId: string, public fileName: string, public mimeType: string) {}
  }

  export class Reset {
    static readonly type: string = '[Opportunity] Reset Opportunity';
    constructor() {}
  }
}

@State<IOpportunityState>({
  name: 'OpportunityState',
  defaults: _DEFAULT_OPPORTUNITY
})
@Injectable()
export class OpportunityState {
  private readonly SERVER: string = environment.SERVER;

  constructor(
    private baseService: BaseService,
    private errorHandlerService: ErrorHandlerService,
    private router: Router,
    private nzMessageService: NzMessageService
  ) {}

  @Selector()
  static getOpportunities({list}: IOpportunityState): IOpportunityWithRelations[] {
    return list;
  }

  @Selector()
  static countOpportunities({count}: IOpportunityState): number {
    return count;
  }

  @Selector()
  static getOpportunitySelected({list, selectedId}: IOpportunityState): IOpportunityWithRelations | undefined {
    return list.find(({id}: IOpportunityWithRelations): boolean => id === selectedId);
  }

  @Selector()
  static getOpportunityMatches({opportunityMatches}: IOpportunityState): IOpportunityMatchWithRelations[] {
    return opportunityMatches;
  }

  @Selector()
  static getOpportunityBudgetRequest({budgetRequest}: IOpportunityState): IBudgetRequest[] {
    return budgetRequest;
  }

  @Selector()
  static getOpportunityBudgetRequestSelected({budgetRequest, budgetRequestSelectedId}: IOpportunityState): IBudgetRequest | undefined {
    return budgetRequest.find((budgetRequest: IBudgetRequest) => budgetRequest.id === budgetRequestSelectedId);
  }

  @Selector()
  static getJoinOpportunityRequest({joinOpportunityRequest}: IOpportunityState): IJoinOpportunityRequestWithRelations[] {
    return joinOpportunityRequest;
  }

  @Selector()
  static getOpportunityReply({opportunityReply}: IOpportunityState): IOpportunityReply | null {
    return opportunityReply;
  }

  @Selector()
  static getJoinOpportunityRequestSelected({joinOpportunityRequest, joinOpportunityRequestSelectedId}: IOpportunityState): IJoinOpportunityRequestWithRelations | undefined {
    return joinOpportunityRequest.find((join: IJoinOpportunityRequestWithRelations) => join.id === joinOpportunityRequestSelectedId);
  }

  @Selector()
  static getOpportunityResolutions({resolutions}: IOpportunityState): IOpportunityResolutionWithRelations[] {
    return resolutions;
  }

  @Selector()
  static getOpportunityResolutionSelected({resolutions, resolutionsSelectedId}: IOpportunityState): IOpportunityResolutionWithRelations | undefined {
    return resolutions.find((res: IOpportunityResolutionWithRelations): boolean => res.id === resolutionsSelectedId);
  }

  @Action(OpportunityActions.GetList)
  async getOpportunitiesList(
    {patchState}: StateContext<IOpportunityState>,
    {organizationId, filter}: OpportunityActions.GetList
  ): Promise<void> {
    const response: DataBaseServiceResponse<IOpportunityWithRelations[]> = await firstValueFrom(this.baseService.get<IOpportunityWithRelations[]>(`${this.SERVER}/organizations/${organizationId}/opportunities`, filter));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({list: response.entity!});
  }

  @Action(OpportunityActions.CountList)
  async countOpportunitiesList(
    {patchState}: StateContext<IOpportunityState>,
    {organizationId, filter}: OpportunityActions.CountList
  ): Promise<void> {
    const response: DataBaseServiceResponse<number> = await firstValueFrom(this.baseService.count<IOpportunityWithRelations>(`${this.SERVER}/organizations/${organizationId}/opportunities/count`, filter));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({count: response.entity ?? 0});
  }

  @Action(OpportunityActions.Create)
  async createOpportunity(
    {patchState, getState}: StateContext<IOpportunityState>,
    {organizationId, opportunity}: OpportunityActions.Create
  ): Promise<void> {
    const response: DataBaseServiceResponse<IOpportunity> = await firstValueFrom(this.baseService.post<IOpportunity>(`${this.SERVER}/organizations/${organizationId}/opportunities`, opportunity));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({
      list: UtilityClass.updateOrPushItems<IOpportunityWithRelations>(getState().list, response.entity!, 'id'),
      selectedId: response.entity!.id!,
    });
  }

  @Action(OpportunityActions.Update)
  async updateOpportunity(
    {patchState, getState}: StateContext<IOpportunityState>,
    {organizationId, opportunityId, opportunity}: OpportunityActions.Update
  ): Promise<void> {
    const response: DataBaseServiceResponse<IOpportunity> = await firstValueFrom(this.baseService.patch<IOpportunity>(`${this.SERVER}/organizations/${organizationId}/opportunities/${opportunityId}`, opportunity));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({
      list: UtilityClass.updateOrPushItems<IOpportunityWithRelations>(getState().list, response.entity!, 'id'),
      selectedId: response.entity!.id!,
    });
  }

  @Action(OpportunityActions.GetById)
  async getOpportunityById(
    {patchState, getState}: StateContext<IOpportunityState>,
    {organizationId, opportunityId, filter, setBudgetRequest}: OpportunityActions.GetById
  ): Promise<void> {
    const response: DataBaseServiceResponse<IOpportunityWithRelations> = await firstValueFrom(this.baseService.get<IOpportunityWithRelations>(`${this.SERVER}/organizations/${organizationId}/opportunities/${opportunityId}`, filter));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({
      list: UtilityClass.updateOrPushItems<IOpportunityWithRelations>(getState().list, response.entity!, 'id'),
      selectedId: response.entity!.id!,
      opportunityMatches: response.entity!.OpportunityMatch ?? [],
      joinOpportunityRequest: response.entity!.JoinOpportunityRequest ?? []
    });

    if (setBudgetRequest) {
      patchState({
        budgetRequest: response.entity!.BudgetRequest ?? [],
      });
    }
  }

  @Action(OpportunityActions.SetOpportunitySelected)
  async setOpportunityById(
    {patchState}: StateContext<IOpportunityState>,
    {id}: OpportunityActions.SetOpportunitySelected
  ): Promise<void> {
    patchState({selectedId: id});
  }

  @Action(OpportunityActions.Reset)
  async resetOpportunity({setState}: StateContext<IOpportunityState>): Promise<void> {
    setState(_DEFAULT_OPPORTUNITY);
  }

  @Action(OpportunityActions.Delete)
  async delete(_: StateContext<IOpportunityState>, {organizationId, opportunityId}: OpportunityActions.Delete): Promise<void> {
    const response: DataBaseServiceResponse<IOpportunityWithRelations> = await firstValueFrom(this.baseService.delete<IOpportunityWithRelations>(`${this.SERVER}/organizations/${organizationId}/opportunities/${opportunityId}`));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);
  }

  @Action(OpportunityActions.Finalize)
  async finalize(_: StateContext<IOpportunityState>, {organizationId, opportunityId}: OpportunityActions.Finalize): Promise<void> {
    const response: DataBaseServiceResponse<IOpportunityWithRelations> = await firstValueFrom(this.baseService.post<IOpportunityWithRelations>(`${this.SERVER}/organizations/${organizationId}/opportunities/${opportunityId}/finish`, void 0));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);
  }

  @Action(OpportunityActions.BudgetRequests)
  async budgetRequests({ getState, patchState }: StateContext<IOpportunityState>, {opportunityId, budgetRequest}: OpportunityActions.BudgetRequests): Promise<void> {
    const response: DataBaseServiceResponse<IProductRequestWithRelations> = await firstValueFrom(this.baseService.post<IProductRequestWithRelations>(`${this.SERVER}/opportunities/${opportunityId}/budget-requests`, budgetRequest));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);
    patchState({
      budgetRequest: UtilityClass.updateOrPushItems(getState().budgetRequest, response.entity!, 'id'),
    });
  }

  @Action(OpportunityActions.BudgetRequestsApply)
  async budgetRequestsApply({ getState, patchState }: StateContext<IOpportunityState>, {opportunityId, supplierId}: OpportunityActions.BudgetRequestsApply): Promise<void> {
    const response: DataBaseServiceResponse<IProductRequestWithRelations> = await firstValueFrom(this.baseService.post<IProductRequestWithRelations>(`${this.SERVER}/opportunities/${opportunityId}/budget-requests/apply`, {supplierId}));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);
    patchState({
      budgetRequest: UtilityClass.updateOrPushItems(getState().budgetRequest, response.entity!, 'id'),
    });
  }

  @Action(OpportunityActions.BudgetRequestById)
  async budgetRequestById({ patchState }: StateContext<IOpportunityState>, {opportunityId, budgetRequestId}: OpportunityActions.BudgetRequestById): Promise<void> {
    const response: DataBaseServiceResponse<IProductRequestWithRelations> = await firstValueFrom(this.baseService.get<IProductRequestWithRelations>(`${this.SERVER}/opportunities/${opportunityId}/budget-requests/${budgetRequestId}`));
    if (response.error) {
      if (response?.message?.includes('(REQ004) BudgetRequest has expired')) {
        await this.router.navigate(['/', 'profile']);
        this.nzMessageService.error(PLATFORM_MESSAGES.OPPORTUNITY.BUDGET_REQUESTS.EXPIRATION);
      }
      if (response?.message?.includes('(REQ002) BudgetRequest has already been responded to')) {
        await this.router.navigate(['/', 'profile']);
        this.nzMessageService.error(PLATFORM_MESSAGES.OPPORTUNITY.BUDGET_REQUESTS.NOT_POSIBLE_ANSWER);
      }
      if (response?.message?.includes('(QUO002) QuotationVersion unauthorized response')) {
        await this.router.navigate(['/', 'profile']);
        this.nzMessageService.error(PLATFORM_MESSAGES.OPPORTUNITY.BUDGET_REQUESTS.NOT_MINE_QUOTE);
      }
      return;
    }
    patchState({
      budgetRequest: [response.entity!],
      budgetRequestSelectedId: budgetRequestId
    });
  }

  @Action(OpportunityActions.GetBudgetRequestsReply)
  async getBudgetRequestsReply({patchState}: StateContext<IOpportunityState>, {opportunityId, budgetRequestId, joinOpportunityRequestId}: OpportunityActions.GetBudgetRequestsReply): Promise<void> {
    const response: DataBaseServiceResponse<IOpportunityReply> = await firstValueFrom(this.baseService.get<IOpportunityReply>(
      `${this.SERVER}/opportunities/${opportunityId}/budget-requests/${budgetRequestId}/reply`,
      void 0,
      {
        params:  joinOpportunityRequestId ? { joinOpportunityRequestId } : undefined,
      }
    ));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);
    patchState({opportunityReply: response.entity});
  }



  @Action(OpportunityActions.BudgetRequestsReply)
  async budgetRequestsReply(_: StateContext<IOpportunityState>, {opportunityId, budgetRequestId, formData}: OpportunityActions.BudgetRequestsReply): Promise<void> {
    const response: DataBaseServiceResponse<IProductRequestWithRelations> = await firstValueFrom(this.baseService.post<IProductRequestWithRelations>(`${this.SERVER}/opportunities/${opportunityId}/budget-requests/${budgetRequestId}/reply`, formData));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);
  }

  @Action(OpportunityActions.DownloadQuoteDocument)
  async downloadQuoteDocument(_: StateContext<IOpportunityState>, {opportunityId, budgetRequestId, mimeType, fileName}: OpportunityActions.DownloadQuoteDocument): Promise<void> {
    const response: DataBaseServiceResponse<Blob> = await firstValueFrom(this.baseService.download(`${this.SERVER}/opportunities/${opportunityId}/budget-requests/${budgetRequestId}/download`, mimeType));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);
    DocumentUtilClass.download(response.entity!, fileName);
  }

  @Action(OpportunityActions.GetPublicList)
  async getPublicList({patchState}: StateContext<IOpportunityState>, {filter}: OpportunityActions.GetPublicList): Promise<void> {
    const response: DataBaseServiceResponse<IOpportunityWithRelations[]> = await firstValueFrom(this.baseService.get<IOpportunityWithRelations[]>(`${this.SERVER}/opportunities/public`, filter));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({list: response.entity!});
  }

  @Action(OpportunityActions.CountPublicList)
  async countPublicList({patchState}: StateContext<IOpportunityState>, {filter}: OpportunityActions.CountPublicList): Promise<void> {
    const response: DataBaseServiceResponse<number> = await firstValueFrom(this.baseService.count<IOpportunityWithRelations>(`${this.SERVER}/opportunities/public/count`, filter));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({count: response.entity ?? 0});
  }

  @Action(OpportunityActions.ApplyTo)
  async applyTo({getState, patchState}: StateContext<IOpportunityState>, {organizationId, opportunityId, joinOpportunity}: OpportunityActions.ApplyTo): Promise<void> {
    const response: DataBaseServiceResponse<IJoinOpportunityRequestWithRelations> = await firstValueFrom(this.baseService.post<IJoinOpportunityRequestWithRelations>(`${this.SERVER}/organizations/${organizationId}/opportunities/${opportunityId}/join`, joinOpportunity));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);
    const opportunities: IOpportunityWithRelations[] = getState().list;
    const index: number = opportunities.findIndex((opportunity: IOpportunityWithRelations): boolean => opportunity.id! === opportunityId);
    if (index !== -1) {
      if (!opportunities[index].JoinOpportunityRequest) opportunities[index].JoinOpportunityRequest = [];
      opportunities[index].JoinOpportunityRequest.push(response.entity!);
      patchState({
        list: [... opportunities]
      });
    }
  }

  @Action(OpportunityActions.CreateResolution)
  async createResolution({getState, patchState}: StateContext<IOpportunityState>, {organizationId, opportunityId, resolution}: OpportunityActions.CreateResolution): Promise<void> {
    const response: DataBaseServiceResponse<IOpportunityResolutionWithRelations> = await firstValueFrom(this.baseService.post<IOpportunityResolutionWithRelations>(`${this.SERVER}/organizations/${organizationId}/opportunities/${opportunityId}/resolutions`, resolution));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);
    patchState({
      resolutions: UtilityClass.updateOrPushItems(getState().resolutions, response.entity!, 'id'),
      resolutionsSelectedId: response.entity?.id
    });
  }

  @Action(OpportunityActions.AcceptedSupplier)
  async acceptedSupplier({getState, patchState}: StateContext<IOpportunityState>, {organizationId, opportunityId, resolutionId, supplier}: OpportunityActions.AcceptedSupplier): Promise<void> {
    const response: DataBaseServiceResponse<IOpportunityAcceptedSupplierWithRelations> = await firstValueFrom(this.baseService.post<IOpportunityAcceptedSupplierWithRelations>(`${this.SERVER}/organizations/${organizationId}/opportunities/${opportunityId}/resolutions/${resolutionId}/accepted-suppliers`, supplier));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);
    patchState({
      supplierAccepted: UtilityClass.updateOrPushItems(getState().supplierAccepted, response.entity!, 'id'),
      supplierAcceptedSelectedId: response.entity?.id
    });
  }

  @Action(OpportunityActions.GetResolution)
  async getResolution({getState, patchState}: StateContext<IOpportunityState>, {organizationId, opportunityId, resolutionId}: OpportunityActions.GetResolution): Promise<void> {
    const response: DataBaseServiceResponse<IOpportunityResolutionWithRelations> = await firstValueFrom(this.baseService.get<IOpportunityResolutionWithRelations>(`${this.SERVER}/organizations/${organizationId}/opportunities/${opportunityId}/resolutions/${resolutionId}`));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);
    patchState({
      resolutions: [response.entity!],
      resolutionsSelectedId: response.entity?.id
    });
  }

  @Action(OpportunityActions.DownloadResolution)
  async DownloadResolution(_: StateContext<IOpportunityState>, {organizationId, opportunityId, resolutionId, acceptedSupplierId, fileName, mimeType}: OpportunityActions.DownloadResolution): Promise<void> {
    const response: DataBaseServiceResponse<Blob> = await firstValueFrom(this.baseService.download(`${this.SERVER}/organizations/${organizationId}/opportunities/${opportunityId}/resolutions/${resolutionId}/accepted-suppliers/${acceptedSupplierId}/download`, mimeType));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);
    DocumentUtilClass.download(response.entity!, fileName);
  }
}
