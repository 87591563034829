import {APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {FormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CoreModule} from '@core/core.module';
import {provideEnvironmentNgxMask} from 'ngx-mask';
import {NgxChartsModule} from '@swimlane/ngx-charts';
import {NgxsModule} from '@ngxs/store';
import {NgxsReduxDevtoolsPluginModule} from '@ngxs/devtools-plugin';
import {NGX_STATES} from './app-ngxs.state';
import {NzMessageService} from 'ng-zorro-antd/message';
import {AuthInterceptor} from '@shared/interceptors';
import {NZ_CONFIG} from 'ng-zorro-antd/core/config';
import {NG_ZORRO_CONFIG} from './app-ng-zorro.config';
import {AuthService} from '@shared/services';
import {OrganizationService} from '@organization/services/organization.service';
import {NetworkCacheInterceptor} from '@shared/interceptors/network-cache.interceptor';
import {WzLoaderComponent} from '@shared/components/ui-elements/loader/wz-loader.component';
import {es_ES as nzEs, NZ_I18N} from 'ng-zorro-antd/i18n';

import es_ES from '@angular/common/locales/es';
import es_MX from '@angular/common/locales/es-MX';
import {registerLocaleData} from '@angular/common';
import * as Sentry from '@sentry/angular-ivy';
import {Router} from '@angular/router';
import {GetTimeGroupFormat} from '@event/components/event-dates/event-dates.component';
registerLocaleData(es_MX, 'es-Mx');
registerLocaleData(es_ES, 'es');

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    CoreModule,
    NgxChartsModule,
    NgxsModule.forRoot(NGX_STATES),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    WzLoaderComponent,
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true,
      }),
    }, {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    { provide: NZ_I18N, useValue: nzEs },
    provideEnvironmentNgxMask({}),
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: NetworkCacheInterceptor, multi: true },
    { provide: NZ_CONFIG, useValue: NG_ZORRO_CONFIG },
    NzMessageService,
    AuthService,
    OrganizationService,
    GetTimeGroupFormat,
    { provide: LOCALE_ID, useValue: 'es-MX'},
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
