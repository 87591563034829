import {Pipe, PipeTransform} from '@angular/core';
import {ValidationErrors} from '@angular/forms';

@Pipe({
  name: 'errorMessage',
  standalone: true,
})
export class GetErrorMessagePipe implements PipeTransform {
  transform(validationError?: ValidationErrors | null): string | undefined {
    if (!validationError) return;
    try {
      switch (true) {
        case validationError['minlength'] !== undefined: return `Debe tener al menos ${validationError['minlength']['requiredLength']} caracteres`;
        case validationError['maxlength'] !== undefined: return `Debe tener menos de ${validationError['maxlength']['requiredLength']} caracteres`;
        case validationError['curp'] !== undefined: return 'El formato de la CURP es incorrecto';
        case validationError['rfc'] !== undefined: return 'El formato de la RFC es incorrecto';
        case validationError['rfcAlreadyExists'] !== undefined: return 'Ya existe una empresa con el mismo RFC';
        case validationError['dependencyAlreadyExist'] !== undefined: return 'Ya existe la dependencia registrada';
        case validationError['rfcNatural'] !== undefined: return 'RFC de tipo persona moral';
        case validationError['rfcCorporate'] !== undefined: return 'RFC de tipo persona física';
        case validationError['required'] !== undefined: return 'Campo requerido';
        case validationError['email'] !== undefined: return 'Correo electrónico inválido';
        case validationError['webPage'] !== undefined: return 'Url inválida';
        case validationError['min'] !== undefined: return `El valor no debe ser menor a ${validationError['min']['min']}`;
        case validationError['max'] !== undefined: return `El valor no debe ser mayor a ${validationError['max']['min']}`;
        case validationError['mask'] !== undefined: return 'Formato no válido';
        default: return 'Error desconocido';
      }
    } catch (ex) {
      return 'Error desconocido';
    }
  }
}
