import {Pipe, PipeTransform} from '@angular/core';
import {IAnyObject} from '@shared/interfaces/any-object.interface';

@Pipe({
  name: 'chartsBarHorizontalHeight',
  standalone: true,
})
export class NgxChartsBarHorizontalHeightPipe implements PipeTransform {
  transform(items: IAnyObject[]): number {
    if (items.length <= 5) return 250;
    if (items.length <= 10) return 300;
    if (items.length <= 15) return 400;
    if (items.length <= 20) return 500;
    if (items.length <= 25) return 600;
    if (items.length <= 30) return 700;

    return 700;
  }

}
