import {ErrorTypeEnum} from '@shared/class/errors/enums/error-type.enum';
import {ErrorException} from '@shared/class/errors/error.exception';

export class UnsafePasswordException extends ErrorException {
  constructor(message: string = 'La contraseña no es suficientemente segura', internalCode?: string) {
    super(message);
    this.name = ErrorTypeEnum.UNSAFE_PASSWORD;
    this.internalCode = internalCode;
  }
}
