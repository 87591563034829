import {Pipe, PipeTransform} from '@angular/core';
import {groupBy} from 'lodash';
import {format, isBefore} from 'date-fns';
import {IEventSchedule} from '@event/interfaces';

export interface IScheduleGroup {
  day: string;
  date: Date;
  isBeforeToday: boolean;
  schedules: IEventSchedule[];
}

@Pipe({
  name: 'scheduleGroup',
  standalone: true,
})
export class ScheduleGroupPipe implements PipeTransform {

  transform(schedules?: IEventSchedule[]): IScheduleGroup[]  {
    const group: {[day: string]: IEventSchedule[]} = groupBy(schedules ?? [], (item: IEventSchedule): string => format(item.startDate, 'dd/MM/yyyy'));
    return Object.entries(group).map(([day, schedules]: [string, IEventSchedule[]]): IScheduleGroup => {
      return {
        day,
        isBeforeToday: isBefore(schedules[0].startDate, new Date()),
        date: schedules[0].startDate,
        schedules,
      };
    });
  }

}
