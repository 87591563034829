import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'initialLetter',
  standalone: true,
})
export class GetInitialLetterPipe implements PipeTransform {
  transform(text?: string, length: number = 1): string {
    if (!text) return '';

    return text.substring(0, length).toUpperCase();
  }
}
