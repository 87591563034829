import {Injectable} from '@angular/core';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {BaseService} from '@shared/services/base/base.service';
import {DataBaseServiceResponse} from '@shared/services/base/interfaces/data-base-service-response.interface';
import {firstValueFrom} from 'rxjs';

import {IAccessToken, ICredentials, IIssueJWT, ILogin} from '@auth/interfaces';
import {StorageService} from '@shared/services/storage/storage.service';
import {ErrorHandlerService} from '@shared/services/error-handler.service';
import {environment} from '@env/environment';
import {IStorageCredentials} from '@shared/services/storage/interfaces/storage-credentials.interface';
import {IStorageOrganization, IStorageUser} from '@shared/services/storage/interfaces';
import {StorageItemEnum} from '@shared/services/storage/enums/storage-item.enum';

export interface IAuthState {
  credentials: IStorageCredentials | null;
  user: IStorageUser | null;
  organization: IStorageOrganization | null;
}

export namespace AuthActions {
  export class Login {
    static readonly type: string = '[Auth] Login';
    constructor(public login: ILogin) {}
  }

  export class IssueToken {
    static readonly type: string = '[Auth] Issue token';
    constructor() {}
  }

  export class Logout {
    static readonly type: string = '[Auth] Logout';
  }

  export class SetStorage {
    static readonly type: string = '[Auth] Set storage';
  }

  export class Reset {
    static readonly type: string = '[Auth] Reset';
  }
}


const _DEFAULT_DATA: IAuthState = {
  credentials: null,
  user: null,
  organization: null,
};

@State<IAuthState>({
  name: 'AuthState',
  defaults: {..._DEFAULT_DATA},
})
@Injectable()
export class AuthState {
  private readonly SERVER: string = environment.SERVER;

  constructor(
    private baseService: BaseService,
    private errorHandlerService: ErrorHandlerService,
    private storageService: StorageService,
  ) { }

  @Selector()
  static getCredentials({credentials}: IAuthState): IStorageCredentials | null {
    return credentials;
  }

  @Selector()
  static getUser({user}: IAuthState): IStorageUser | null {
    return user;
  }

  @Selector()
  static getOrganization({organization}: IAuthState): IStorageOrganization | null {
    return organization;
  }


  @Action(AuthActions.Login)
  async login({patchState}: StateContext<IAuthState>, {login}: AuthActions.Login): Promise<void> {
    const response: DataBaseServiceResponse<ICredentials> = await firstValueFrom(this.baseService.post<ICredentials>(`${this.SERVER}/auth/login`, login));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    this.storageService.setCredentials(response.entity!);
    this.storageService.setUser(response.entity!.user!);
    patchState({
      credentials: this.storageService.getCredentials(),
      user: this.storageService.getUser(),
    });
  }


  @Action(AuthActions.IssueToken)
  async issueToken(): Promise<void> {
    const credentials: IStorageCredentials | null = this.storageService.getCredentials();
    const user: IStorageUser | null = this.storageService.getUser();
    const issueJWT: IIssueJWT = {
      userId: user!.id,
      refreshToken: credentials?.refreshToken!,
    }
    const response: DataBaseServiceResponse<IAccessToken> = await firstValueFrom(this.baseService.post<IAccessToken>(`${this.SERVER}/auth/issue-token`, issueJWT));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    this.storageService.setCredentials({
      refreshToken: credentials!.refreshToken,
      accessToken: response.entity!.accessToken,
    });
  }


  @Action(AuthActions.SetStorage)
  async setStorageUser({patchState}: StateContext<IAuthState>): Promise<void> {
    const credentials: IStorageCredentials | null = this.storageService.getCredentials()
    if (!credentials) {
      this.storageService.remove(StorageItemEnum.CREDENTIALS);
      this.storageService.remove(StorageItemEnum.USER);
      this.storageService.remove(StorageItemEnum.ORGANIZATION);
      this.storageService.remove(StorageItemEnum.ORGANIZATION_TYPE);
      return;
    }
    patchState({
      credentials: this.storageService.getCredentials(),
      user: this.storageService.getUser(),
      organization: this.storageService.getOrganization(),
    });
  }

  @Action(AuthActions.Logout)
  logout(): void {
    this.storageService.clear();
  }

  @Action(AuthActions.Reset)
  reset({setState}: StateContext<IAuthState>): void {
    setState(_DEFAULT_DATA);
  }
}
