import {IServiceCreateDto, IServiceUpdateDto, IServiceWithRelations} from '@service/interfaces';
import {PrismaFilter} from '@shared/services/base/interfaces/prisma-filter.interface';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {environment} from '@env/environment';
import {BaseService} from '@shared/services';
import {ErrorHandlerService} from '@shared/services/error-handler.service';
import {DataBaseServiceResponse} from '@shared/services/base/interfaces/data-base-service-response.interface';
import {firstValueFrom} from 'rxjs';
import {UtilityClass} from '@shared/class/utils';
import {omit as _omit} from 'lodash';

export interface IServiceState {
  services: IServiceWithRelations[];
  selectedId: string | null;
  count: number;
}

const _DEFAULT: IServiceState = {
  services: [],
  selectedId: null,
  count: 0,
}

export namespace ServiceActions {
  export class Find {
    static readonly type: string = '[Service] Find';
    constructor(public organizationId: string, public filters: PrismaFilter<IServiceWithRelations>) {}
  }

  export class Count {
    static readonly type: string = '[Service] Count';
    constructor(public organizationId: string, public filters: PrismaFilter<IServiceWithRelations>) {}
  }

  export class FindById {
    static readonly type: string = '[Service] FindById';
    constructor(public id: string, public filters?: PrismaFilter<IServiceWithRelations>) {}
  }

  export class Post {
    static readonly type: string = '[Service] Post';
    constructor(public product: IServiceCreateDto) {}
  }

  export class CreateAsDraft {
    static readonly type: string = '[Service] Create As Draft';
    constructor(public product: IServiceCreateDto) {}
  }

  export class Publish {
    static readonly type: string = '[Service] Publish';
    constructor(public id: string) {}
  }

  export class Patch {
    static readonly type: string = '[Service] Patch';
    constructor(public id: string, public product: IServiceUpdateDto) {}
  }

  export class Delete {
    static readonly type: string = '[Service] Delete';
    constructor(public id: string) {}
  }

  export class Reset {
    static readonly type: string = '[Service] Reset';
    constructor() {}
  }
}

@State<IServiceState>({
  name: 'ServiceState',
  defaults: _DEFAULT
})
@Injectable()
export class ServiceState {
  private readonly SERVER: string = environment.SERVER;

  constructor(
    private baseService: BaseService,
    private errorHandlerService: ErrorHandlerService,
  ) {}

  @Selector()
  static getServices({services}: IServiceState): IServiceWithRelations[] {
    return services;
  }

  @Selector()
  static getServiceSelected({services, selectedId}: IServiceState): IServiceWithRelations | null {
    return services.find(({id}): boolean => id === selectedId) ?? null;
  }

  @Selector()
  static getCount({count}: IServiceState): number {
    return count;
  }

  @Action(ServiceActions.Find)
  async find({patchState}: StateContext<IServiceState>, {filters, organizationId}: ServiceActions.Find): Promise<void> {
    const response: DataBaseServiceResponse<IServiceWithRelations[]> = await firstValueFrom(this.baseService.get<IServiceWithRelations[]>(`${this.SERVER}/organizations/${organizationId}/services`, filters));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({
      services: response.entity!,
      selectedId: null,
    });
  }

  @Action(ServiceActions.FindById)
  async findById({patchState, getState}: StateContext<IServiceState>, {id, filters}: ServiceActions.FindById): Promise<void> {
    const response: DataBaseServiceResponse<IServiceWithRelations> = await firstValueFrom(this.baseService.get<IServiceWithRelations>(`${this.SERVER}/services/${id}`, filters));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({
      services: UtilityClass.updateOrPushItems<IServiceWithRelations>(getState().services, response.entity!, 'id'),
      selectedId: response.entity!.id!,
    });
  }

  @Action(ServiceActions.Count)
  async count({patchState}: StateContext<IServiceState>, {filters, organizationId}: ServiceActions.Count): Promise<void> {
    const response: DataBaseServiceResponse<number> = await firstValueFrom(this.baseService.count<IServiceWithRelations>(`${this.SERVER}/organizations/${organizationId}/services/count`, filters));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({count: response.entity ?? 0});
  }


  @Action(ServiceActions.Post)
  async post({patchState, getState}: StateContext<IServiceState>, {product}: ServiceActions.Post): Promise<void> {
    const response: DataBaseServiceResponse<IServiceWithRelations> = await firstValueFrom(this.baseService.post<IServiceWithRelations>(`${this.SERVER}/services`, product));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({
      services: UtilityClass.updateOrPushItems<IServiceWithRelations>(getState().services, response.entity!, 'id'),
      selectedId: response.entity!.id!,
    });
  }

  @Action(ServiceActions.CreateAsDraft)
  async createAsDraft({patchState, getState}: StateContext<IServiceState>, {product}: ServiceActions.CreateAsDraft): Promise<void> {
    const response: DataBaseServiceResponse<IServiceWithRelations> = await firstValueFrom(this.baseService.post<IServiceWithRelations>(`${this.SERVER}/services/draft`, product));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({
      services: UtilityClass.updateOrPushItems<IServiceWithRelations>(getState().services, response.entity!, 'id'),
      selectedId: response.entity!.id!,
    });
  }

  @Action(ServiceActions.Publish)
  async publish({patchState, getState}: StateContext<IServiceState>, {id}: ServiceActions.Publish): Promise<void> {
    const response: DataBaseServiceResponse<IServiceWithRelations> = await firstValueFrom(this.baseService.post<IServiceWithRelations>(`${this.SERVER}/services/${id}/publish`, {}));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);
  }

  @Action(ServiceActions.Patch)
  async patch({patchState, getState}: StateContext<IServiceState>, {product, id}: ServiceActions.Patch): Promise<void> {
    const response: DataBaseServiceResponse<IServiceWithRelations> = await firstValueFrom(this.baseService.patch<IServiceWithRelations>(`${this.SERVER}/services/${id}`, _omit(product, ['organizationId'])));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({
      services: UtilityClass.updateOrPushItems<IServiceWithRelations>(getState().services, response.entity!, 'id'),
      selectedId: response.entity!.id!,
    });
  }

  @Action(ServiceActions.Delete)
  async delete({patchState, getState}: StateContext<IServiceState>, {id}: ServiceActions.Delete): Promise<void> {
    const response: DataBaseServiceResponse<IServiceWithRelations> = await firstValueFrom(this.baseService.delete<IServiceWithRelations>(`${this.SERVER}/services/${id}`));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);
  }

  @Action(ServiceActions.Reset)
  async reset({setState}: StateContext<IServiceState>, _: ServiceActions.Reset): Promise<void> {
    setState(_DEFAULT);
  }
}
