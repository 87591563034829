import {
  IUser,
  IUserCreateUpdateDto,
  IUserProfile,
  IUserProfileAddress,
  IUserProfileCreateUpdateDto,
  IUserProfilePhoneNumber,
  IUserProfileWithRelations,
  IUserWithRelations
} from '@user/interfaces';
import {UtilityClass} from '@shared/class/utils/utility.class';
import {PrismaCountFilter, PrismaFilter} from '@shared/services/base/interfaces/prisma-filter.interface';
import {Action, Selector, State, StateContext, Store} from '@ngxs/store';
import {DataBaseServiceResponse} from '@shared/services/base/interfaces/data-base-service-response.interface';
import {ICredentials} from '@auth/interfaces';
import {firstValueFrom} from 'rxjs';
import {Injectable} from '@angular/core';
import {AuthService, BaseService, StorageService, UploadService} from '@shared/services';
import {environment} from '@env/environment';
import {AuthActions} from '@auth/state/auth.state';
import {ErrorHandlerService} from '@shared/services/error-handler.service';
import {NotFoundException} from '@shared/class/errors';
import {IAddress, IMedia, IPhoneNumber} from '@shared/interfaces';
import {PLATFORM_MESSAGES} from '@shared/config';
import {BadRequestException} from '@shared/class/errors/bad-request.exception';
import {IChangePassword, IPasswordRecovery, IResetPassword} from '@auth/interfaces/change-password.interface';
import {omit as _omit} from 'lodash';
import {PhoneBuilder} from '@shared/components/ui-elements/information/phones/phone-builder.util';
import {AddressBuilder} from '@shared/components/ui-elements/information/addresses/address-builder.util';
import {IStorageUser} from '@shared/services/storage/interfaces';
import {OrganizationTypeEnum} from '@organization/enums';

export interface IFolioData {
  name: string;
  lastName: string;
  email: string;
  tradeName: string;
  legalName: string;
  organizationType: OrganizationTypeEnum;
  rfc: string;
}

export interface IUserState {
  list: IUserWithRelations[];
  userSelectedId: string | null;
  userCount: number;

  folioData: IFolioData | null;
}

export namespace UserActions {
  export class Me {
    static readonly type: string = '[User] Me';
  }

  export class Reset {
    static readonly type: string = '[User] Reset';
  }

  export class GetList {
    static readonly type: string = '[User] Get List';

    constructor(public filters?: PrismaFilter<IUser>) {
    }
  }

  export class SetList {
    static readonly type: string = '[User] Set List';

    constructor(public list: IUserWithRelations[]) {
    }
  }

  export class SetCount {
    static readonly type: string = '[User] Set Count';

    constructor(public count: number) {
    }
  }

  export class Post {
    static readonly type: string = '[User] Post';

    constructor(public entity?: IUserCreateUpdateDto) {
    }
  }

  export class PatchById {
    static readonly type: string = '[User] Patch By Id';

    constructor(public id: string, public entity: Partial<IUser>) {
    }
  }

  export class ChangePassword {
    static readonly type: string = '[User] Change password';
    constructor(public userId: string, public changePassword: IChangePassword) {
    }
  }

  export class ResetPassword {
    static readonly type: string = '[User] Reset password';
    constructor(public userId: string, public resetPassword: IResetPassword) {
    }
  }

  export class PasswordRecovery {
    static readonly type: string = '[User] Password recovery';

    constructor(public passwordRecovery: IPasswordRecovery) {
    }
  }



  export class GetById {
    static readonly type: string = '[User] Get User By Id';

    constructor(public id: string, public filters?: PrismaCountFilter<IUser>) {
    }
  }

  export class PatchProfileById {
    static readonly type: string = '[User] Patch profile';

    constructor(public userId: string, public userProfile?: IUserProfileCreateUpdateDto) {
    }
  }

  export class PostProfile {
    static readonly type: string = '[User] Post profile';

    constructor(public userId: string, public userProfile?: IUserProfileCreateUpdateDto) {
    }
  }

  export class PostProfilePhone {
    static readonly type: string = '[User] Post Profile Phone';

    constructor(public userId: string, public phone: IPhoneNumber, public phoneBuilder: PhoneBuilder) {
    }
  }

  export class PatchProfilePhone {
    static readonly type: string = '[User] Patch Profile Phone';

    constructor(public userId: string, public phone: IPhoneNumber, public phoneBuilder: PhoneBuilder) {
    }
  }

  export class DeleteProfilePhone {
    static readonly type: string = '[User] Delete profile phone';
    constructor(public userId: string, public phoneId: string, public phoneBuilder: PhoneBuilder) {
    }
  }

  export class PostProfileAddress {

    static readonly type: string = '[User] Post profile address';
    constructor(public userId: string, public address: IAddress, public addressBuilder: AddressBuilder) {}
  }

  export class PatchProfileAddress {

    static readonly type: string = '[User] Patch profile address';
    constructor(public userId: string, public address: IAddress, public addressBuilder: AddressBuilder) {}
  }

  export class DeleteProfileAddress {
    static readonly type: string = '[User] Delete profile address';

    constructor(public userId: string, public addressId: string, public addressBuilder: AddressBuilder) {
    }
  }


  export class SetUserSelected {
    static readonly type: string = '[User] Clear user selected list';

    constructor(public id: string | null = null) {
    }
  }

  export class UploadProfileImage {
    static readonly type: string = '[User] Upload Profile Image';
    constructor(public userId: string, public file: any) {
    }
  }

  export class GetDataByFolio {
    static readonly type: string = '[User] Get Data By Folio';
    constructor(public folio: string) {
    }
  }
}


const _DEFAULT_DATA: IUserState = {
  list: [],
  userSelectedId: null,
  userCount: 0,

  folioData: null
};

@State<IUserState>({
  name: 'UserState',
  defaults: {..._DEFAULT_DATA},
})
@Injectable()
export class UserState {
  private readonly SERVER: string = environment.SERVER;

  constructor(
    private authService: AuthService,
    private baseService: BaseService,
    private errorHandlerService: ErrorHandlerService,
    private storageService: StorageService,
    private store: Store,
    private uploadService: UploadService
  ) {
  }

  /**
   * Update auth user
   * @param userId
   * @param user
   * @private
   */
  private async updateAuthUser(userId: string, user: IUserWithRelations): Promise<void> {
    const storageUser: IStorageUser | null = this.storageService.getUser();
    if (userId !== storageUser?.id) return;
    this.storageService.setUser(user);
    await firstValueFrom(this.store.dispatch(new AuthActions.SetStorage()));
  }

  @Selector()
  static getFolioData(state: IUserState): IFolioData | null {
    return state.folioData;
  }

  @Selector()
  static getUserList(state: IUserState): IUser[] {
    return state.list;
  }

  @Selector()
  static getUserSelected(state: IUserState): IUserWithRelations | undefined {
    return state.list.find(({id}: IUser): boolean => id === state.userSelectedId);
  }

  @Selector()
  static getSectorCount({userCount}: IUserState): number {
    return userCount;
  }

  @Action(UserActions.Me)
  async me({dispatch, getState, patchState}: StateContext<IUserState>): Promise<void> {
    const response: DataBaseServiceResponse<IUserWithRelations> = await firstValueFrom(this.baseService.get<IUserWithRelations>(`${this.SERVER}/users/me`));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    this.storageService.setUser(response.entity!);
    await firstValueFrom(dispatch(new AuthActions.SetStorage()));
    patchState({
      list: UtilityClass.updateOrPushItems<IUserWithRelations>(getState().list, response.entity!, 'id'),
      userSelectedId: response.entity!.id!
    });
  }

  @Action(UserActions.Reset)
  reset({setState}: StateContext<IUserState>): void {
    setState(_DEFAULT_DATA);
  }

  @Action(UserActions.GetList)
  async GetList(
    {patchState}: StateContext<IUserState>,
    {filters}: UserActions.GetList
  ): Promise<void> {
    const response: DataBaseServiceResponse<IUser[]> = await firstValueFrom(this.baseService.get<IUser[]>(`${this.SERVER}/users`, filters));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({
      list: response.entity ?? [],
    });
  }

  @Action(UserActions.GetDataByFolio)
  async getDataByFolio(
    {patchState}: StateContext<IUserState>,
    {folio}: UserActions.GetDataByFolio
  ): Promise<void> {
    const response: DataBaseServiceResponse<IFolioData> = await firstValueFrom(this.baseService.get<IFolioData>(`${this.SERVER}/organizations/gto/folio/${folio}/information`));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({
      folioData: response.entity ?? null,
    });
  }

  @Action(UserActions.SetList)
  async setList({patchState}: StateContext<IUserState>, {list}: UserActions.SetList): Promise<void> {
    patchState({list});
  }

  @Action(UserActions.SetCount)
  async setCount({patchState}: StateContext<IUserState>, {count}: UserActions.SetCount): Promise<void> {
    patchState({userCount: count});
  }

  @Action(UserActions.Post)
  async post(
    {patchState, getState}: StateContext<IUserState>,
    {entity}: UserActions.Post
  ): Promise<void> {
    const response: DataBaseServiceResponse<ICredentials> = await firstValueFrom(this.baseService.post<ICredentials>(`${this.SERVER}/users`, entity));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    this.storageService.setCredentials(response.entity!);
    this.storageService.setUser(response.entity!.user!);

    patchState({
      list: [...getState().list, response.entity!.user!],
      userSelectedId: response.entity?.user?.id,
    });

  }

  @Action(UserActions.PatchById)
  async patchById(
    {patchState, getState, dispatch}: StateContext<IUserState>,
    {entity, id}: UserActions.PatchById
  ): Promise<void> {
    const response: DataBaseServiceResponse<IUserWithRelations> = await firstValueFrom(this.baseService.patch<IUserWithRelations>(`${this.SERVER}/users/${id}`, entity));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    const storageUser: IStorageUser | null = this.storageService.getUser();
    if (storageUser && id === storageUser.id) {
      this.storageService.setUser(response.entity!);
      await firstValueFrom(dispatch(new AuthActions.SetStorage()));
    }


    patchState({
      list: UtilityClass.updateOrPushItems<IUserWithRelations>(getState().list, response.entity!, 'id'),
      userSelectedId: id,
    });
  }

  @Action(UserActions.ChangePassword)
  async changePassword(
    {}: StateContext<IUserState>,
    {changePassword, userId}: UserActions.ChangePassword
  ): Promise<void> {
    const response: DataBaseServiceResponse<void> = await firstValueFrom(this.baseService.patch<void>(`${this.SERVER}/users/${userId}/change-password`, changePassword));
    if (response.error) throw new BadRequestException(PLATFORM_MESSAGES.USER.PASSWORD_NOT_UPDATED);
  }

  @Action(UserActions.ResetPassword)
  async resetPassword(
    {}: StateContext<IUserState>,
    {resetPassword, userId}: UserActions.ResetPassword
  ): Promise<void> {
    const response: DataBaseServiceResponse<void> = await firstValueFrom(this.baseService.patch<void>(`${this.SERVER}/users/${userId}/reset-password`, resetPassword));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);
  }

  @Action(UserActions.PasswordRecovery)
  async passwordRecovery(
    {}: StateContext<IUserState>,
    {passwordRecovery}: UserActions.PasswordRecovery
  ): Promise<void> {
    const response: DataBaseServiceResponse<void> = await firstValueFrom(this.baseService.post<void>(`${this.SERVER}/users/password-recovery`, passwordRecovery));
    if (response.error) throw new BadRequestException(PLATFORM_MESSAGES.USER.NOT_FOUND);
  }


  @Action(UserActions.GetById)
  async GetById(
    {patchState, getState}: StateContext<IUserState>,
    {id}: UserActions.GetById
  ): Promise<void> {
    const response: DataBaseServiceResponse<IUserWithRelations> = await firstValueFrom(this.baseService.get<IUserWithRelations>(`${this.SERVER}/users/${id}`));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({
      list: UtilityClass.updateOrPushItems<IUser>(getState().list, response.entity!, 'id'),
      userSelectedId: id,
    });
  }

  @Action(UserActions.PatchProfileById)
  async patchProfile(
    {patchState, getState}: StateContext<IUserState>,
    {userId, userProfile}: UserActions.PatchProfileById
  ): Promise<void> {
    const response: DataBaseServiceResponse<IUserProfileWithRelations> = await firstValueFrom(this.baseService.patch<IUserProfileWithRelations>(`${this.SERVER}/users/${userId}/profiles`, userProfile));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    const user: IUserWithRelations | undefined = getState().list.find((user: IUserWithRelations): boolean => user.id === userId);
    if (!user) throw new NotFoundException(PLATFORM_MESSAGES.USER.NOT_FOUND);

    await this.updateAuthUser(userId, {...user, UserProfile: {...user.UserProfile, ...response.entity!}})
    patchState({
      list: UtilityClass.updateOrPushItems<IUserWithRelations>(getState().list, {
        ...user,
        UserProfile: response.entity!
      }, 'id'),
      userSelectedId: userId,
    });
  }

  @Action(UserActions.PostProfile)
  async postProfile(
    {patchState, getState}: StateContext<IUserState>,
    {userId, userProfile}: UserActions.PostProfile
  ): Promise<void> {
    const response: DataBaseServiceResponse<IUserProfile> = await firstValueFrom(this.baseService.post<IUserProfile>(`${this.SERVER}/users/${userId}/profiles`, userProfile));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    const user: IUserWithRelations | undefined = getState().list.find((user: IUserWithRelations): boolean => user.id === userId);
    if (!user) throw new NotFoundException(PLATFORM_MESSAGES.USER.NOT_FOUND);

    await this.updateAuthUser(userId, {...user, UserProfile: response.entity!});
    patchState({
      list: UtilityClass.updateOrPushItems<IUserWithRelations>(getState().list, {
        ...user,
        UserProfile: response.entity!
      }, 'id'),
      userSelectedId: userId,
    });
  }

  @Action(UserActions.PostProfilePhone)
  async postProfilePhone(
    _: StateContext<IUserState>,
    {phone, userId, phoneBuilder}: UserActions.PostProfilePhone
  ): Promise<void> {
    const response: DataBaseServiceResponse<IUserProfilePhoneNumber> = await firstValueFrom(this.baseService.post<IUserProfilePhoneNumber>(`${this.SERVER}/users/${userId}/profiles/phones`, _omit(phone, ['id'])));
    if (response.error) return;
    phoneBuilder.replaceIdBy(phone.id!, response.entity?.phoneNumberId ?? '');
  }

  @Action(UserActions.PatchProfilePhone)
  async patchProfilePhone(
    _: StateContext<IUserState>,
    {phone, userId, phoneBuilder}: UserActions.PatchProfilePhone
  ): Promise<void> {
    const response: DataBaseServiceResponse<IPhoneNumber> = await firstValueFrom(this.baseService.patch<IPhoneNumber>(`${this.SERVER}/users/${userId}/profiles/phones/${phone.id}`, phone));
    if (!response.error) return;
  }


  @Action(UserActions.DeleteProfilePhone)
  async deleteProfilePhone(
    _: StateContext<IUserState>,
    {userId, phoneId, phoneBuilder}: UserActions.DeleteProfilePhone,
  ): Promise<void> {
    const response: DataBaseServiceResponse<IPhoneNumber> = await firstValueFrom(this.baseService.delete<IPhoneNumber>(`${this.SERVER}/users/${userId}/profiles/phones/${phoneId}`));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);
    phoneBuilder.deleteById(phoneId);
  }

  @Action(UserActions.PostProfileAddress)
  async postProfileAddresses(
    _: StateContext<IUserState>,
    {address, userId, addressBuilder}: UserActions.PostProfileAddress
  ): Promise<void> {
    const response: DataBaseServiceResponse<IUserProfileAddress> = await firstValueFrom(this.baseService.post<IUserProfileAddress>(`${this.SERVER}/users/${userId}/profiles/addresses`, _omit(address, ['id'])));
    if (response.error) return;
    addressBuilder.replaceIdBy(address.id!, response.entity?.addressId ?? '');
  }

  @Action(UserActions.PatchProfileAddress)
  async postPatchAddresses(
    _: StateContext<IUserState>,
    {address, userId}: UserActions.PatchProfileAddress
  ): Promise<void> {
    const response: DataBaseServiceResponse<IAddress> = await firstValueFrom(this.baseService.patch<IAddress>(`${this.SERVER}/users/${userId}/profiles/addresses/${address.id}`, address));
    if (!response.error) return;
  }

  @Action(UserActions.DeleteProfileAddress)
  async deleteProfileAddress(
    _: StateContext<IUserState>,
    {userId, addressId, addressBuilder}: UserActions.DeleteProfileAddress
  ): Promise<void> {
    const response: DataBaseServiceResponse<IAddress> = await firstValueFrom(this.baseService.delete<IAddress>(`${this.SERVER}/users/${userId}/profiles/addresses/${addressId}`));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);
    addressBuilder.deleteById(addressId);
  }

  @Action(UserActions.SetUserSelected)
  async ClearUserSelectedId({patchState}: StateContext<IUserState>, {id}: UserActions.SetUserSelected,): Promise<void> {
    patchState({userSelectedId: id});
  }

  @Action(UserActions.UploadProfileImage)
  async uploadProfileImage({getState}: StateContext<IUserState>, {userId, file}: UserActions.UploadProfileImage): Promise<void> {
    const response: DataBaseServiceResponse<IMedia> = await this.uploadService.upload<IMedia>(`users/${userId}/upload-profile-picture`, file as File);
    const storageUser: IStorageUser | null = this.storageService.getUser();
    if (storageUser?.id !== userId) return;

    const user: IUserWithRelations = getState().list.find(({id}: IUserWithRelations): boolean => id === userId)!;
    this.storageService.setUser({
      ...user,
      Media: [response.entity!],
    });
    await firstValueFrom(this.store.dispatch(new AuthActions.SetStorage()));
  }
}
