import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {tap} from 'rxjs/operators';
import {CachedRequest} from '@shared/class/utils';

@Injectable()
export class NetworkCacheInterceptor implements HttpInterceptor {

  constructor() {
  }

  // An array of URL prefixes that should be cached
  private urlsToCache: string[] = [
    'organizations/belongs',
  ];
  // A map of cached requests, keyed by the full request URL with parameters
  private requests: Map<string, CachedRequest> = new Map<string, CachedRequest>();


  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.includes('auth/login')) {
      const belongsToRequestKey: string | undefined = [...this.requests.keys()].find((key: string) => key.includes('organizations/belongs'));
      if (belongsToRequestKey) {
        this.requests.delete(belongsToRequestKey);
      }
    }
    const secondsToCache: number = 20;
    // Only handle GET requests
    if (request.method !== 'GET') {
      return next.handle(request);
    }

    // Only cache requests for specified URL prefixes
    if (!this.urlsToCache.some(url => request.url.includes(url))) {
      return next.handle(request);
    }
    // Check if a cached response exists for this request
    const cachedResponse = this.requests.get(request.urlWithParams);
    if (cachedResponse !== undefined) {
      // Check if the cached response is still fresh enough to use
      const timeElapsed = (Date.now() - cachedResponse.responseTime) / 1000;
      if (timeElapsed < secondsToCache) {
        // Return the cached response
        return of(cachedResponse.response as any);
      } else {
        // If the cached response is stale, remove it from the cache
        this.requests.delete(request.urlWithParams);
      }
    }

    // If no cached response exists or the cached response is stale, make the request and cache the response
    return next.handle(request).pipe(
      tap((event) => {
        if (event instanceof HttpResponse) {
          const responseTime = Date.now();
          // Cache the response with a header indicating the max age of the cache
          this.requests.set(request.urlWithParams, new CachedRequest(request, event.clone({
            headers: event.headers.set('Cache-Control', `max-age=${secondsToCache}`),
            status: event.status,
            statusText: event.statusText
          }), responseTime));
        }
      })
    );
  }
}
