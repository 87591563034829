import {ErrorTypeEnum} from '@shared/class/errors/enums/error-type.enum';
import {ErrorException} from '@shared/class/errors/error.exception';

export class ForbiddenException extends ErrorException {
  constructor(message: string = 'Acceso denegado. No tienes permisos para realizar esta acción', internalCode?: string) {
    super(message);
    this.name = ErrorTypeEnum.FORBIDDEN;
    this.httpCode = 403;
    this.internalCode = internalCode;
  }
}
