export enum FileExtensionsEnum {
  PNG= 'PNG',
  JPG = 'JPG',
  JPEG = 'JPEG',
  PDF = 'PDF',
  XLSX = 'XLSX',
  DOCX = 'DOCX',
  PPTX = 'PPTX',
  NUMBERS = 'NUMBERS'
}
