import {Pipe, PipeTransform} from "@angular/core"
import {IEventDateTimeBlock} from '@event/interfaces';
import {IDateValidator} from '@shared/class/connection-date-validator';
import {DateClass} from '@shared/class/utils/date.class';
import {cloneDeep} from 'lodash';

@Pipe({
  name: 'hasSomeDate',
  standalone: true,
})

export class HasSomeDatePipe implements PipeTransform {
  transform(block: IEventDateTimeBlock, dates: IDateValidator[]): boolean {
    return !!dates.find((date: IDateValidator) => DateClass.isSomeMinutes(date.startDate, block.startDate!));
  }
}
