import {IDayDestructuringDate, IDestructuringDate} from '@shared/interfaces/destructuring-date.interface';

export class DateClass {
  static destructuringDate(date: Date | string): IDestructuringDate {
    const _date: Date = date instanceof Date ? date : new Date(date);

    return {
      date: _date,
      year: _date.getFullYear(),
      month: _date.getMonth(),
      day: _date.getDate(),
      hours: _date.getHours(),
      minutes: _date.getMinutes(),
      seconds: _date.getSeconds(),
      milliseconds: _date.getMilliseconds(),
    };
  }
  static destructuringShortDate(date: Date | string): IDayDestructuringDate {
    const _date: Date = date instanceof Date ? date : new Date(date);

    return {
      year: _date.getFullYear(),
      month: _date.getMonth(),
      day: _date.getDate()
    };
  }
  static createFromIDestructuringDate(date: Partial<IDestructuringDate>): Date {
    return new Date(
      date.year ?? new Date().getFullYear(),
      date.month ?? new Date().getMonth(),
      date.day ?? new Date().getDate(),
      date.hours ?? 0,
      date.minutes ?? 0,
      date.seconds ?? 0,
      date.milliseconds ?? 0
    );
  }

  static validate(date: string | Date | number): Date {
    if (date instanceof Date) return date;
    return new Date(date);
  }

  static isSameDay(date: Date, compareTo: Date): boolean {
    const a: IDestructuringDate = DateClass.destructuringDate(date);
    const b: IDestructuringDate = DateClass.destructuringDate(compareTo);
    return a.year === b.year && a.month === b.month && a.day === b.day;
  }

  static isSomeMinutes(date: Date, compareTo: Date): boolean {
    const a: IDestructuringDate = DateClass.destructuringDate(date);
    const b: IDestructuringDate = DateClass.destructuringDate(compareTo);
    return a.year === b.year && a.month === b.month && a.day === b.day && a.hours === b.hours && a.minutes === b.minutes;
  }
}
