import {Action, Selector, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {AuthActions} from '@auth/state/auth.state';
import {firstValueFrom} from 'rxjs';
import {MenuActions} from '@menu/state/menu.state';
import {SupplierActions} from '@supplier/state/supplier.state';
import {BuyerActions} from 'src/app/features/buyer/state/buyer.state';
import {CatalogActions} from '@platform/state/catalog.state';
import {UserActions} from '@user/state';
import {
  OrganizationActions,
  OrganizationCertificationActions,
  OrganizationContactActions,
  OrganizationMemberActions,
} from '@organization/state';
import {SidebarActions} from '@sidebar/state/sidebar.state';
import {ProductActions} from '@product/state/product.state';
import {ServiceActions} from '@service/state/service.state';
import {PhoneActions} from '../../features/phone/state/phone.state';
import {OpportunityActions} from '@opportunity/state/opportunity.state';
import {NotificationActions} from '../../features/notification/state/notification.state';
import {DocumentActions} from '@file-record/state/document.state';
import {GlobalConfigActions} from '../../features/global/state/global-config.state';
import {OrganizationSupplierNumberActions} from '@organization/state/organization-supplier-number.state';
import {IConnectionRequestConfig} from '@shared/state/interfaces';
import {DataBaseServiceResponse} from '@shared/services/base/interfaces/data-base-service-response.interface';
import {ErrorHandlerService} from '@shared/services/error-handler.service';
import {BaseService} from '@shared/services';
import {environment} from '@env/environment';

export interface IPlatformState {
}

export namespace PlatformActions {
  export class Reset {
    static readonly type: string = '[Platform] Reset';
    constructor() {}
  }
}

const _DEFAULT_DATA: IPlatformState = {
};

@State<IPlatformState>({
  name: 'PlatformState',
  defaults: {..._DEFAULT_DATA},
})
@Injectable()
export class PlatformState {
  private readonly SERVER: string = environment.SERVER;
  constructor(
    private baseService: BaseService,
    private errorHandlerService: ErrorHandlerService
  ) { }

  @Action(PlatformActions.Reset)
  async reset({dispatch}: StateContext<IPlatformState>): Promise<void>  {
    await firstValueFrom(dispatch([
      new AuthActions.Reset(),
      new CatalogActions.Reset(),
      new UserActions.Reset(),
      new OrganizationActions.Reset(),
      new OrganizationMemberActions.Reset(),
      new SidebarActions.Reset(),
      new MenuActions.Reset(),
      new OrganizationCertificationActions.Reset(),
      new SupplierActions.Reset(),
      new BuyerActions.Reset(),
      new ProductActions.Reset(),
      new ServiceActions.Reset(),
      new OrganizationContactActions.Reset(),
      new PhoneActions.Reset(),
      new OpportunityActions.Reset(),
      new NotificationActions.Reset(),
      new DocumentActions.Reset(),
      new GlobalConfigActions.Reset(),
      new OrganizationSupplierNumberActions.Reset()
    ]));
  }
}
