import {Component, Input} from '@angular/core';
import {Select, Store} from '@ngxs/store';
import {firstValueFrom, Observable, take} from 'rxjs';
import {
  AccountCheckActions,
  AccountCheckState
} from '@shared/components/ui-elements/account-check/state/account-check.state';
import {Router} from '@angular/router';
import {AccountCheckModalEnum} from '@shared/components/ui-elements/account-check/enums/account-check-modal.enum';
import {StorageService} from '@shared/services';
import {IStorageOrganization} from '@shared/services/storage/interfaces';
import {OrganizationTypeEnum} from '@organization/enums';

@Component({
  selector: 'address-account-check-modal',
  template: `
    <ng-template #titleTpl>
      <h4 class="font-semibold text-gray-700">Perfil empresarial incompleto</h4>
    </ng-template>
    <nz-modal
      [(nzVisible)]="isVisible"
      [nzOkDanger]="false"
      [nzTitle]="titleTpl"
      [nzWidth]="700"
      [nzFooter]="footerTpl"
      [nzClosable]="false"
    >
      <ng-container *nzModalContent>
        <div class="flex flex-col gap-2">
          <p class="text-gray-500">Para poder acceder a la totalidad de los servicios que ofrece BizGto, es indispensable que proporcione al menos una dirección en su perfil empresarial.</p>
          <p class="text-gray-500">Al completar esta información, usted tendrá acceso a una variedad de beneficios, incluyendo:</p>

          <ul class="list-disc ml-8">
            <li class="font-semibold">Participación en eventos</li>
            <li class="font-semibold">Conexiones con otras empresas</li>
            <li class="font-semibold" *ngIf="this.organization?.organizationType === OrganizationTypeEnum.BOTH || this.organization?.organizationType === OrganizationTypeEnum.BUYER">Oportunidades de negocio</li>
            <li class="font-semibold" *ngIf="this.organization?.organizationType === OrganizationTypeEnum.BOTH || this.organization?.organizationType === OrganizationTypeEnum.BUYER">Solicitud de cotizaciones a proveedores</li>
            <li class="font-semibold" *ngIf="this.organization?.organizationType === OrganizationTypeEnum.BOTH || this.organization?.organizationType === OrganizationTypeEnum.SUPPLIER">Postulaciones a oportunidades</li>
            <li class="font-semibold" *ngIf="this.organization?.organizationType === OrganizationTypeEnum.BOTH || this.organization?.organizationType === OrganizationTypeEnum.SUPPLIER">Registro de productos y/o servicios</li>
          </ul>

          <p class="text-gray-500"> Le invitamos a actualizar su perfil para aprovechar plenamente las oportunidades que BizGto tiene para ofrecer. </p>
        </div>
      </ng-container>

      <ng-template #footerTpl>
        <button *ngIf="(showRedirect$ | async)" nz-button nzType="default" (click)="handleCancel()">Cerrar</button>
        <button nz-button nzType="primary" (click)="handleOk()"> {{ (showRedirect$ | async) ? 'Ir a mi perfil' : 'Aceptar' }} </button>
      </ng-template>
    </nz-modal>
  `,
})
export class AddressAccountCheckModal {
  @Select(AccountCheckState.showRedirect) showRedirect$!: Observable<boolean>;
  @Input({ required: true }) isVisible: boolean = false;
  organization: IStorageOrganization | null;

  constructor(
    private storageService: StorageService,
    private store: Store,
    private router: Router,
  ) {
    this.organization = this.storageService.getOrganization();
  }

  async handleCancel(): Promise<void> {
    await firstValueFrom(this.store.dispatch(new AccountCheckActions.SetAccountCheck(null)));
  }

  async handleOk(): Promise<void> {
    const showRedirect: boolean = await firstValueFrom(this.showRedirect$.pipe(take(1)));
    if (!showRedirect) {
      await firstValueFrom(this.store.dispatch(new AccountCheckActions.SetAccountCheck(null)));
      return;
    }
    const organization: IStorageOrganization | null = this.storageService.getOrganization();
    if (!organization) return;

    await this.router.navigate(['/organizations', organization.id, 'details'], { queryParams: { missingData: AccountCheckModalEnum.ADDRESS } })
    await firstValueFrom(this.store.dispatch(new AccountCheckActions.SetAccountCheck(null)));
  }

  protected readonly OrganizationTypeEnum = OrganizationTypeEnum;
}

