import {Action, Selector, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {IConnectionRequestConfig, IPolicyPrivacy, ISlideItem, ITermsAndConditions} from '../interfaces';
import {DataBaseServiceResponse} from '@shared/services/base/interfaces/data-base-service-response.interface';
import {firstValueFrom} from 'rxjs';
import {BaseService} from '@shared/services';
import {ErrorHandlerService} from '@shared/services/error-handler.service';
import {environment} from '@env/environment';
import {PrismaFilter} from '@shared/services/base/interfaces/prisma-filter.interface';

export interface IGlobalConfigState {
  policy: IPolicyPrivacy | null;
  termsAndConditions: ITermsAndConditions | null;
  slideItemList: ISlideItem[];
  connectionRequest: IConnectionRequestConfig;
}

export namespace GlobalConfigActions {

  export class GetPolicyPrivacy {
    static readonly type: string = '[GlobalConfig] Get Policy Privacy';
    constructor() {}
  }


  export class PatchPolicyPrivacy {
    static readonly type: string = '[GlobalConfig] Patch Policy Privacy';
    constructor(policy: IPolicyPrivacy) {}
  }

  export class GetTermsAndConditions {
    static readonly type: string = '[GlobalConfig] Get Terms And Conditions';
    constructor() {}
  }

  export class PatchTermsAndConditions {
    static readonly type: string = '[GlobalConfig] Patch Terms And Conditions';
    constructor(termsAndConditions: ITermsAndConditions) {}
  }

  export class GetSlideItemList {
    static readonly type: string = '[GlobalConfig] Get Slide Item List';
    constructor(public filters?: PrismaFilter<ISlideItem>) {}
  }

  export class GetConnectionRequestConfig {
    static readonly type: string = '[GlobalConfig] Get Connection Request Config';
    constructor() {}
  }

  export class Reset {
    static readonly type: string = '[GlobalConfig] Reset';
    constructor() {}
  }

}

const _DEFAULT_DATA: IGlobalConfigState = {
  policy: null,
  termsAndConditions: null,
  slideItemList: [],
  connectionRequest: {
    applyDateOverlapValidations: false
  }
};

@State<IGlobalConfigState>({
  name: 'GlobalConfigState',
  defaults: {..._DEFAULT_DATA},
})
@Injectable()
export class GlobalConfigState {
  private readonly SERVER: string = environment.SERVER;
  constructor(
    private baseService: BaseService,
    private errorHandlerService: ErrorHandlerService,
  ) { }

  @Selector()
  static getConnectionRequestConfig({connectionRequest}: IGlobalConfigState): IConnectionRequestConfig {
    return connectionRequest;
  }

  @Selector()
  static getPolicyPrivacy({policy}: IGlobalConfigState): IPolicyPrivacy | null {
    return policy;
  }

  @Selector()
  static getTermsAndConditions({termsAndConditions}: IGlobalConfigState): ITermsAndConditions | null {
    return termsAndConditions;
  }

  @Selector()
  static getSlideItemList({slideItemList}: IGlobalConfigState): ISlideItem[] {
    return slideItemList;
  }

  // POLICY PRIVACY
  @Action(GlobalConfigActions.GetPolicyPrivacy)
  async getPolicyPrivacy({patchState}: StateContext<IGlobalConfigState>, _: GlobalConfigActions.GetPolicyPrivacy): Promise<void> {
    const response: DataBaseServiceResponse<IPolicyPrivacy> = await firstValueFrom(this.baseService.get<IPolicyPrivacy>(`${this.SERVER}/global-config/privacy-policy`, void 0));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({
      policy: response.entity!,
    });
  }

  // TERMS AND CONDITIONS
  @Action(GlobalConfigActions.GetTermsAndConditions)
  async getTermsAndConditions({patchState}: StateContext<IGlobalConfigState>, _: GlobalConfigActions.GetTermsAndConditions): Promise<void> {
    const response: DataBaseServiceResponse<ITermsAndConditions> = await firstValueFrom(this.baseService.get<ITermsAndConditions>(`${this.SERVER}/global-config/terms-and-conditions`, void 0));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({
      termsAndConditions: response.entity!,
    });
  }

  @Action(GlobalConfigActions.GetSlideItemList)
  async getSlideItemList({patchState}: StateContext<IGlobalConfigState>, {filters}: GlobalConfigActions.GetSlideItemList): Promise<void> {
    const response: DataBaseServiceResponse<ISlideItem[]> = await firstValueFrom(this.baseService.get<ISlideItem[]>(`${this.SERVER}/global-config/slides`, filters));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({
      slideItemList: response.entity!,
    });
  }

  @Action(GlobalConfigActions.GetConnectionRequestConfig)
  async getConnectionRequestConfig({patchState}: StateContext<IGlobalConfigState>): Promise<void>  {
    const response: DataBaseServiceResponse<IConnectionRequestConfig> = await firstValueFrom(this.baseService.get<IConnectionRequestConfig>(`${this.SERVER}/global-config/connection-requests`));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);
    patchState({
      connectionRequest: response.entity!
    });
  }

  @Action(GlobalConfigActions.Reset)
  async reset({setState}: StateContext<IGlobalConfigState>): Promise<void>  {
    setState(_DEFAULT_DATA)
  }
}
