import {NgModule} from '@angular/core';
import {InfoMessageComponent} from '@shared/components/ui-elements/messages/info-message.component';
import {NzIconModule} from 'ng-zorro-antd/icon';
import {InfoCircleFill} from '@ant-design/icons-angular/icons';
import {CommonModule} from '@angular/common';
import {WarningMessageComponent} from '@shared/components/ui-elements/messages/warning-message.component';
import {LabelIconInfoComponent} from '@shared/components/ui-elements/messages/label-icon-info.component';
import {NzToolTipModule} from 'ng-zorro-antd/tooltip';
import { SuccessMessageComponent } from './success-message.component';
import {NzPopoverModule} from 'ng-zorro-antd/popover';
import {ErrorMessageComponent} from '@shared/components/ui-elements/messages/error-message.component';

@NgModule({
  declarations: [
    InfoMessageComponent,
    WarningMessageComponent,
    LabelIconInfoComponent,
    SuccessMessageComponent,
    ErrorMessageComponent
  ],
  exports: [
    InfoMessageComponent,
    WarningMessageComponent,
    LabelIconInfoComponent,
    SuccessMessageComponent,
    ErrorMessageComponent
  ],
  imports: [
    CommonModule,
    NzIconModule.forChild([InfoCircleFill]),
    NzToolTipModule,
    NzPopoverModule
  ],
  providers: []
})
export class MessagesModule {

}
