import {NgModule} from '@angular/core';
import {
  AddressAccountCheckModal
} from '@shared/components/ui-elements/account-check/modals/address-account-check.modal';
import {NzModalModule} from 'ng-zorro-antd/modal';
import {AccountCheckComponent} from '@shared/components/ui-elements/account-check/components/account-check.component';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {NzButtonModule} from 'ng-zorro-antd/button';
import {NzToolTipModule} from 'ng-zorro-antd/tooltip';
import {NzWaveModule} from 'ng-zorro-antd/core/wave';

@NgModule({
  declarations: [
    AccountCheckComponent,
    AddressAccountCheckModal,
  ],
  imports: [
    CommonModule,
    RouterModule,
    NzModalModule,
    NzButtonModule,
    NzToolTipModule,
    NzWaveModule,
  ],
  exports: [
    AccountCheckComponent
  ]
})
export class AccountCheckModule {}
