import {Action, Selector, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';

export interface ISidebarState {
  active: boolean;
  hasItems: boolean,
}

const _DEFAULT_DATA: ISidebarState = {
  active: false,
  hasItems: false,
};

export namespace SidebarActions {

  export class Toggle {
    static readonly type: string = '[Sidebar] Toggle';
    constructor(public show?: boolean) {}
  }

  export class HasItems {
    static readonly type: string = '[Sidebar] Has items';
    constructor(public hasItems: boolean) {}
  }

  export class Reset {
    static readonly type: string = '[Sidebar] Reset';
  }
}

@State<ISidebarState>({
  name: 'SidebarState',
  defaults: _DEFAULT_DATA
})
@Injectable()
export class SidebarState {
  constructor() {}

  @Selector()
  static isActive({active}: ISidebarState): boolean {
    return active;
  }

  @Selector()
  static hasItems({hasItems}: ISidebarState): boolean {
    return hasItems;
  }

  @Action(SidebarActions.Toggle)
  show({patchState, getState}: StateContext<ISidebarState>, {show}: SidebarActions.Toggle): void {
    patchState({active: show !== undefined ? show : !getState().active});
  }

  @Action(SidebarActions.HasItems)
  hasItems({patchState, getState}: StateContext<ISidebarState>, {hasItems}: SidebarActions.HasItems): void {
    patchState({hasItems});
  }


  @Action(SidebarActions.Reset)
  reset({setState}: StateContext<ISidebarState>): void {
    setState(_DEFAULT_DATA);
  }

}
