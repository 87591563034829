import {Pipe, PipeTransform} from "@angular/core"
import {differenceInDays as _differenceInDays, addDays as _addDays} from "date-fns"
import {IDocumentTemplateWithRelations} from '@platform/catalog/interfaces/document-template.interface';

@Pipe({
  name: 'getDocumentExpiryDays',
  standalone: true,
})

export class GetDocumentExpiryDaysPipe implements PipeTransform {
  transform(document: IDocumentTemplateWithRelations): string | undefined {
    if (document.Document?.length === 0) return;

    const expiryDays: number = document.expiryDays;
    const updatedAt: Date = new Date(document.Document![0].updatedAt!);
    const differenceDays = _differenceInDays(_addDays(updatedAt, expiryDays), new Date());

    if (differenceDays > 7) return;

    if (differenceDays === 0) {
      return `<span class="text-orange-400 text-xs"> El documento expirá hoy.</span>`;
    }

    if (differenceDays < 0) {
      return `<span class="text-wz-red text-xs"> El documento a expirado.</span>`;

    }

    return `<span class="text-orange-400 text-xs"> El documento expirá en ${differenceDays} días. </span>`;
  }
}
