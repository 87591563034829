import {IAnyObject} from '@shared/interfaces';
import {OpportunityStatusEnum} from '@opportunity/enums';

export const OPPORTUNITY_STATUS_VALUES: IAnyObject<string> = {
  [OpportunityStatusEnum.IN_SEARCH_STATE]: 'Búsqueda estatal',
  [OpportunityStatusEnum.IN_SEARCH_NATIONAL]: 'Búsqueda nacional',
  [OpportunityStatusEnum.NOT_FOUND_LAST_WAIT]: 'Espera de consejo',
  [OpportunityStatusEnum.COMPLETED_SUCCESSFUL]: 'Completado satisfactoriamente',
  [OpportunityStatusEnum.COMPLETED_BY_USER]: 'Completado por el usuario',
  [OpportunityStatusEnum.COMPLETED_NOT_FOUND]: 'Completado sin encontrar',
}
