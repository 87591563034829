export const CLUSTER_GROUP_NAMES = [
  'IMEF - Instituto Mexicano de Ejecutivos de Finanzas A.C. Grupo Gto.',
  'APIMEX - Asociación de Proveedores Industriales Mexicanos',
  'AMEXME - Capítulo León-Asociación Mexicana de Mujeres Empresarias LEÓN',
  'CANIETI - Cámara Nacional de la Industria Electrónica, de Telecomunicaciones y Tecnologías de la Información',
  'IRYSA BAJIO - Industria de Reservaciones y Servicios de Alimentos de Gto. A.C.',
  'Clúster Aeroespacial del Bajío',
  'CLUTIG - CLÚSTER DE TECNOLOGÍAS DE LA INFORMACIÓN DE GUANAJUATO A.C.',
  'CICEG - Cámara de la Industria de Calzado del Estado de Gto.',
  'CICUR - Cámara de la Industria de Curtiduría del Estado de Gto.',
  'CNEMCO - Consejo Nacional de Empresas Consultoras (Consejo Nacional de la Industria del Conocimiento, A.C.)',
  'CLYM GTO - Cluster de Logistica y de Movilidad Guanajuato',
  'CANIRAC - Cámara de la Industria de Restaurantes y Alimentos Condimentados Delegación León y Estatal',
  'CANACAR - Cámara Nacional de Autotransporte de Carga Delegación Gto.',
  'CMIC - Cámara Mexicana de la Industria de la Construcción Delegación Gto.',
  'CANAINMA - Cámara Nacional de la Industria Maderera Delegación Gto.',
  'CANAINTEX -  Cámara Nacional de la Industria Textil Delegación Gto.',
  'CANAPAT - Cámara Nacional del Autotransporte de Pasaje y Turismo Delegación Guanajuato',
  'AMHM - Asociación Mexicana de Hoteles y Moteles de León',
  'CANACINTRA - León Cámara de la Industria de la Transformación Delegación León, Estado de Guanajuato',
  'CLAUGTO - Cluster Automotriz de Guanajuato',
  'FECANACO/CONCANACO',
  'CANACO Salamanca (Cámara Nacional de Comercio, Servicios y Turismo)',
  'CANACO Celaya (Cámara Nacional de Comercio, Servicios y Turismo)',
  'CANACO Guanajuato (Cámara Nacional de Comercio, Servicios y Turismo)',
  'CANACO SERVyTUR San Felipe',
  'CANACO SERVyTUR Silao - Romita',
  'CANACO SERVYTUR León',
  'CANACO Irapuato (Cámara Nacional de Comercio, Servicios y Turismo)',
  'CANACO San Miguel de Allende (Cámara Nacional de Comercio, Servicios y Turismo)',
  'CANACO Acámbaro (Cámara Nacional de Comercio, Servicios y Turismo)',
  'CANACO Valle de Santiago (Cámara Nacional de Comercio, Servicios y Turismo)',
  'CANACO Dolores Hidalgo C.I.N. (Cámara Nacional de Comercio, Servicios y Turismo)',
  'CANACO Salvatierra(Cámara Nacional de Comercio, Servicios y Turismo)',
  'CANACINTRA LEÓN (Cámara Nacional de la Industria de la Transformación)',
  'CANACINTRA Celaya (Cámara Nacional de la Industria de la Transformación)',
  'CANACINTRA Irapuato (Cámara Nacional de la Industria de la Transformación)',
  'COPARMEX Celaya (Confederación Patronal de la Republica Mexicana)',
  'COPARMEX León (Confederación Patronal de la Republica Mexicana)',
  'COPARMEX Irapuato (Confederación Patronal de la Republica Mexicana)',
  'CANIRAC- Cámara de la Industria de Restaurantes y Alimentos Condimentados Delegación León y Estatal',
  'CANIRAC Celaya (Cámara de la Industria de Restaurantes y Alimentos Condimentados Delegación Celaya)',
  'CANIRAC- Cámara de la Industria de Restaurantes y Alimentos Condimentados Delegación San Miguel de Allende',
  'CANIRAC- Cámara de la Industria de Restaurantes y Alimentos Condimentados Delegación Guanajuato',
  'Cámara Nacional de Autotransporte de Carga (CANACAR)',
  'Cámara Nacional de la Industria de Desarrollo y Promoción de Vivienda (CANADEVI), Delegación Guanajuato',
  'Cámara Mexicana de la Industria de la Construcción, Delegación Guanajuato (CMIC)',
  'CANAINPA. Cámara Nacional de la Industria Panificadora, Delegación Guanajuato',
  'CANAINMA- Cámara Nacional de la Industria Maderera Delegación Gto.',
  'CANIEM Cámara Nacional de la Industria Editorial Delegación Guanajuato',
  'CAMIMEX (Cámara Minera de México)',
  'CANAINTEX',
  'CANAPAT- Cámara Nacional del Autotransporte de Pasaje y Turismo Delegación Guanajuato',
  'Cámara Comercio México-E.U.',
  'CICEG (Cámara de la Industria de Calzado del Estado de Guanajuato)',
  'CONCAMIN Nacional (Confederación de Cámaras industriales)',
  'CICUR (Cámara de la Industria de la Curtiduría)',
  'CNENCO (Cámara Nacional de Empresas de Consultoría)',
  'APIMEX (Asociación Nacional de Proveedores de la Indusria del Calzado, A.C.)',
  'CANAIVE (Cámara Nacional de la Industria del Vestido) Delegación Guanajuato',
  'Círculo de Empresarios Celaya',
  'Círculo de Empresarios Irapuato',
  'CANIETI (Cámara Nacional de la Industria Electrónica, de Telecomunicaciones y Tecnologías de la Información)',
  'Cámara Nacional de la Industria de Artes Gráficas, Delegación Guanajuato CANAGRAF',
  'AMEXME Capítulo Celaya',
  'AMEXME Capítulo Irapuato',
  'AMEXME Capítulo Salamanca',
  'AMEXME Capítulo Pueblos del Rincón',
  'AMEXME Capítulo León',
  'AMEXME Capítulo Moroleón-Uriangato y Yuriria',
  'AMEXME Guanajuato',
  'AMEXME Región Bajio',
  'AMEXME Silao-Romita',
  'AMMJE Capítulo Celaya',
  'AMMJE Capítulo Irapuato',
  'IMEF (Instituto Mexicano de Ejecutivos de Finanzas, A.C. Grupo, Guanajuato)',
  'Red Estatal de Restaurantes A. C.',
  'Asociación Ganadera Local de León',
  'Federación de Productores de Maza y Tortilla de Guanajuato, A.C. ',
  'AMDA Asociación de Dristribuidores de Automotores del Estado de Guanajuato A.C.',
  'Asociación Mexicana de Hoteles y Moteles de León',
  'Asociación Mexicana de Hoteles y Moteles de San Miguel de Allende',
  'Asociación Mexicana de Hoteles y Moteles de Silao',
  'CONIQQ Guanajuato Colegio Nacional de Ingenieros Químicos y de Químicos, A.C.',
  'Asociación de Artesanos de Dolores Hidalgo',
  'Asociación de Empresarios del Rincón',
  'Asociación de Jóvenes Empresarios Irapuato',
  'Asociación Empresas de la Zona Industrial de Apaseo el Grande',
  'Cámara Nacional de Comerciantes Pequeños (CANACOPE) Irapuato',
  'Cámara Nacional de Comerciantes Pequeños (CANACOPE) Celaya',
  'Red Estatal de Restaurantes, Bares y Cantinas, A.C.,',
  'CANACINTRA Queretaro (Cámara de la Industria de la Transformación)',
  'Camara de la Industria del calzado del Estado de Jalisco CICEJ',
  'Consejo Coordinador Empresarial de León',
  'Consejo Coordinador Empresarial de Irapuato',
  'Consejo Coordinador Empresarial de Salamanca',
  'Consejo Coordinador Empresarial de Celaya',
  'Consejo Empresarial de San Miguel de Allende',
  'Consejo Empresarial de Guanajuato',
  'Consejo Coordinador Empresarial Cortazar',
  'Camara de Comercio México- Estados Unidos, Capitulo Valle de México',
  'Asociación Mexicana de la Industria de la Construcción, A.C.',
  'Asociación Mexicana de Hoteles y Moteles de León',
  'CANIETI (Cámara Nacional de la Industria Electrónica, de Telecomunicaciones y Tecnologías de la Información)',
  'Asociación Mexicana de Parques Industriales, AMPIP',
  'APIMEX (Asociación Nacional de Proveedores de la Indusria del Calzado, A.C.)'
];
