import {Component, Input, TemplateRef} from '@angular/core';


@Component({
  selector: 'label-icon-info',
  template: `
    <div class="flex gap-1 items-center">
      <label class="truncate">{{ label }}</label>
      <i *ngIf="!popOverTpl" class="las la-info-circle text-wz-primary cursor-pointer mb-2" nz-tooltip [nzTooltipTitle]="message"></i>
      <i *ngIf="popOverTpl" class="las la-info-circle text-wz-primary cursor-pointer mb-2" nz-popover  [nzPopoverContent]="popOverTpl"></i>
    </div>
  `,

})
export class LabelIconInfoComponent {
  @Input({required: true}) message!: string;
  @Input({required: true}) label!: string;
  @Input() popOverTpl!: TemplateRef<any>;
}
