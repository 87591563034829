export const PLATFORM_MESSAGES = {
  COLLABORATOR: {
    CREATED: 'Colaborador creado correctamente',
    UPDATED: 'Colaborador actualizado correctamente',
    PROFILE_IMAGE_UPDATED: 'Imagen de colaborador actualizada correctamente',
  },
  USER: {
    NOT_FOUND: 'Usuario no encontrado',
    UPDATED: 'Usuario actualizado correctamente',
    CREATED: 'Usuario creado correctamente',
    PASSWORD_UPDATED: 'Contraseña actualizada correctamente',
    PROFILE_IMAGE_UPDATED: 'Imagen de perfil actualizada correctamente',
    PASSWORD_NOT_UPDATED: 'Contraseña no actualizada. La contraseña anterior no coincide',
    PASSWORD_RECOVERY: 'Se ha enviado un correo a su dirección de correo electrónico.',
    PASSWORD_TOKEN_EXPIRED: 'El token ha expirado. Por favor, solicita uno nuevo.',
    DELETED: 'Usuario eliminado correctamente',
    ALREADY_BEEN_REGISTERED: 'El usuario ya ha sido registrado anteriormente',
    DELETED_ACCOUNT: 'La cuenta de usuario ha sido eliminada'
  },
  PROFILE: {
    NOT_FOUND: 'Perfil no encontrado',
    CREATED: 'Perfil creado correctamente',
    UPDATED: 'Perfil actualizado correctamente',
  },
  PRODUCT: {
    NOT_FOUND: 'Producto no encontrado',
    CREATED: 'Producto creado correctamente',
    UPDATED: 'Producto actualizado correctamente',
    PUBLISHED: 'Producto publicado correctamente',
    DELETED: 'Producto eliminado correctamente',
    REQUIRED_NAME: 'Para guardar un producto como borrador es requerido al menos el nombre',
    DUPLICATED: 'El producto se ha duplicado correctamente'
  },
  OPPORTUNITY: {
    NOT_FOUND: 'Oportunidad no encontrada',
    CREATED: 'Oportunidad creada correctamente',
    UPDATED: 'Oportunidad actualizada correctamente',
    DELETED: 'Oportunidad eliminada correctamente',
    FINALIZED: 'Oportunidad finalizada correctamente',
    BUDGET_REQUESTS: {
      REJECTED: 'La solicitud de cotización ha sido rechazada',
      ACCEPTED: 'La cotización ha sido enviada correctamente',
      EXPIRATION: 'La solicitud de cotización ha expirado',
      NOT_POSIBLE_ANSWER: 'La solicitud ya ha sido respondida anteriormente',
      NOT_MINE_QUOTE: 'La solicitud de cotización no te corresponde, no es posible responder a ella'
    },
    APPLY_TO: {
      SENT: 'Te has postulado correctamente a la oportunidad'
    }
  },
  SERVICE: {
    NOT_FOUND: 'Servicio no encontrado',
    CREATED: 'Servicio creado correctamente',
    UPDATED: 'Servicio actualizado correctamente',
    PUBLISHED: 'Servicio publicado correctamente',
    DELETED: 'Servicio eliminado correctamente',
    REQUIRED_NAME: 'Para guardar un servicio como borrador es requerido al menos el nombre',
    DUPLICATED: 'El servicio se ha duplicado correctamente'
  },
  SUPPLIER: {
    NOT_FOUND: 'Proveedor no encontrado',
    CREATED: 'Proveedor registrado correctamente',
    UPDATED: 'Proveedor actualizado correctamente',
    DELETED: 'Proveedor eliminado correctamente',
  },
  BUYER: {
    NOT_FOUND: 'Comprador no encontrado',
    CREATED: 'Comprador registrado correctamente',
    UPDATED: 'Comprador actualizado correctamente',
    DELETED: 'Comprador eliminado correctamente',
  },
  PHONE: {
    UPDATED: 'Teléfonos actualizados correctamente',
    DELETED: 'Teléfono eliminado correctamente',
    INVALID: 'Teléfono(s) inválido(s)',
    NOT_FOUND: 'Teléfono no encontrado',
  },
  ADDRESS: {
    CREATED: 'Dirección creada correctamente',
    UPDATED: 'Dirección actualizada correctamente',
    DELETED: 'Dirección eliminada correctamente',
    NOT_FOUND: 'Dirección no encontrada',
  },
  FILTERS: {
    APPLIED: 'Filtros aplicados correctamente',
  },
  GENERAL: {
    CANNOT_DELETED: 'Lo sentimos, no ha sido posible eliminar este registro',
    TRY_LATER: 'Ha ocurrido un error, intenta más tarde',
    RESET_FORM: 'Formulario limpiado correctamente',
    INVALID_FORM: 'Por favor, revisa el formulario',
  },
  ROLE: {
    NOT_FOUND: 'Rol no encontrado',
    CANNOT_PERMIT_UPDATED: 'Lo sentimos, no ha sido posible actualizar algunos permisos',
    CANNOT_DELETED: 'Lo sentimos, no ha sido posible eliminar el rol',
    ROLE_CREATED: 'Rol creado correctamente',
    ROLE_UPDATED: 'Rol actualizado correctamente',
    DELETED: 'Rol eliminado correctamente',
  },
  CATALOGS: {
    JOB_TITLE: {
      CREATED: 'Puesto de trabajo registrado correctamente',
      UPDATED: 'Puesto de trabajo actualizado correctamente',
      NOT_FOUND: 'Puesto de trabajo no encontrado',
      CANNOT_DELETED: 'Lo sentimos, este registro está asociado a una o más empresas',
      DELETED: 'Puesto de trabajo eliminado correctamente',
    },
    SECTOR: {
      CREATED: 'Sector económico registrado correctamente',
      UPDATED: 'Sector económico actualizado correctamente',
      NOT_FOUND: 'Sector económico no encontrado',
      CANNOT_DELETED: 'Lo sentimos, este registro está asociado a una o más empresas',
      DELETED: 'Sector eliminado correctamente',
    },
    LINE_OF_BUSINESS: {
      CREATED: 'Categoría de empresa registrada correctamente',
      UPDATED: 'Categoría de empresa actualizada correctamente',
      NOT_FOUND: 'Categoría de empresa no encontrada',
      CANNOT_DELETED: 'Lo sentimos, este registro está asociado a una o más empresas',
      DELETED: 'Categoría de empresa eliminada correctamente',
    },
    CERTIFICATION_CATEGORY: {
      CREATED: 'Categoría de certificación registrada correctamente',
      UPDATED: 'Categoría de certificación actualizada correctamente',
      NOT_FOUND: 'Categoría de certificación no encontrada',
      CANNOT_DELETED: 'Lo sentimos, este registro está asociado a una o más empresas',
      DELETED: 'Categoría de certificación eliminada correctamente',
    },
    CERTIFICATION: {
      CREATED: 'Certificación registrada correctamente',
      UPDATED: 'Certificación actualizada correctamente',
      NOT_FOUND: 'Certificación no encontrada',
      CANNOT_DELETED: 'Lo sentimos, este registro está asociado a una o más empresas',
      DELETED: 'Certificación eliminada correctamente',
    },
  },
  ORGANIZATION: {
    COMPLETE_REGISTRATION: 'Por favor, finalice el proceso de registro antes de continuar.',
    NOT_FOUND: 'Empresa no encontrada',
    CREATED: 'Empresa registrada correctamente',
    UPDATED: 'Empresa actualizada correctamente',
    DELETED: 'La empresa ha sido eliminada. Por favor, contacta al administrador.',
    NOT_ACCEPT_TERMS_AND_CONDITIONS: 'Por favor, debes aceptar los términos y condiciones',
    NOT_ACCEPT_POLICY: 'Por favor, debes aceptar las políticas de privacidad',
    IS_BLACK_LIST: 'La empresa se encuentra en la lista negra del SAT',
    DEPENDENCY_ALREADY_ASSIGNED: 'La dependencia/organismo ya está registrada; favor de comunicarse con la administración en caso de dudas.',
    RFC_MUST_BE_NATURAL_PERSON: 'El RFC de la empresa debería ser de tipo persona física.',
    RFC_MUST_BE_CORPORATE_PERSON: 'El RFC de la empresa debería ser de tipo persona moral.',
    RFC_MUST_BE_MEXICAN_SAT: 'El RFC de la empresa es inválido.',
    LOGO_IMAGE_UPDATED: 'Logo actualizado correctamente',
    BANNER_IMAGE_UPDATED: 'Portada actualizada correctamente',
    VALIDATION_PERIOD: 'La empresa se encuentra en periodo de validación',
    BLOCKED: 'La empresa se encuentra bloqueada',
    CERTIFICATION: {
      CREATED: 'Certificación registrada correctamente',
      UPDATED: 'Certificación actualizada correctamente',
      NOT_FOUND: 'Certificación no encontrada',
      CANNOT_DELETED: 'Lo sentimos, este registro está asociado a una o más empresas',
      DELETED: 'Certificación eliminada correctamente',
    },
    SUPPLIER_NUMBER: {
      CREATED: 'Número de proveedor registrado correctamente',
      UPDATED: 'Número de proveedor actualizado correctamente',
      NOT_FOUND: 'Número de proveedor no encontrado',
      DELETED: 'Número de proveedor eliminado correctamente',
    },
    PATENTS: {
      DELETED: 'Registro/patente eliminado correctamente'
    },
    CONTACT: {
      CREATED: 'Contacto registrado correctamente',
      UPDATED: 'Contacto actualizado correctamente',
      NOT_FOUND: 'Contacto no encontrado',
      CANNOT_DELETED: 'Lo sentimos, este registro está asociado a una o más empresas',
      CANNOT_PHONES_UPDATED: 'Lo sentimos, no ha sido posible actualizar algunos teléfonos',
      DELETED: 'Contacto eliminado correctamente',
      LEGAL_REPRESENTATIVE_UPDATED: 'El representante legal ha sido actualizado correctamente',
      LEGAL_REPRESENTATIVE_CREATED: 'El representante legal ha sido creado correctamente'
    }
  },
  SESSION: {
    EXPIRED: 'Tu sesión ha caducado. Por favor, inicia sesión nuevamente.',
    TRY_AGAIN: 'Por favor, intenta iniciar sesión'
  },
  DOCUMENT: {
    NOT_FOUND: 'Documento no encontrado',
    UPLOADED: 'Documento subido correctamente',
    UPDATED: 'Documento actualizado correctamente',
    DOWNLOADED: 'Documento descargado correctamente',
    DELETED: 'Documento eliminado correctamente',
  },
  CONNECTION_REQUEST: {
    CREATED: 'Propuesta registrada correctamente',
    UPDATED: 'Propuesta actualizada correctamente',
    CANCELED: 'Propuesta cancelada correctamente',
    FINISHED: 'Propuesta finalizada correctamente',
    GUEST: {
      ADDED: 'Contacto(s) agregado(s) correctamente',
      DELETED: 'Contacto(s) eliminado(s) correctamente'
    },
    SCHEDULE: {
      DELETED: 'Horario eliminado correctamente',
      ACCEPTED: 'Horario aceptado correctamente'
    },
    FEEDBACK: {
      CREATED: 'Reseña enviada correctamente'
    },
    STATUS: {
      IN_PLANING: 'En planeación',
      CANCELLED: 'Cancelada',
      FINISHED: 'Finalizada',
      ACCEPTED: 'Aceptada',
    },
  },
  TRAINING: {
    NO_AVAILABLE_DATE: 'No hay fechas disponibles',
    SELECT_DAY: 'Selección un día para continuar',
    SELECT_SCHEDULE: 'Selecciona un horario para continuar',
    SUCCESS: 'Capacitación agendada correctamente',
  }
}
