import {Pipe, PipeTransform} from "@angular/core"
import {OrganizationService} from '@organization/services/organization.service';
import {IOrganizationProfile, IOrganizationWithRelations} from '@organization/interfaces';

@Pipe({
  name: 'convertToOrganizationProfile',
  standalone: true,
})

export class ConvertToOrganizationProfilePipe implements PipeTransform {

  constructor(public organizationService: OrganizationService) {
  }
  transform(organization: IOrganizationWithRelations): IOrganizationProfile {
    return this.organizationService.convertToOrganizationProfileDto(organization);
  }
}
