import {Pipe, PipeTransform} from '@angular/core';
import {IConnectionRequestWithRelations} from '@connection-request/interfaces';
import {IProposedConnectionDate} from '@connection-request/interfaces/proposed-connection-date.interface';
import {DateClass} from '@shared/class/utils/date.class';
import {ProposedConnectionDateStatusEnum} from '@connection-request/enums';
import {cloneDeep} from 'lodash';

@Pipe({
  name: 'sortConnectionRequest',
  standalone: true,
})
export class SortConnectionRequestsPipe implements PipeTransform {
  transform(connections: IConnectionRequestWithRelations[], date?: Date): IConnectionRequestWithRelations[] {
    return cloneDeep(connections).sort((a: IConnectionRequestWithRelations, b: IConnectionRequestWithRelations): number => {
      // Fechas aceptadas
      const dateAcceptedA: IProposedConnectionDate | undefined = (a.ProposedConnectionDate ?? []).find(({status}: IProposedConnectionDate): boolean => status === ProposedConnectionDateStatusEnum.ACCEPTED);
      const dateAcceptedB: IProposedConnectionDate | undefined = (b.ProposedConnectionDate ?? []).find(({status}: IProposedConnectionDate): boolean => status === ProposedConnectionDateStatusEnum.ACCEPTED);
      if (dateAcceptedA && dateAcceptedB) return new Date(dateAcceptedA.startDate) <  new Date(dateAcceptedB.startDate) ? -1 : 1;

      // Fechas próximas o del día
      const dateA: IProposedConnectionDate | undefined = (a.ProposedConnectionDate ?? []).find(({startDate}: IProposedConnectionDate): boolean => date ? (DateClass.isSameDay(new Date(startDate), date)) : (new Date(startDate) > new Date()));
      const dateB: IProposedConnectionDate | undefined = (b.ProposedConnectionDate ?? []).find(({startDate}: IProposedConnectionDate): boolean => date ? (DateClass.isSameDay(new Date(startDate), date)) : (new Date(startDate) > new Date()));

      // First date
      const defaultA: IProposedConnectionDate | undefined = (a.ProposedConnectionDate ?? [])[0];
      const defaultB: IProposedConnectionDate | undefined = (b.ProposedConnectionDate ?? [])[0];

      // Compara fechas aceptadas con fechas próximas y default
      if (dateAcceptedA && (!dateAcceptedB && dateB)) return new Date(dateAcceptedA.startDate) <  new Date(dateB.startDate) ? -1 : 1;
      if (dateAcceptedA && (!dateAcceptedB && !dateB && defaultB)) return new Date(dateAcceptedA.startDate) <  new Date(defaultB.startDate) ? -1 : 1;
      if (dateAcceptedB && (!dateAcceptedA && dateA)) return new Date(dateA.startDate) <  new Date(dateAcceptedB.startDate) ? -1 : 1;
      if (dateAcceptedB && (!dateAcceptedA && !dateA && defaultA)) return new Date(defaultA.startDate) <  new Date(dateAcceptedB.startDate) ? -1 : 1;

      // Compara con fechas próximas con default
      if (dateA && dateB) return new Date(dateA.startDate) <  new Date(dateB.startDate) ? -1 : 1;
      if (dateA && (!dateB && defaultB)) return new Date(dateA.startDate) <  new Date(defaultB.startDate) ? -1 : 1;
      if (dateB && (!dateA && defaultA)) return new Date(defaultA.startDate) <  new Date(dateB.startDate) ? -1 : 1;

      if (defaultA && defaultB) return new Date(defaultA.startDate) <  new Date(defaultB.startDate) ? -1 : 1;
      return -1;
    });
  }
}
