import {Action, Selector, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {AccountCheckModalEnum} from '@shared/components/ui-elements/account-check/enums/account-check-modal.enum';

export interface IAccountCheckModel {
  modal: AccountCheckModalEnum | null,
  showRedirect: boolean;
}

export namespace AccountCheckActions {
  const state: string = 'Account State';

  export class SetAccountCheck {
    static readonly type: string = `[${state}] Set Account check`;

    constructor(public modal: AccountCheckModalEnum | null, public showRedirect: boolean = true) { }
  }
}

@State<IAccountCheckModel>({
  name: 'AccountCheckState',
  defaults: {
    modal: null,
    showRedirect: true,
  }
})
@Injectable()
export class AccountCheckState {
  constructor() {}

  @Selector()
  static getModal({modal}: IAccountCheckModel): AccountCheckModalEnum | null {
    return modal;
  }

  @Selector()
  static showRedirect({showRedirect}: IAccountCheckModel): boolean {
    return showRedirect;
  }

  @Action(AccountCheckActions.SetAccountCheck)
  async setAccountCheck({patchState}: StateContext<IAccountCheckModel>, {modal, showRedirect}: AccountCheckActions.SetAccountCheck): Promise<void> {
    patchState({modal, showRedirect});
  }
}
