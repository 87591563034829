import {Pipe, PipeTransform} from '@angular/core';
import {IMediaWithRelations} from '@shared/interfaces';
import {ImageDescriptionEnum} from '@shared/enums';
import {IEventMedia, IEventMediaWithRelations} from '@event/interfaces';

@Pipe({
  name: 'firstUrlFromMedia',
  standalone: true,
})
export class FirstUrlFromMediaPipe implements PipeTransform {
  transform(medias?: IMediaWithRelations[], description?: ImageDescriptionEnum): string | undefined {
    if (!medias || medias.length === 0) return;

    return !description ? medias[0].filePath : medias.find((media: IMediaWithRelations) => media.description === description)?.filePath;
  }
}

@Pipe({
  name: 'getEventMediaByDescription',
  standalone: true,
})
export class GetEventMediaByDescription implements PipeTransform {
  transform(eventMedia: IEventMediaWithRelations[] = [], description?: ImageDescriptionEnum): IEventMediaWithRelations | undefined {
    return eventMedia.find(({ Media }: IEventMediaWithRelations): boolean => Media?.description === description);
  }
}
