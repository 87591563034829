import {animate, AnimationTriggerMetadata, state, style, transition, trigger} from '@angular/animations';

export const IconRotate: AnimationTriggerMetadata = trigger('icon', [
  state('loading', style({
    transform: 'rotate({{rotateEnd}}deg)',
  }), {params: {rotateEnd: 0}}),
  transition('* => loading', [
    style({
      transform: 'rotate({{rotate}}deg)',
    }),
    animate('8s')
  ], {params: {rotate: 0}}),
]);
