import {Injectable} from '@angular/core';
import {Action, Selector, State, StateContext} from '@ngxs/store';

import {environment} from '@env/environment';
import {IPhoneNumber} from '@shared/interfaces';
import {UtilityClass} from '@shared/class/utils';

export interface IPhoneState {
  list: IPhoneNumber[];
}

export namespace PhoneActions {
  export class Set {
    static readonly type: string = '[Phone] Set';

    constructor(public phones: IPhoneNumber[]) {
    }
  }

  export class DeleteById {
    static readonly type: string = '[Phone] Delete by id';

    constructor(public id: string) {
    }
  }

  export class PostPatch {
    static readonly type: string = '[Phone] Post patch';

    constructor(public phones: IPhoneNumber[]) {
    }
  }


  export class Reset {
    static readonly type: string = '[Phone] Reset';
  }
}


const _DEFAULT_DATA: IPhoneState = {
  list: [],
};

@State<IPhoneState>({
  name: 'PhoneState',
  defaults: {..._DEFAULT_DATA},
})
@Injectable()
export class PhoneState {
  private readonly SERVER: string = environment.SERVER;

  constructor() {
  }

  @Selector()
  static getPhoneList({list}: IPhoneState): IPhoneNumber[] {
    return list;
  }


  @Action(PhoneActions.Set)
  async login({patchState}: StateContext<IPhoneState>, {phones}: PhoneActions.Set): Promise<void> {
    patchState({list: [...phones]});
  }

  @Action(PhoneActions.PostPatch)
  async postPatch({patchState, getState}: StateContext<IPhoneState>, {phones}: PhoneActions.PostPatch): Promise<void> {
    let list: IPhoneNumber[] = getState().list;

    phones.forEach((phone: IPhoneNumber): void => {
      list = UtilityClass.updateOrPushItems<IPhoneNumber>(list, phone, 'id');
    });

    patchState({list});
  }

  @Action(PhoneActions.DeleteById)
  async deleteById({patchState, getState}: StateContext<IPhoneState>, {id}: PhoneActions.DeleteById): Promise<void> {
    patchState({list: UtilityClass.deleteItemByProp<IPhoneNumber>(getState().list, 'id', id)});
  }

  @Action(PhoneActions.Reset)
  reset({setState}: StateContext<IPhoneState>): void {
    setState(_DEFAULT_DATA);
  }
}
