import {Injectable} from '@angular/core';
import {StorageService} from '@shared/services/storage/storage.service';
import {PLATFORM_MESSAGES} from '@shared/config';
import {Router} from '@angular/router';
import {NzMessageService} from 'ng-zorro-antd/message';
import {IStorageCredentials, IStorageUser} from '@shared/services/storage/interfaces';
import {environment} from '@env/environment';

@Injectable()
export class AuthService {
  constructor(
    private nzMessageService: NzMessageService,
    private router: Router,
    private storageService: StorageService,
  ) {}

  hasCredentials(): boolean {
    const credentials: IStorageCredentials | null = this.storageService.getCredentials();
    return !!credentials;
  }

  async hasUser(): Promise<boolean> {
    const user: IStorageUser | null = this.storageService.getUser();
    if (user?.id) return Promise.resolve(true);
    await this.router.navigate([environment.DEFAULT_PAGE_TO_LOAD]);
    this.storageService.clear();
    this.nzMessageService.error(PLATFORM_MESSAGES.SESSION.TRY_AGAIN);
    return Promise.resolve(false);
  }
}
