import {ErrorTypeEnum} from '@shared/class/errors/enums/error-type.enum';
import {ErrorException} from '@shared/class/errors/error.exception';

export class DeveloperException extends ErrorException {
  constructor(message: string = 'Funcionalidad en desarrollo') {
    super(message);
    this.name = ErrorTypeEnum.INTERNAL_CLIENT;
    this.internalCode = 'DEV-001';
  }
}
