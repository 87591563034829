import {Injectable} from '@angular/core';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {IOrganization, IOrganizationContactPhoneNumberWithRelations} from '@organization/interfaces';
import {BaseService} from '@shared/services';
import {ErrorHandlerService} from '@shared/services/error-handler.service';
import {DataBaseServiceResponse} from '@shared/services/base/interfaces/data-base-service-response.interface';
import {firstValueFrom} from 'rxjs';
import {environment} from '@env/environment';
import {PrismaCountFilter, PrismaFilter} from '@shared/services/base/interfaces/prisma-filter.interface';
import {
  IContact,
  IContactWithRelations,
  ICreateOrUpdatePhoneNumber,
  ICreateOrUpdatePhoneNumberList,
  IPhoneNumber,
} from '@shared/interfaces';
import {PLATFORM_CONFIG, PLATFORM_MESSAGES} from '@shared/config';
import {NotFoundException} from '@shared/class/errors';
import {
  IOrganizationContact,
  IOrganizationContactWithRelations
} from '@organization/interfaces/organization-contact.interface';
import {UtilityClass} from '@shared/class/utils';
import {PhoneActions} from '../../phone/state/phone.state';
import {omit as _omit} from 'lodash';
import {PhoneBuilder} from '@shared/components/ui-elements/information/phones/phone-builder.util';
import {IUserProfilePhoneNumber} from '@user/interfaces';
import {IUserState, UserActions} from '@user/state';

export interface IOrganizationContactState {
  list: IContactWithRelations[];
  legalRepresentative: IContactWithRelations | null;
  contactSelectedId: string;
  contactCount: number;
  thereAreMoreContacts: boolean;
}

const _DEFAULT_DATA: IOrganizationContactState = {
  list: [],
  contactSelectedId: '',
  legalRepresentative: null,
  contactCount: 0,
  thereAreMoreContacts: true,
}

export namespace OrganizationContactActions {
  export class GetList {
    static readonly type: string = '[OrganizationContact] Get Organization Contact List Action';
    constructor(public organizationId: string, public filter?: PrismaFilter<IContactWithRelations>, public isAddedTo?: boolean) {}
  }

  export class GetLegalRepresentative {
    static readonly type: string = '[OrganizationContact] Get Organization Contact Legal Representative Action';
    constructor(public organizationId: string) {}
  }

  export class SetLegalRepresentative {
    static readonly type: string = '[OrganizationContact] Set Organization Contact Legal Representative Action';
    constructor(public organizationId: string, public id: string, public contact: Partial<IContactWithRelations>) {}
  }


  export class GetCount {
    static readonly type: string = '[OrganizationContact] Get Organization Contact Count';
    constructor(public organizationId: string, public filters?: PrismaCountFilter<IContactWithRelations>) {
    }
  }

  export class GetById {
    static readonly type: string = '[OrganizationContact] Get Organization Contact By Id Action';
    constructor(public organizationId: string, public id: string, public filter?: PrismaFilter<IContactWithRelations>) {}
  }

  export class Create {
    static readonly type: string = '[OrganizationContact] Create Organization Contact Action';
    constructor(public organizationId: string, public contact: IContact) {}
  }

  export class Update {
    static readonly type: string = '[OrganizationContact] Update Organization Contact Action';
    constructor(public organizationId: string, public id: string, public contact: Partial<IContactWithRelations>) {}
  }

  export class Delete {
    static readonly type: string = '[OrganizationContact] Delete Organization Contact Action';
    constructor(public organizationId: string, public id: string) {}
  }

  export class SetOrganizationContactId {
    static readonly type: string = '[OrganizationContact] Set Organization Contact Id Action';
    constructor(public id: string) {}
  }

  export class PostPhone {
    static readonly type: string = '[User] Post Organization Collaborator Phone';

    constructor(public organizationId: string, public contactId: string, public phone: IPhoneNumber, public phoneBuilder: PhoneBuilder) {
    }
  }

  export class PatchPhone {
    static readonly type: string = '[User] Patch Organization Collaborator Phone';

    constructor(public organizationId: string, public contactId: string, public phone: IPhoneNumber, public phoneBuilder: PhoneBuilder) {
    }
  }

  export class DeletePhone {
    static readonly type: string = '[OrganizationContact] Delete Organization Contact Phone Action';
    constructor(public organizationId: string, public contactId: string, public phoneId: string, public phoneBuilder: PhoneBuilder) {}
  }

  export class Reset {
    static readonly type: string = '[OrganizationContact] Reset';
  }

}

@State<IOrganizationContactState>({
  name: 'OrganizationContactState',
  defaults: _DEFAULT_DATA
})
@Injectable()
export class OrganizationContactState {
  private readonly SERVER: string = environment.SERVER;
  constructor(
    private baseService: BaseService,
    private errorHandlerService: ErrorHandlerService,
  ) {
  }

  @Selector()
  static getContactList(state: IOrganizationContactState): IContactWithRelations[] {
    return state.list;
  }

  @Selector()
  static getContactSelectedId(state: IOrganizationContactState): string {
    return state.contactSelectedId;
  }

  @Selector()
  static getContactLegalRepresentative(state: IOrganizationContactState): IContactWithRelations | null {
    return state.legalRepresentative;
  }

  @Selector()
  static getContactSelected({list, contactSelectedId}: IOrganizationContactState): IContactWithRelations | undefined {
    return list.find((contact: IContactWithRelations): boolean => contact.id === contactSelectedId);
  }

  @Selector()
  static getCount({contactCount}: IOrganizationContactState): number {
    return contactCount;
  }

  @Selector()
  static getThereAreMoreContacts({thereAreMoreContacts}: IOrganizationContactState): boolean {
    return thereAreMoreContacts;
  }

  @Action(OrganizationContactActions.GetList)
  async getList({patchState, getState}: StateContext<IOrganizationContactState>, {organizationId, filter, isAddedTo}: OrganizationContactActions.GetList): Promise<void> {
    const response: DataBaseServiceResponse<IContactWithRelations[]> = await firstValueFrom(this.baseService.get<IContactWithRelations[]>(`${this.SERVER}/organizations/${organizationId}/contacts`, filter ?? {}));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);
    const contacts: IContact[] = isAddedTo ? [...getState().list].concat(response.entity ?? []) : response.entity ?? [];
    patchState({
      list: contacts,
      thereAreMoreContacts: !(response.entity && response.entity.length < PLATFORM_CONFIG.PAGINATOR.ITEMS_PER_SCROLL),
    })
  }

  @Action(OrganizationContactActions.GetLegalRepresentative)
  async getLegalRepresentative({patchState}: StateContext<IOrganizationContactState>, {organizationId}: OrganizationContactActions.GetLegalRepresentative): Promise<void> {
    const response: DataBaseServiceResponse<IContactWithRelations[]> = await firstValueFrom(this.baseService.get<IContactWithRelations[]>(
      `${this.SERVER}/organizations/${organizationId}/contacts`, {
        where: {
          legalRepresentative: true,
        }
      }));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);
    const [legalRepresentative] = (response.entity ?? []);
    patchState({
      legalRepresentative: legalRepresentative ?? null,
    })
  }

  @Action(OrganizationContactActions.GetCount)
  async GetCount(
    {patchState}: StateContext<IOrganizationContactState>,
    {organizationId ,filters}: OrganizationContactActions.GetCount
  ): Promise<void> {
    const response: DataBaseServiceResponse<number> = await firstValueFrom(this.baseService.count<IOrganizationContact>(`${this.SERVER}/organizations/${organizationId}/contacts/count`, filters));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({contactCount: response.entity ?? 0});
  }

  @Action(OrganizationContactActions.GetById)
  async getById({patchState, getState}: StateContext<IOrganizationContactState>, {organizationId, id, filter}: OrganizationContactActions.GetById): Promise<void> {
    const response: DataBaseServiceResponse<IContactWithRelations> = await firstValueFrom(this.baseService.get(`${this.SERVER}/organizations/${organizationId}/contacts/${id}`, filter));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({
      list: UtilityClass.updateOrPushItems<IContactWithRelations>(getState().list, response.entity!, 'id'),
      contactSelectedId: id
    });
  }

  @Action(OrganizationContactActions.Create)
  async create({patchState, getState}: StateContext<IOrganizationContactState>, {organizationId, contact}: OrganizationContactActions.Create): Promise<void> {
    const response: DataBaseServiceResponse<IOrganizationContact> = await firstValueFrom(this.baseService.post(`${this.SERVER}/organizations/${organizationId}/contacts`, contact));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);
    const contactCreated: IContactWithRelations = {
      id: response.entity?.contactId,
      ...contact
    }
    patchState({
      list: UtilityClass.updateOrPushItems<IContactWithRelations>(getState().list, contactCreated, 'id'),
      contactSelectedId: response.entity?.contactId
    });
  }

  @Action(OrganizationContactActions.Update)
  async update({patchState, getState}: StateContext<IOrganizationContactState>, {organizationId, id, contact}: OrganizationContactActions.Update): Promise<void> {
    const response: DataBaseServiceResponse<IOrganizationContact> = await firstValueFrom(this.baseService.patch(`${this.SERVER}/organizations/${organizationId}/contacts/${id}`, contact));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);
    let contactSelected: IContactWithRelations | undefined = getState().list.find((contact: IContactWithRelations) => contact.id === id);
    if (!contactSelected) throw new NotFoundException(PLATFORM_MESSAGES.ORGANIZATION.CONTACT.NOT_FOUND);
    contactSelected = {
      ...contactSelected,
      ...response.entity
    };
    patchState({
      list: UtilityClass.updateOrPushItems<IContactWithRelations>(getState().list, contactSelected, 'id'),
      contactSelectedId: id
    });
  }

  @Action(OrganizationContactActions.SetLegalRepresentative)
  async setLegalRepresentative({patchState, getState}: StateContext<IOrganizationContactState>, {organizationId, id, contact}: OrganizationContactActions.SetLegalRepresentative): Promise<void> {
    const response: DataBaseServiceResponse<IOrganizationContact> = await firstValueFrom(this.baseService.patch(`${this.SERVER}/organizations/${organizationId}/contacts/${id}`, contact));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({
      legalRepresentative: {
        ...(getState().legalRepresentative ?? {}),
        ...response.entity!,
      } as IContactWithRelations
    });
  }

  @Action(OrganizationContactActions.Delete)
  async delete(ctx: StateContext<IOrganizationContactState>, {organizationId, id}: OrganizationContactActions.Delete): Promise<void> {
    const response: DataBaseServiceResponse<IOrganization> = await firstValueFrom(this.baseService.delete(`${this.SERVER}/organizations/${organizationId}/contacts/${id}`));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);
  }

  @Action(OrganizationContactActions.SetOrganizationContactId)
  setOrganizationContactId({patchState}: StateContext<IOrganizationContactState>, {id}: OrganizationContactActions.SetOrganizationContactId): void {
    patchState({
      contactSelectedId: id
    });
  }

/*  @Action(OrganizationContactActions.UpdatePhones)
  async updatePhones({getState, dispatch}: StateContext<IOrganizationContactState>, {organizationId, contactId, phones}: OrganizationContactActions.UpdatePhones): Promise<void> {
    const phonesCreatePromise: Promise<IOrganizationContactPhoneNumberWithRelations | null>[] = phones.create.map((phone: ICreateOrUpdatePhoneNumber) => {
      return new Promise(async (resolve: (value: IOrganizationContactPhoneNumberWithRelations | null) => void): Promise<void> => {
        const contactPhone: DataBaseServiceResponse<IOrganizationContactPhoneNumberWithRelations> = await firstValueFrom(this.baseService.post(`${this.SERVER}/organizations/${organizationId}/contacts/${contactId}/phones`, _omit(phone, ['id'])));
        if (contactPhone.error) {
          return resolve(null);
        }

        resolve({
          ...contactPhone.entity!,
          PhoneNumber: {
            number: phone.number,
            countryCode: phone.countryCode,
            id: contactPhone.entity!.phoneNumberId!,
          }
        });
      });
    });

    const phonesUpdatePromise: Promise<IOrganizationContactPhoneNumberWithRelations | null>[] = phones.update.map((phone: ICreateOrUpdatePhoneNumber) => {
      return new Promise(async (resolve: (value: IOrganizationContactPhoneNumberWithRelations | null) => void): Promise<void> => {
        const contactPhone: DataBaseServiceResponse<IPhoneNumber> = await firstValueFrom(this.baseService.patch(`${this.SERVER}/organizations/${organizationId}/contacts/${contactId}/phones/${phone.id}`, phone));
        if (contactPhone.error) {
          return resolve(null);
        }

        resolve({
          phoneNumberId: contactPhone.entity!.id!,
          contactId: contactPhone.entity!.id!, // replace if necessary
          PhoneNumber: contactPhone.entity!,
        });
      });
    });

    const promises: Promise<IOrganizationContactPhoneNumberWithRelations | null>[] = [...phonesCreatePromise, ...phonesUpdatePromise];
    if (promises.length === 0) return;
    const responses: (IOrganizationContactPhoneNumberWithRelations | null)[] = await Promise.all(promises);
    const contactSelected: IContactWithRelations | undefined = getState().list.find((contact: IContact): boolean => contact.id === contactId);
    if (!contactSelected) return;
    const successResponses: IOrganizationContactPhoneNumberWithRelations[] = responses.filter((item: IOrganizationContactPhoneNumberWithRelations | null): boolean => item !== null) as IOrganizationContactPhoneNumberWithRelations[];
    await firstValueFrom(dispatch(new PhoneActions.PostPatch(successResponses.map(({PhoneNumber}: IOrganizationContactPhoneNumberWithRelations) => PhoneNumber!))));
  }*/

  @Action(OrganizationContactActions.PostPhone)
  async postPhone(
    _: StateContext<IUserState>,
    {organizationId, contactId, phone, phoneBuilder}: OrganizationContactActions.PostPhone
  ): Promise<void> {
    const response: DataBaseServiceResponse<IUserProfilePhoneNumber> = await firstValueFrom(this.baseService.post<IUserProfilePhoneNumber>(`${this.SERVER}/organizations/${organizationId}/contacts/${contactId}/phones`, _omit(phone, ['id'])));
    if (response.error) return;
    phoneBuilder.replaceIdBy(phone.id!, response.entity?.phoneNumberId ?? '');
  }

  @Action(OrganizationContactActions.PatchPhone)
  async patchPhone(
    _: StateContext<IUserState>,
    {organizationId, contactId, phone, phoneBuilder}: OrganizationContactActions.PatchPhone
  ): Promise<void> {
    const response: DataBaseServiceResponse<IPhoneNumber> = await firstValueFrom(this.baseService.patch<IPhoneNumber>(`${this.SERVER}/organizations/${organizationId}/contacts/${contactId}/phones/${phone.id}`, phone));
    if (!response.error) return;
  }

  @Action(OrganizationContactActions.DeletePhone)
  async deletePhone(_: StateContext<IOrganizationContactState>, {organizationId, contactId, phoneId, phoneBuilder}: OrganizationContactActions.DeletePhone): Promise<void> {
    const response: DataBaseServiceResponse<IOrganization> = await firstValueFrom(this.baseService.delete(`${this.SERVER}/organizations/${organizationId}/contacts/${contactId}/phones/${phoneId}`));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);
    phoneBuilder.deleteById(phoneId);
  }


  @Action(OrganizationContactActions.Reset)
  reset({setState}: StateContext<IOrganizationContactState>): void {
    setState(_DEFAULT_DATA);
  }

}
