import {IAnyObject} from '@shared/interfaces';
import {TimeDedicatedEnum} from '@shared/enums';

export const TIME_DEDICATED_VALUES: IAnyObject<string> = {
  [TimeDedicatedEnum.HOUR]: 'Horas',
  [TimeDedicatedEnum.DAY]: 'Días',
  [TimeDedicatedEnum.WEEK]: 'Semanas',
  [TimeDedicatedEnum.MONTH]: 'Meses',
  [TimeDedicatedEnum.YEAR]: 'Años',
}
