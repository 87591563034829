import {Pipe, PipeTransform} from "@angular/core"
import {format} from "date-fns"
import {IEventDate, IEventDateGroup} from '@event/interfaces';
import {es} from 'date-fns/locale';

@Pipe({
  name: 'transformEventDateText',
  standalone: true,
})

export class TransformEventDateTextPipe implements PipeTransform {
  transform(value: IEventDateGroup | IEventDate): string {
    const startDate: Date = new Date(value.startDate);
    const endDate: Date = new Date(value.endDate);

    const yearStart: number = startDate.getFullYear();
    const monthStart: number = startDate.getMonth();
    const dayStart: number = startDate.getDate();

    const yearEnd: number = endDate.getFullYear();
    const monthEnd: number = endDate.getMonth();
    const dayEnd: number = endDate.getDate();

    if (yearStart === yearEnd && monthStart === monthEnd) {
      return `${dayStart === dayEnd ? dayStart : (dayStart + ' al ' + dayEnd)} de ${this.getMonthName(monthStart)} del ${yearStart}`;
    } else if (yearStart === yearEnd && monthStart !== monthEnd) {
      return `${dayStart} de ${this.getMonthName(monthStart)} al ${dayEnd} del ${this.getMonthName(monthEnd)} del ${yearStart}`;
    } else if (yearStart !== yearEnd) {
      return `${dayStart} de ${this.getMonthName(monthStart)} del ${yearStart} al ${dayEnd} del ${this.getMonthName(monthEnd)} del ${yearEnd}`;
    }
    return '';
  }

  getMonthName(n: number): string {
    return format(new Date().setMonth(n), 'MMMM', { locale: es });
  }
}
