import {cloneDeep as _cloneDeep, isPlainObject as _isPlainObject} from 'lodash';
import {IAnyObject} from '@shared/interfaces';


export class UtilityClass {
  constructor() {}

  /**
   *  Random GUID (Globally Unique Identifier)
   * @param prefix
   */
  static guid(prefix: string = ''): string {
    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c: string) {
      const r: number = (Math.random() * 16) | 0;
      const v: number = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
    return `${prefix}${uuid}`;
  }

  /**
   * Scroll an element by id
   * @param id
   */
  static scrollIntoViewById(id: string): void {
    const element: HTMLElement | null = document.getElementById(id);
    if (!element) return;

    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start', // Defines vertical alignment. One of start, center, end, or nearest. Defaults to start.
      inline: 'nearest' // Defines horizontal alignment. One of start, center, end, or nearest. Defaults to nearest.
    });
  }

  /**
   *
   * @param items
   * @param item
   * @param property
   */
  static updateOrPushItems<T>(items: T[], item: T | Partial<T>, property: keyof T): T[] {
    const cloneItems: T[] = _cloneDeep(items);
    const index: number = cloneItems.findIndex((_item: T): boolean => _item[property] === item[property]);
    if (index === -1) {
      cloneItems.push(item as T);
    } else {
      cloneItems[index] = {
        ...cloneItems[index],
        ...item,
      };
    }
    return cloneItems;
  }

  /**
   *
   * @param items
   * @param property
   * @param valueToCompare
   */
  static deleteItemByProp<T>(items: T[], property: keyof T, valueToCompare: any): T[] {
    const cloneItems: T[] = _cloneDeep(items);
    const index: number = cloneItems.findIndex((_item: T): boolean => _item[property] === valueToCompare);
    if (index !== -1) {
      cloneItems.splice(index, 1);
    }
    return cloneItems;
  }

  /**
   * Delete properties with undefined or null value
   * @param obj
   */
  static deleteNullOrUndefinedProsFromObject(obj: IAnyObject): IAnyObject {
    if (!_isPlainObject(obj)) return obj;
    const cloneObj: IAnyObject = _cloneDeep(obj);

    try {
      for (const prop in cloneObj) {
        if (cloneObj.hasOwnProperty(prop) && (cloneObj[prop] === null || cloneObj[prop] === undefined || cloneObj[prop] === '')) {
          delete cloneObj[prop];
        }
      }
      return cloneObj;
    } catch (ex) {
      return cloneObj;
    }
  }

  /**
   * Add the vowels and the vowels with accents
   * @param replacedText
   * @example México -> M[eé]x[ií]c[oó]
   */
  static regexRemplaceTextWithAccents(replacedText: string = ''): string {
    const characters: IAnyObject<string> = {
      a: 'aá',
      e: 'eé',
      i: 'ií',
      o: 'oó',
      u: 'uú'
    }
    for (const [key, value] of Object.entries(characters)) {
      replacedText = replacedText.replaceAll(key, `[${value}]`);
    }
    return replacedText;
  }

  public static addHttps(url: string): string {
    // Verificar si la URL ya comienza con "http://" o "https://"
    if (!/^https?:\/\//i.test(url)) {
      // Si no, agregar "https://"
      return 'https://' + url;
    }
    // Si ya tiene "http://" o "https://", devolver la URL sin cambios
    return url;
  }
}
