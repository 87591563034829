import {Injectable} from '@angular/core';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {IOrganization, IOrganizationCreateUpdateDto, IOrganizationWithRelations} from '@organization/interfaces';
import {BaseService} from '@shared/services';
import {ErrorHandlerService} from '@shared/services/error-handler.service';
import {DataBaseServiceResponse} from '@shared/services/base/interfaces/data-base-service-response.interface';
import {firstValueFrom} from 'rxjs';
import {environment} from '@env/environment';
import {PLATFORM_MESSAGES} from '@shared/config';
import {UtilityClass} from '@shared/class/utils';
import {BadRequestException} from '@shared/class/errors/bad-request.exception';

export interface IOrganizationGovernmentAgencyState {
  list: IOrganization[];
  legalRepresentative: IOrganization | null;
  organizationSelectedId: string;

  isAvailable: boolean
}

const _DEFAULT_DATA: IOrganizationGovernmentAgencyState = {
  list: [],
  organizationSelectedId: '',
  legalRepresentative: null,
  isAvailable: false
}

export namespace OrganizationGovernmentAgencyActions {
  export class Create {
    static readonly type: string = '[OrganizationGovernment] Create Organization Government Agency Action';
    constructor(public organization: IOrganizationCreateUpdateDto) {}
  }

  export class Update {
    static readonly type: string = '[OrganizationGovernment] Update Organization Government Agency Action';
    constructor(public organizationId: string, public organization: Partial<IOrganizationCreateUpdateDto>) {}
  }

  export class Available {
    static readonly type: string = '[OrganizationGovernment] Update Organization Government Agency Available Action';
    constructor(public id: string) {}
  }

  export class Reset {
    static readonly type: string = '[OrganizationGovernment] Reset';
  }
}

@State<IOrganizationGovernmentAgencyState>({
  name: 'OrganizationGovernmentState',
  defaults: _DEFAULT_DATA
})
@Injectable()
export class OrganizationGovernmentState {
  private readonly SERVER: string = environment.SERVER;
  constructor(
    private baseService: BaseService,
    private errorHandlerService: ErrorHandlerService,
  ) {
  }

  @Selector()
  static getContactSelected({list, organizationSelectedId}: IOrganizationGovernmentAgencyState): IOrganization | undefined {
    return list.find((organization: IOrganization): boolean => organization.id === organizationSelectedId);
  }

  @Selector()
  static getIsAvailable({isAvailable}: IOrganizationGovernmentAgencyState): boolean {
    return isAvailable;
  }

  @Action(OrganizationGovernmentAgencyActions.Create)
  async create({patchState, getState}: StateContext<IOrganizationGovernmentAgencyState>, {organization}: OrganizationGovernmentAgencyActions.Create): Promise<void> {
    const response: DataBaseServiceResponse<IOrganization> = await firstValueFrom(this.baseService.post(`${this.SERVER}/organizations/government-agencies`, organization));

    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({
      list: UtilityClass.updateOrPushItems<IOrganization>(getState().list, response.entity!, 'id'),
      organizationSelectedId: response.entity?.id
    });
  }

  @Action(OrganizationGovernmentAgencyActions.Available)
  async available({patchState}: StateContext<IOrganizationGovernmentAgencyState>, {id}: OrganizationGovernmentAgencyActions.Available): Promise<void> {
    const response: DataBaseServiceResponse<{ available: boolean }> = await firstValueFrom(this.baseService.get(`${this.SERVER}/government-agencies/${id}/available`));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);
    patchState({
      isAvailable: response.entity?.available,
    });
  }

  @Action(OrganizationGovernmentAgencyActions.Update)
  async update({patchState, getState}: StateContext<IOrganizationGovernmentAgencyState>, {organizationId, organization}: OrganizationGovernmentAgencyActions.Update): Promise<void> {
  }

  @Action(OrganizationGovernmentAgencyActions.Reset)
  reset({setState}: StateContext<IOrganizationGovernmentAgencyState>): void {
    setState(_DEFAULT_DATA);
  }

}
