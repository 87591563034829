import {IOrganizationMemberWithRelations} from '@organization/interfaces';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {BaseService} from '@shared/services';
import {PrismaFilter} from '@shared/services/base/interfaces/prisma-filter.interface';
import {DataBaseServiceResponse} from '@shared/services/base/interfaces/data-base-service-response.interface';
import {firstValueFrom} from 'rxjs';
import {environment} from '@env/environment';
import {ErrorHandlerService} from '@shared/services/error-handler.service';
import {IUser, IUserCreateUpdateDto, IUserWithRelations} from '@user/interfaces';
import {UtilityClass} from '@shared/class/utils/utility.class';
import {OrganizationMemberTypeEnum} from '@organization/enums';

export interface IOrganizationMemberState {
  list: IOrganizationMemberWithRelations[];
  selectedId: string | null;
  count: number;
}

const _DEFAULT_DATA: IOrganizationMemberState = {
  list: [],
  selectedId: null,
  count: 0,
}

export namespace OrganizationMemberActions {
  export class GetList {
    static readonly type: string = '[Organization Member] Get Organization Member List';
    constructor(public organizationId: string, public search?: string, public filter?: PrismaFilter<IOrganizationMemberWithRelations>) {}
  }

  export class CountList {
    static readonly type: string = '[Organization Member] Count Organization Member List';
    constructor(public organizationId: string, public search?: string) {}
  }

  export class Create {
    static readonly type: string = '[Organization Member] Create Organization Member';
    constructor(public organizationId: string, public user: IUserCreateUpdateDto) {}
  }

  export class GetById {
    static readonly type: string = '[Organization Member] Get Organization Member By Id';
    constructor(public organizationId: string, public userId: string) {}
  }

  export class DeleteById {
    static readonly type: string = '[Organization Member] Delete Organization Member By Id';
    constructor(public organizationId: string, public userId: string) {}
  }

  export class SetOrganizationMemberSelected {
    static readonly type: string = '[Organization Member] Set Organization Member By Id';
    constructor(public userId: string | null) {}
  }

  export class Reset {
    static readonly type: string = '[Organization Member] Reset Organization Member';
    constructor() {}
  }

}

@State<IOrganizationMemberState>({
  name: 'OrganizationMemberState',
  defaults: _DEFAULT_DATA
})
@Injectable()
export class OrganizationMemberState {
  private readonly SERVER: string = environment.SERVER;

  constructor(
    private baseService: BaseService,
    private errorHandlerService: ErrorHandlerService,
  ) { }

  @Selector()
  static getOrganizationMembers({list}: IOrganizationMemberState): IOrganizationMemberWithRelations[] {
    return list;
  }

  @Selector()
  static countOrganizationMembers({count}: IOrganizationMemberState): number {
    return count;
  }

  @Selector()
  static getUserOrganizationMembers({list}: IOrganizationMemberState): IUserWithRelations[] {
    return list.map(({User}: IOrganizationMemberWithRelations) => User!);
  }

  @Selector()
  static getOrganizationMemberSelected(
    {list, selectedId}: IOrganizationMemberState
  ): IOrganizationMemberWithRelations | null {
    return list.find(({userId}: IOrganizationMemberWithRelations): boolean => userId === selectedId) ?? null;
  }

  @Action(OrganizationMemberActions.GetList)
  async getOrganizationMembersList(
    {patchState}: StateContext<IOrganizationMemberState>,
    {organizationId, filter, search}: OrganizationMemberActions.GetList
  ): Promise<void> {
    const response: DataBaseServiceResponse<IOrganizationMemberWithRelations[]> = await firstValueFrom(this.baseService.get<IOrganizationMemberWithRelations[]>(`${this.SERVER}/organizations/${organizationId}/members`, filter, {params: {search: search ?? ''}}));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({list: response.entity!});
  }


  @Action(OrganizationMemberActions.CountList)
  async countOrganizationMembersList(
    {patchState}: StateContext<IOrganizationMemberState>,
    {organizationId, search}: OrganizationMemberActions.CountList
  ): Promise<void> {
    const response: DataBaseServiceResponse<number> = await firstValueFrom(this.baseService.count<number>(`${this.SERVER}/organizations/${organizationId}/members/count`, void 0, {params: {search: search ?? ''}}));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({count: response.entity!});
  }

  @Action(OrganizationMemberActions.Create)
  async createOrganizationMember(
    {patchState, getState}: StateContext<IOrganizationMemberState>,
    {organizationId, user}: OrganizationMemberActions.Create
  ): Promise<void> {
    const response: DataBaseServiceResponse<IUser> = await firstValueFrom(this.baseService.post<IUser>(`${this.SERVER}/organizations/${organizationId}/members`, user));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);


    const organizationMember: IOrganizationMemberWithRelations = {
      User: response.entity!,
      userId: response.entity!.id!,
      organizationId: organizationId,
      memberType: OrganizationMemberTypeEnum.MEMBER,
      joinedAt: new Date(),
    };

    patchState({
      list: UtilityClass.updateOrPushItems<IOrganizationMemberWithRelations>(getState().list, organizationMember, 'userId'),
      selectedId: response.entity!.id!,
    });
  }


  @Action(OrganizationMemberActions.GetById)
  async getOrganizationMemberById(
    {patchState, getState}: StateContext<IOrganizationMemberState>,
    {organizationId, userId}: OrganizationMemberActions.GetById
  ): Promise<void> {
    const response: DataBaseServiceResponse<IOrganizationMemberWithRelations> = await firstValueFrom(this.baseService.get<IOrganizationMemberWithRelations>(`${this.SERVER}/organizations/${organizationId}/members/${userId}`));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({
      list: UtilityClass.updateOrPushItems<IOrganizationMemberWithRelations>(getState().list, response.entity!, 'userId'),
      selectedId: response.entity!.userId!,
    });
  }

  @Action(OrganizationMemberActions.DeleteById)
  async deleteOrganizationMemberById(
    {patchState, getState}: StateContext<IOrganizationMemberState>,
    {organizationId, userId}: OrganizationMemberActions.DeleteById
  ): Promise<void> {
    const response: DataBaseServiceResponse<IOrganizationMemberWithRelations> = await firstValueFrom(this.baseService.delete<IOrganizationMemberWithRelations>(`${this.SERVER}/organizations/${organizationId}/members/${userId}`));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({
      list: UtilityClass.deleteItemByProp<IOrganizationMemberWithRelations>(getState().list, 'userId', userId),
      selectedId: null,
    });
  }

  @Action(OrganizationMemberActions.SetOrganizationMemberSelected)
  async setOrganizationMemberById(
    {patchState}: StateContext<IOrganizationMemberState>,
    {userId}: OrganizationMemberActions.SetOrganizationMemberSelected
  ): Promise<void> {
    patchState({selectedId: userId});
  }



  @Action(OrganizationMemberActions.Reset)
  async resetOrganizationMember({setState}: StateContext<IOrganizationMemberState>): Promise<void> {
    setState(_DEFAULT_DATA);
  }
}
