import {environment} from '@env/environment';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {BaseService} from '@shared/services';
import {ErrorHandlerService} from '@shared/services/error-handler.service';
import {ITraining} from '@training/interfaces/training.interface';
import {OrganizationTypeEnum} from '@organization/enums';
import {IOrganizationState} from '@organization/state';
import {DataBaseServiceResponse} from '@shared/services/base/interfaces/data-base-service-response.interface';
import {firstValueFrom} from 'rxjs';
import {ITrainingResponse} from '@training/interfaces/training-response.interace';

export interface ITrainingState {
  list: ITraining[];
}

const _DEFAULT_DATA: ITrainingState = {
  list: []
}

export namespace  TrainingActions {
  export class Response {
    static readonly type: string = '[Training] Response Training';
    constructor(public organizationId: string, public trainingId: string, public scheduleId: string) {}
  }

  export class Get {
    static readonly type: string = '[Training] Get Training List';
    constructor() {}
  }

}

@State<ITrainingState>({
  name: 'TrainingState',
  defaults: _DEFAULT_DATA
})
@Injectable()
export class TrainingState {
  private readonly SERVER: string = environment.SERVER;

  constructor(
    private baseService: BaseService,
    private errorHandlerService: ErrorHandlerService,
  ) {}

  @Selector()
  static getList({list}: ITrainingState): ITraining[] {
    return list;
  }

  @Action(TrainingActions.Get)
  async get({patchState}: StateContext<ITrainingState>, actions: TrainingActions.Get): Promise<void> {
    const response: DataBaseServiceResponse<ITraining[]> = await firstValueFrom(this.baseService.get<ITraining[]>(`${this.SERVER}/training`));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);
    patchState({
      list: response.entity ?? []
    });
  }

  @Action(TrainingActions.Response)
  async response({patchState}: StateContext<ITrainingState>, { trainingId, organizationId, scheduleId }: TrainingActions.Response): Promise<void> {
    const response: DataBaseServiceResponse<ITrainingResponse> = await firstValueFrom(this.baseService.post<ITrainingResponse>(`${this.SERVER}/training/${trainingId}/response`, { organizationId, scheduleId }));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

  }
}
