import {Injectable, NgZone} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest,} from '@angular/common/http';
import {firstValueFrom, Observable, Observer, throwError} from 'rxjs';
import {catchError, retry} from 'rxjs/operators';
import {Store} from '@ngxs/store';
import {Router} from '@angular/router';
import {StorageService} from '@shared/services';
import {AuthActions} from '@auth/state/auth.state';
import {NzMessageService} from 'ng-zorro-antd/message';
import {ErrorHandlerService} from '@shared/services/error-handler.service';
import {IStorageCredentials} from '@shared/services/storage/interfaces';
import {environment} from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private errorHandlerService: ErrorHandlerService,
    private router: Router,
    private ngZone: NgZone,
    protected nzMessageService: NzMessageService,
    private storageService: StorageService,
    private store: Store,
  ) { }


  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // ISSUE TOKEN
    if (req.url.endsWith('/auth/issue-token')) {
      return next.handle(req).pipe(catchError((error: HttpErrorResponse) => {
        this.ngZone.run(async (): Promise<void> => {
          this.storageService.clear();
          if (typeof error?.error?.message === 'string') {
            await this.router.navigate([environment.DEFAULT_PAGE_TO_LOAD]);
            this.nzMessageService.info(this.errorHandlerService.createRequestException(error).message);
          }
        }).then();

        return throwError(() => error);
      }));
    }


    // Set Authorization
    return this.setAuthorization(req, next).pipe(
      retry({
        count: 1,
        delay: (error: HttpErrorResponse): Observable<never> | Promise<unknown> => {
          // Request error
          if (!(error.error?.message ?? '').toString().includes('Unauthorized')) {
            return throwError(() => error);
          }

          // IssueToken
          return new Promise(async (resolve: (value: any) => void, reject: (error: any) => void): Promise<void> => {
            try {
              await firstValueFrom(this.store.dispatch(new AuthActions.IssueToken()))
              resolve(true);
            } catch (ex) {
              reject(ex);
            }
          });
        },
      }),
    );
  }

  private setAuthorization(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return new Observable((observer: Observer<HttpEvent<any>>): void => {
      const credentials: IStorageCredentials | null = this.storageService.getCredentials()
      let changedReq: HttpRequest<any> = req;

      if (credentials?.accessToken) {
        changedReq = req.clone({
          setHeaders: {
            Authorization: `Bearer ${credentials.accessToken}`,
          },
        });
      }

      next.handle(changedReq).subscribe({
        next: (value: HttpEvent<any>) => observer.next(value),
        error: (error) => observer.error(error),
      });

    });
  }

}
