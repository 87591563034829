<section class="auth max-w-[1500px]">
  <div class="auth-slider">
    <header class="flex flex-col items-center md:items-start">
      <div class="mt-14 flex gap-6 justify-between w-full items-center row sm:flex-row px-8 md:px-0">
        <img class="h-14 sm:h-20 md:h-16 lg:h-24" src="./assets/images/logo/bizgto_logo_v.png" alt="">
        <img class="h-14 sm:h-20 md:h-16 lg:h-24" src="./assets/images/logo/gto_compra_gto_blanco.png" alt="">
      </div>
      <h2 class="text-title text-white text-center md:text-left text-xl md:text-4xl mt-6 md:mt-12 mb-14 md:mb-0 mx-9 md:mx-0">
        Donde los negocios se encuentran con Guanajuato
      </h2>
    </header>
    <div class="hidden md:block">
      <nz-carousel nzAutoPlay [nzLoop]="true" [nzAutoPlaySpeed]="10000"
                   class="h-[120px] md:h-[165px] [&>div>ul]:!justify-start [&>div>ul]:!ml-0">
        <div nz-carousel-content *ngFor="let message of (slideItemList$ | async)!">
          <h2 class="text-white text-2xl md:text-3xl mb-2 md:mb-3.5">{{ message.title}}</h2>
          <p class="text-white text-xs md:text-base">{{ message.description}}</p>
        </div>
      </nz-carousel>
    </div>
  </div>

  <main class="main-section h-full">
    <header class="w-full justify-end hidden md:flex">
      <img class="w-32" src="./assets/images/logo/concamin-bajio.png" alt="">
    </header>
    <router-outlet></router-outlet>
  </main>
</section>
