import {IState} from '@shared/components/ui-elements/information/interfaces/state.interface';
import {
  ICertification, ICompanySize, IGovernmentAgency,
  IProductCategory,
  IProductSubcategory, IProductSubcategoryWithRelations,
  IServiceCategory,
  IServiceSubcategory, IServiceSubcategoryWithRelations, ISubSector,
} from '@platform/catalog/interfaces';
import {Injectable} from '@angular/core';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {ICertificationCategory} from '@platform/catalog/interfaces/certification-category.interface';
import {IDocumentCategory} from '@platform/catalog/interfaces/document-category.interface';
import {IExtensionCatalog} from '@platform/catalog/interfaces/extension-file.interface';
import {IJobTitle} from '@platform/catalog/interfaces/job-title.interface';
import {IMeasurementUnit} from '@platform/catalog/interfaces/measurement-unit.interface';
import {ISector} from '@platform/catalog/interfaces/sector.interface';
import {IService} from '@platform/catalog/interfaces/service.interface';
import {cloneDeep} from 'lodash';
import {ICountry} from '@platform/catalog/interfaces/country.interface';
import {ICity} from '@platform/catalog/interfaces/city.interface';
import {BaseService} from '@shared/services/base/base.service';
import {PrismaCountFilter, PrismaFilter} from '@shared/services/base/interfaces/prisma-filter.interface';
import {firstValueFrom} from 'rxjs';
import {environment} from '@env/environment';
import {DataBaseServiceResponse} from '@shared/services/base/interfaces/data-base-service-response.interface';

import {ErrorHandlerService} from '@shared/services/error-handler.service';
import {PLATFORM_CONFIG} from '@shared/config/platform-config.const';
import {ICurrency} from '@platform/catalog/interfaces/currency.interface';
import {UtilityClass} from '@shared/class/utils';
import {IDynamicFormOptions} from '@shared/components/ui-elements/form-container/interfaces/dynamic-form.interface';
import {
  IDocumentTemplate,
  IDocumentTemplateCreateUpdateDto, IDocumentTemplateWithRelations,
} from '@platform/catalog/interfaces/document-template.interface';
import {ICityStore} from '@platform/catalog/interfaces/city-store.interface';


export interface ICatalogState {
  documentCategoryList: IDocumentCategory[];
  documentCategorySelectedId: string | null;
  documentCategoryCount: number;
  extensionFileList: IExtensionCatalog[];
  extensionFileSelectedId: string | null;
  extensionFileCount: number;
  documentTemplateList: IDocumentTemplateWithRelations[];
  documentTemplateSelectedId: string | null;
  documentTemplateCount: number;
  serviceList: IService[];
  serviceSelectedId: string | null;
  serviceCount: number;
  measurementUnitList: IMeasurementUnit[];
  measurementUnitSelectedId: string | null;
  measurementUnitCount: number;
  currencyList: ICurrency[];
  currencySelectedId: string | null;
  currencyCount: number;
  companySizeList: ICompanySize[];
  companySizeSelectedId: string | null;
  companySizeCount: number;
  certificationCategoryList: ICertificationCategory[];
  certificationCategorySelectedId: string | null;
  certificationCategoryCount: number;
  certificationList: ICertification[];
  certificationSelectedId: string | null;
  certificationCount: number;

  sectorList: ISector[];
  sectorSelectedId: string | null;
  sectorCount: number;

  subSectorList: ISubSector[];
  subSectorSelectedId: string | null;
  subSectorCount: number;

  jobTitleList: IJobTitle[];
  jobTitleSelectedId: string | null;
  jobTitleCount: number;
  countryList: ICountry[];
  stateList: IState[],
  cityList: ICity[],
  productCategoryList: IProductCategory[];
  productCategorySelectedId: string | null;
  productCategoryCount: number;
  thereAreMoreSubcategories: boolean,
  productSubcategoryList: IProductSubcategory[];
  productSubcategoryWithRelationsList: IProductSubcategoryWithRelations[];
  productSubcategorySelectedId: string | null;
  productSubcategoryCount: number;
  serviceCategoryList: IServiceCategory[];
  serviceSubcategoryWithRelationsList: IServiceSubcategoryWithRelations[];
  serviceCategorySelectedId: string | null;
  serviceCategoryCount: number;
  serviceSubcategoryList: IServiceSubcategory[];
  serviceSubcategorySelectedId: string | null;
  serviceSubcategoryCount: number;

  governmentAgencyList: IGovernmentAgency[];
  governmentAgencySelectedId: string | null;
  governmentAgencyCount: number;

  cityStore: ICityStore[];
}

const _DEFAULT_DATA: ICatalogState = {
  documentCategoryList: [],
  documentCategorySelectedId: null,
  documentCategoryCount: 0,
  extensionFileList: [],
  extensionFileSelectedId: null,
  extensionFileCount: 0,
  documentTemplateList: [],
  documentTemplateSelectedId: null,
  documentTemplateCount: 0,
  serviceList: [],
  serviceSelectedId: null,
  serviceCount: 0,
  measurementUnitList: [],
  measurementUnitCount: 0,
  measurementUnitSelectedId: null,
  currencyList: [],
  currencyCount: 0,
  currencySelectedId: null,
  companySizeList: [],
  companySizeCount: 0,
  companySizeSelectedId: null,
  certificationCategoryList: [],
  certificationCategorySelectedId: null,
  certificationCategoryCount: 0,
  certificationList: [],
  certificationSelectedId: null,
  certificationCount: 0,
  sectorList: [],
  sectorSelectedId: null,
  sectorCount: 0,
  subSectorList: [],
  subSectorSelectedId: null,
  subSectorCount: 0,
  jobTitleList: [],
  jobTitleSelectedId: null,
  jobTitleCount: 0,
  countryList: [],
  stateList: [],
  cityList: [],
  productCategoryList: [],
  productCategoryCount: 0,
  productCategorySelectedId: null,
  thereAreMoreSubcategories: false,
  productSubcategoryList: [],
  productSubcategoryWithRelationsList: [],
  productSubcategoryCount: 0,
  productSubcategorySelectedId: null,
  serviceCategoryList: [],
  serviceCategoryCount: 0,
  serviceCategorySelectedId: null,
  serviceSubcategoryList: [],
  serviceSubcategoryWithRelationsList: [],
  serviceSubcategoryCount: 0,
  serviceSubcategorySelectedId: null,
  governmentAgencyList: [],
  governmentAgencySelectedId: null,
  governmentAgencyCount: 0,

  cityStore: [],
}

export namespace CatalogActions {
  /* DOCUMENT CATEGORY */
  export class GetDocumentCategoryList {
    static readonly type: string = '[Catalog] Get DocumentCategory List';
    constructor(public filters?: PrismaFilter<IDocumentCategory>) {
    }
  }

  export class GetDocumentCategoryCount {
    static readonly type: string = '[Catalog] Get DocumentCategory Count';
    constructor(public filters?: PrismaCountFilter<IDocumentCategory>) {
    }
  }

  export class GetDocumentCategoryById {
    static readonly type: string = '[Catalog] Get DocumentCategory By Id';
    constructor(public id: string, public filters?: PrismaFilter<IDocumentCategory>) {
    }
  }

  /* DOCUMENT CATEGORY */

  /* EXTENSION FILE */
  export class GetExtensionFileList {
    static readonly type: string = '[Catalog] Get ExtensionFile List';

    constructor(public filters?: PrismaFilter<IExtensionCatalog>) {
    }
  }

  export class GetExtensionFileCount {
    static readonly type: string = '[Catalog] Get ExtensionFile Count';

    constructor(public filters?: PrismaCountFilter<IExtensionCatalog>) {
    }
  }

  export class GetExtensionFileById {
    static readonly type: string = '[Catalog] Get ExtensionFile By Id';

    constructor(public id: string, public filters?: PrismaFilter<IExtensionCatalog>) {
    }
  }

  /* EXTENSION FILE */

  /* DOCUMENT TEMPLATE */
  export class GetDocumentTemplateList {
    static readonly type: string = '[Catalog] Get DocumentTemplate List';

    constructor(public filters?: PrismaFilter<IDocumentTemplate>) {
    }
  }

  export class GetDocumentTemplateCount {
    static readonly type: string = '[Catalog] Get DocumentTemplate Count';

    constructor(public filters?: PrismaCountFilter<IDocumentTemplate>) {
    }
  }

  export class GetDocumentTemplateById {
    static readonly type: string = '[Catalog] Get DocumentTemplate By Id';

    constructor(public id: string, public filters?: PrismaFilter<IDocumentTemplate>) {
    }
  }

  export class PostDocumentTemplate {
    static readonly type: string = '[Catalog] Post DocumentTemplate';

    constructor(public entity: IDocumentTemplateCreateUpdateDto) {
    }
  }

  export class PatchDocumentTemplate {
    static readonly type: string = '[Catalog] Patch DocumentTemplate';

    constructor(public id: string, public entity: IDocumentTemplateCreateUpdateDto) {
    }
  }

  export class DeleteDocumentTemplateById {
    static readonly type: string = '[Catalog] Delete DocumentTemplate By Id';

    constructor(public id: string) {
    }
  }
  /* DOCUMENT TEMPLATE */

  /* SERVICE */
  export class GetServiceList {
    static readonly type: string = '[Catalog] Get Service List';

    constructor(public filters?: PrismaFilter<IService>) {
    }
  }

  export class GetServiceSubcategoryWithRelationsList {
    static readonly type: string = '[Catalog] Get Service subcategory with relations list';

    constructor(public search: string, public take: number, public skip: number, public concat?: boolean) { }
  }

  export class GetServiceCount {
    static readonly type: string = '[Catalog] Get Service Count';

    constructor(public filters?: PrismaCountFilter<IService>) {
    }
  }

  export class GetServiceById {
    static readonly type: string = '[Catalog] Get Service By Id';

    constructor(public id: string, public filters?: PrismaFilter<IService>) {
    }
  }

  /* SERVICE */

  /* MEASUREMENT UNIT */
  export class GetMeasurementUnitList {
    static readonly type: string = '[Catalog] Get MeasurementUnit List';

    constructor(public filters?: PrismaFilter<IMeasurementUnit>) {
    }
  }

  export class GetMeasurementUnitCount {
    static readonly type: string = '[Catalog] Get MeasurementUnit Count';

    constructor(public filters?: PrismaCountFilter<IMeasurementUnit>) {
    }
  }

  export class GetMeasurementUnitById {
    static readonly type: string = '[Catalog] Get MeasurementUnit By Id';

    constructor(public id: string, public filters?: PrismaFilter<IMeasurementUnit>) {
    }
  }

  /* MEASUREMENT UNIT */

  /* CURRENCY ACTIONS */
  export class GetCurrencyList {
    static readonly type: string = '[Catalog] Get Currency List';

    constructor(public filters?: PrismaFilter<ICurrency>) {
    }
  }

  export class GetCurrencyCount {
    static readonly type: string = '[Catalog] Get Currency Count';

    constructor(public filters?: PrismaCountFilter<ICurrency>) {
    }
  }

  export class GetCurrencyById {
    static readonly type: string = '[Catalog] Get Currency By Id';

    constructor(public id: string, public filters?: PrismaFilter<ICurrency>) {
    }
  }

  /* CURRENCY ACTIONS */

  /* COMPANY ACTIONS */
  export class GetCompanySizeList {
    static readonly type: string = '[Catalog] Get CompanySize List';
    constructor(public filters?: PrismaFilter<ICompanySize>) {
    }
  }

  export class GetCompanySizeCount {
    static readonly type: string = '[Catalog] Get CompanySize Count';
    constructor(public filters?: PrismaCountFilter<ICompanySize>) {
    }
  }

  export class GetCompanySizeById {
    static readonly type: string = '[Catalog] Get CompanySize By Id';
    constructor(public id: string, public filters?: PrismaFilter<ICompanySize>) {
    }
  }

  /* CERTIFICATION CATEGORY */
  export class GetCertificationCategoryList {
    static readonly type: string = '[Catalog] Get CertificationCategory List';

    constructor(public filters?: PrismaFilter<ICertificationCategory>) {
    }
  }

  export class GetCertificationCategoryCount {
    static readonly type: string = '[Catalog] Get CertificationCategory Count';

    constructor(public filters?: PrismaCountFilter<ICertificationCategory>) {
    }
  }

  export class GetCertificationCategoryById {
    static readonly type: string = '[Catalog] Get CertificationCategory By Id';

    constructor(public id: string, public filters?: PrismaFilter<ICertificationCategory>) {
    }
  }

  /* CERTIFICATION CATEGORY */

  /* CERTIFICATION */
  export class GetCertificationList {
    static readonly type: string = '[Catalog] Get Certification List';

    constructor(public filters?: PrismaFilter<ICertification>) {
    }
  }

  export class GetCertificationCount {
    static readonly type: string = '[Catalog] Get Certification Count';

    constructor(public filters?: PrismaCountFilter<ICertification>) {
    }
  }

  export class GetCertificationById {
    static readonly type: string = '[Catalog] Get Certification By Id';

    constructor(public id: string, public filters?: PrismaFilter<ICertification>) {
    }
  }

  /* CERTIFICATION */

  /* SECTOR */
  export class GetSectorList {
    static readonly type: string = '[Catalog] Get Sector List';

    constructor(public filters?: PrismaFilter<ISector>) {
    }
  }

  export class GetSectorCount {
    static readonly type: string = '[Catalog] Get Sector Count';

    constructor(public filters?: PrismaCountFilter<ISector>) {
    }
  }

  export class GetSectorById {
    static readonly type: string = '[Catalog] Get Sector By Id';

    constructor(public id: string, public filters?: PrismaFilter<ISector>) {
    }
  }

  /* SECTOR */

  /* SUB SECTOR */
  export class GetSubSectorList {
    static readonly type: string = '[Catalog] Get SubSector List';

    constructor(public sectorId: string, public filters?: PrismaFilter<ISubSector>) {
    }
  }

  export class GetSubSectorCount {
    static readonly type: string = '[Catalog] Get SubSector Count';

    constructor(public sectorId: string, public filters?: PrismaCountFilter<ISubSector>) {
    }
  }

  export class GetSubSectorById {
    static readonly type: string = '[Catalog] Get Sub Sector By Id';

    constructor(public sectorId: string, public id: string, public filters?: PrismaFilter<ISubSector>) {
    }
  }

  /* SUB SECTOR */


  /* JOB TITLE */
  export class GetJobTitleList {
    static readonly type: string = '[Catalog] Get JobTitle List';

    constructor(public filters?: PrismaFilter<IJobTitle>) {
    }
  }

  export class GetJobTitleCount {
    static readonly type: string = '[Catalog] Get JobTitle Count';

    constructor(public filters?: PrismaFilter<IJobTitle>) {
    }
  }

  export class GetJobTitleById {
    static readonly type: string = '[Catalog] Get JobTitle By Id';

    constructor(public id: string, public filters?: PrismaFilter<IJobTitle>) {
    }
  }

  /* JOB TITLE */

  /* COUNTRY */
  export class GetCountryList {
    static readonly type: string = '[Catalog] Get Country List';
    constructor(public filters?: PrismaFilter<ICountry>, public isAddedTo?: boolean) {
    }
  }


  export class AddCountryToList {
    static readonly type: string = '[Catalog] Add Country To List';
    constructor(public country: ICountry) {
    }
  }

  /* COUNTRY */

  /* STATE */
  export class GetStateList {
    static readonly type: string = '[Catalog] Get State List';
    constructor(public filters?: PrismaFilter<ISector>) {
    }
  }

  export class GetStateById {
    static readonly type: string = '[Catalog] Get State By Id';
    constructor(public id: string, public filters?: PrismaFilter<IState>) {
    }
  }




  /* STATE */

  /* CITY */
  export class GetCityList {
    static readonly type: string = '[Catalog] Get City List';

    constructor(public filters?: PrismaFilter<ICity>, public isAddedTo?: boolean, public _cityStoreUID?: string) {
    }
  }

  export class GetCityById {
    static readonly type: string = '[Catalog] Get City By Id';

    constructor(public id: string, public filters?: PrismaFilter<ICity>, public _cityStoreUID?: string) {
    }
  }

  /* CITY */

  /* PRODUCT CATEGORY ACTIONS */

  export class GetProductCategoryList {
    static readonly type: string = '[Catalog] Get product category list';

    constructor(public filters?: PrismaFilter<IProductCategory>) {
    }
  }

  export class GetProductSubcategoryWithRelationsList {
    static readonly type: string = '[Catalog] Get product subcategory with relations list';

    constructor(public search: string, public take: number, public skip: number, public concat?: boolean) { }
  }

  export class GetProductCategoryById {
    static readonly type: string = '[Catalog] Get product category by id';

    constructor(public id: string) {
    }
  }

  export class CountProductCategory {
    static readonly type: string = '[Catalog] Count product category';

    constructor(public filters?: PrismaFilter<IProductCategory>) {
    }
  }

  /* PRODUCT CATEGORY ACTIONS */

  /* PRODUCT SUBCATEGORY ACTIONS */

  export class GetProductSubcategoryList {
    static readonly type: string = '[Catalog] Get product subcategory list';

    constructor(public categoryId: string, public filters?: PrismaFilter<IProductSubcategory>, public concat?: boolean) {
    }
  }

  export class RemoveLocalProductSubcategoryByCategoryId {
    static readonly type: string = '[Catalog] Remove local product subcategory by category id';

    constructor(public categoryId: string) { }
  }

  export class RemoveLocalServiceSubcategoryByCategoryId {
    static readonly type: string = '[Catalog] Remove local service subcategory by category id';

    constructor(public categoryId: string) { }
  }


  export class GetProductSubcategoryById {
    static readonly type: string = '[Catalog] Get product subcategory by id';

    constructor(public categoryId: string, public id: string) {
    }
  }

  export class CountProductSubcategory {
    static readonly type: string = '[Catalog] Count product subcategory';

    constructor(public categoryId: string, public filters?: PrismaFilter<IProductSubcategory>) {
    }
  }

  /* PRODUCT SUBCATEGORY ACTIONS */

  /* SERVICE CATEGORY ACTIONS */

  export class GetServiceCategoryList {
    static readonly type: string = '[Catalog] Get service category list';

    constructor(public filters?: PrismaFilter<IServiceCategory>) {
    }
  }

  export class GetServiceCategoryById {
    static readonly type: string = '[Catalog] Get service category by id';

    constructor(public id: string) {
    }
  }

  export class CountServiceCategory {
    static readonly type: string = '[Catalog] Count service category';

    constructor(public filters?: PrismaFilter<IServiceCategory>) {
    }
  }

  /* SERVICE CATEGORY ACTIONS */

  /* SERVICE SUBCATEGORY ACTIONS */

  export class GetServiceSubcategoryList {
    static readonly type: string = '[Catalog] Get service subcategory list';

    constructor(public categoryId: string, public filters?: PrismaFilter<IServiceSubcategory>, public concat: boolean = false) {
    }
  }

  export class GetServiceSubcategoryById {
    static readonly type: string = '[Catalog] Get service subcategory by id';

    constructor(public categoryId: string, public id: string) {
    }
  }

  export class CountServiceSubcategory {
    static readonly type: string = '[Catalog] Count service subcategory';
    constructor(public categoryId: string, public filters?: PrismaFilter<IServiceSubcategory>) {
    }
  }

  /* SERVICE SUBCATEGORY ACTIONS */

  /* GOVERNMENT AGENCY ACTIONS */
  export class GetGovernmentAgencyList {
    static readonly type: string = '[Catalog] Get government agency list';

    constructor(public filters?: PrismaFilter<IGovernmentAgency>) { }
  }
  /* GOVERNMENT AGENCY ACTIONS */

  export class Reset {
    static readonly type: string = '[Catalog] Reset';
  }
}


@State<ICatalogState>({
  name: 'CatalogState',
  defaults: cloneDeep(_DEFAULT_DATA),

})
@Injectable()
export class CatalogState {
  private readonly SERVER: string = environment.SERVER;

  constructor(
    private baseService: BaseService,
    private errorHandlerService: ErrorHandlerService,
  ) {
  }

  @Selector()
  static getGovernmentAgencyList({governmentAgencyList}: ICatalogState): IGovernmentAgency[] {
    return governmentAgencyList;
  }

  @Selector()
  static getDocumentCategoryList(state: ICatalogState): IDocumentCategory[] {
    return state.documentCategoryList;
  }

  @Selector()
  static getDocumentCategoryCount({documentCategoryCount}: ICatalogState): number {
    return documentCategoryCount;
  }

  @Selector()
  static getDocumentCategorySelected(state: ICatalogState): IDocumentCategory | undefined {
    return state.documentCategoryList.find(({id}: IDocumentCategory): boolean => id === state.documentCategorySelectedId);
  }

  @Selector()
  static getExtensionFileList(state: ICatalogState): IExtensionCatalog[] {
    return state.extensionFileList;
  }

  @Selector()
  static getExtensionFileCount({extensionFileCount}: ICatalogState): number {
    return extensionFileCount;
  }

  @Selector()
  static getExtensionFileSelected(state: ICatalogState): IExtensionCatalog | undefined {
    return state.extensionFileList.find(({id}: IExtensionCatalog): boolean => id === state.extensionFileSelectedId);
  }

  @Selector()
  static getDocumentTemplateList(state: ICatalogState): IDocumentTemplateWithRelations[] {
    return state.documentTemplateList;
  }

  @Selector()
  static getDocumentTemplateCount({documentTemplateCount}: ICatalogState): number {
    return documentTemplateCount;
  }

  @Selector()
  static getDocumentTemplateSelected(state: ICatalogState): IDocumentTemplate | undefined {
    return state.documentTemplateList.find(({id}: IDocumentTemplate): boolean => id === state.documentTemplateSelectedId);
  }

  @Selector()
  static getServiceList(state: ICatalogState): IService[] {
    return state.serviceList;
  }

  @Selector()
  static getServiceCount({serviceCount}: ICatalogState): number {
    return serviceCount;
  }

  @Selector()
  static getServiceSelected(state: ICatalogState): IService | undefined {
    return state.serviceList.find(({id}: IService): boolean => id === state.serviceSelectedId);
  }

  @Selector()
  static getMeasurementUnitList(state: ICatalogState): IMeasurementUnit[] {
    return state.measurementUnitList;
  }

  @Selector()
  static getMeasurementUnitDynamicFormOpts(state: ICatalogState): IDynamicFormOptions[] {
    return state.measurementUnitList.map(({id, name}: IMeasurementUnit): IDynamicFormOptions => ({value: id!, label: name}));
  }

  @Selector()
  static getMeasurementUnitCount({measurementUnitCount}: ICatalogState): number {
    return measurementUnitCount;
  }

  @Selector()
  static getMeasurementUnitSelected(state: ICatalogState): IMeasurementUnit | undefined {
    return state.measurementUnitList.find(({id}: IMeasurementUnit): boolean => id === state.measurementUnitSelectedId);
  }

  /* CURRENCY SELECTORS */
  @Selector()
  static getCurrencyList(state: ICatalogState): ICurrency[] {
    return state.currencyList;
  }

  @Selector()
  static getCurrencyCount({currencyCount}: ICatalogState): number {
    return currencyCount;
  }

  @Selector()
  static getCurrencySelected(state: ICatalogState): ICurrency | undefined {
    return state.currencyList.find(({id}: ICurrency): boolean => id === state.currencySelectedId);
  }

  /* CURRENCY SELECTORS */

  @Selector()
  static getCertificationCategoryList(state: ICatalogState): ICertificationCategory[] {
    return state.certificationCategoryList;
  }

  @Selector()
  static getCertificationCategoryCount({certificationCategoryCount}: ICatalogState): number {
    return certificationCategoryCount;
  }

  @Selector()
  static getCertificationCategorySelected(state: ICatalogState): ICertificationCategory | undefined {
    return state.certificationCategoryList.find(({id}: ICertificationCategory): boolean => id === state.certificationCategorySelectedId);
  }

  @Selector()
  static getCertificationList(state: ICatalogState): ICertification[] {
    return state.certificationList;
  }

  @Selector()
  static getCertificationCount({certificationCount}: ICatalogState): number {
    return certificationCount;
  }

  @Selector()
  static getCertificationSelected(state: ICatalogState): ICertification | undefined {
    return state.certificationList.find(({id}: ICertification): boolean => id === state.certificationSelectedId);
  }

  @Selector()
  static getSectorList(state: ICatalogState): ISector[] {
    return state.sectorList;
  }

  @Selector()
  static getSectorCount({sectorCount}: ICatalogState): number {
    return sectorCount;
  }

  @Selector()
  static getSectorSelected(state: ICatalogState): ISector | undefined {
    return state.sectorList.find(({id}: ISector): boolean => id === state.sectorSelectedId);
  }

  @Selector()
  static getSubSectorList(state: ICatalogState): ISubSector[] {
    return state.subSectorList;
  }

  @Selector()
  static getSubSectorCount({subSectorCount}: ICatalogState): number {
    return subSectorCount;
  }

  @Selector()
  static getSubSectorSelected(state: ICatalogState): ISubSector | undefined {
    return state.subSectorList.find(({id}: ISubSector): boolean => id === state.subSectorSelectedId);
  }

  @Selector()
  static getJobTitleList(state: ICatalogState): IJobTitle[] {
    return state.jobTitleList;
  }

  @Selector()
  static getJobTitleCount({jobTitleCount}: ICatalogState): number {
    return jobTitleCount;
  }

  @Selector()
  static getJobTitleSelected(state: ICatalogState): IJobTitle | undefined {
    return state.jobTitleList.find(({id}: IJobTitle): boolean => id === state.jobTitleSelectedId);
  }

  @Selector()
  static getCountryList(state: ICatalogState): ICountry[] {
    return state.countryList;
  }


 /* @Selector()
  static getStateSelected(state: ICatalogState): IState | undefined {
    return state.stateList.find(({id}) => id === state.stateSelectedId);
  }*/

  @Selector()
  static getStateList(state: ICatalogState): IState[] {
    return state.stateList;
  }

  @Selector()
  static getStateListByCountry(state: ICatalogState): (_countryId: string) => IState[] {
    return (_countryId: string) => {
      return state.stateList.filter(({countryId}: IState): boolean => countryId === _countryId);
    }
  }

  @Selector()
  static getCityListByCityStoreUID(state: ICatalogState): (cityStoreUID: string) => ICity[] {
    return (_cityStoreUID: string) => {
      return state.cityStore.find(({cityStoreUID}: ICityStore): boolean => cityStoreUID === _cityStoreUID)?.cityList ?? []
    }
  }

  @Selector()
  static getThereAreMoreCitiesByCityStoreUID(state: ICatalogState): (cityStoreUID: string) => boolean {
    return (_cityStoreUID: string) => {
      return state.cityStore.find(({cityStoreUID}: ICityStore): boolean => cityStoreUID === _cityStoreUID)?.therAreMoreCities ?? false
    }
  }

  @Selector()
  static getLastCityByCityStoreUID(state: ICatalogState): (cityStoreUID: string) => ICity | undefined {
    return (_cityStoreUID: string) => {
      return state.cityStore.find(({cityStoreUID}: ICityStore): boolean => cityStoreUID === _cityStoreUID)?.cityList.at(-1)
    }
  }



  /* PRODUCT CATEGORY SELECTORS */
  @Selector()
  static getProductCategoryList({productCategoryList}: ICatalogState): IProductCategory[] {
    return productCategoryList;
  }

  @Selector()
  static getProductCategoryDynamicFormOpts({productCategoryList}: ICatalogState): IDynamicFormOptions[] {
    return productCategoryList.map(({name, id}: IProductCategory): IDynamicFormOptions => ({value: id!, label: name}));
  }

  @Selector()
  static getServiceCategoryDynamicFormOpts({serviceCategoryList}: ICatalogState): IDynamicFormOptions[] {
    return serviceCategoryList.map(({name, id}: IServiceCategory): IDynamicFormOptions => ({value: id!, label: name}));
  }

  @Selector()
  static getProductCategoryCount({productCategoryCount}: ICatalogState): number {
    return productCategoryCount;
  }

  @Selector()
  static getProductCategorySelected(
    {productCategorySelectedId, productCategoryList}: ICatalogState,
  ): IProductCategory | null {
    return productCategoryList.find(({id}: IProductCategory): boolean => id === productCategorySelectedId) ?? null;
  }

  /* PRODUCT CATEGORY SELECTORS */

  /* PRODUCT SUBCATEGORY SELECTORS */
  @Selector()
  static getProductSubcategoryList({productSubcategoryList}: ICatalogState): IProductSubcategory[] {
    return productSubcategoryList;
  }

  @Selector()
  static getProductSubcategoryWitRelationsList({productSubcategoryWithRelationsList}: ICatalogState): IProductSubcategoryWithRelations[] {
    return productSubcategoryWithRelationsList;
  }

  @Selector()
  static getThereAreMoreSubcategories({thereAreMoreSubcategories}: ICatalogState): boolean {
    return thereAreMoreSubcategories;
  }

  @Selector()
  static getProductSubcategoryDynamicFormOpts({productSubcategoryList}: ICatalogState): IDynamicFormOptions[] {
    return productSubcategoryList.map(({name, id}: IProductCategory): IDynamicFormOptions => ({value: id!, label: name}));
  }

  @Selector()
  static getServiceSubcategoryDynamicFormOpts({serviceSubcategoryList}: ICatalogState): IDynamicFormOptions[] {
    return serviceSubcategoryList.map(({name, id}: IServiceSubcategory): IDynamicFormOptions => ({value: id!, label: name}));
  }

  @Selector()
  static getProductSubcategoryCount({productSubcategoryCount}: ICatalogState): number {
    return productSubcategoryCount;
  }

  @Selector()
  static getProductSubcategorySelected(
    {productSubcategorySelectedId, productSubcategoryList}: ICatalogState,
  ): IProductSubcategory | null {
    return productSubcategoryList.find(({id}: IProductCategory): boolean => id === productSubcategorySelectedId) ?? null;
  }

  /* PRODUCT SUBCATEGORY SELECTORS */

  /* SERVICE CATEGORY SELECTORS */
  @Selector()
  static getServiceCategoryList({serviceCategoryList}: ICatalogState): IServiceCategory[] {
    return serviceCategoryList;
  }

  @Selector()
  static getServiceSubcategoryWithRelationsList({serviceSubcategoryWithRelationsList}: ICatalogState): IServiceSubcategoryWithRelations[] {
    return serviceSubcategoryWithRelationsList;
  }

  @Selector()
  static getServiceCategoryCount({serviceCategoryCount}: ICatalogState): number {
    return serviceCategoryCount;
  }

  @Selector()
  static getServiceCategorySelected(
    {serviceCategorySelectedId, serviceCategoryList}: ICatalogState,
  ): IServiceCategory | null {
    return serviceCategoryList.find(({id}: IProductCategory): boolean => id === serviceCategorySelectedId) ?? null;
  }

  /* SERVICE CATEGORY SELECTORS */

  /* SERVICE SUBCATEGORY SELECTORS */
  @Selector()
  static getServiceSubcategoryList({serviceSubcategoryList}: ICatalogState): IServiceSubcategory[] {
    return serviceSubcategoryList;
  }

  @Selector()
  static getServiceSubcategoryCount({serviceSubcategoryCount}: ICatalogState): number {
    return serviceSubcategoryCount;
  }

  @Selector()
  static getServiceSubcategorySelected(
    {serviceSubcategorySelectedId, serviceSubcategoryList}: ICatalogState,
  ): IServiceSubcategory | null {
    return serviceSubcategoryList.find(({id}: IServiceCategory): boolean => id === serviceSubcategorySelectedId) ?? null;
  }

  /* SERVICE SUBCATEGORY SELECTORS */

  /* COMPANY SIZE SELECTORS */
  @Selector()
  static getCompanySizeList(state: ICatalogState): ICompanySize[] {
    return state.companySizeList;
  }

  @Selector()
  static getCompanySizeCount({companySizeCount}: ICatalogState): number {
    return companySizeCount;
  }

  @Selector()
  static getCompanySizeSelected(state: ICatalogState): ICompanySize | undefined {
    return state.companySizeList.find(({id}: ICompanySize): boolean => id === state.companySizeSelectedId);
  }

  @Selector()
  static getSectorDynamicFormOpts({sectorList}: ICatalogState): IDynamicFormOptions[] {
    return sectorList.map(({name, id}: IProductCategory): IDynamicFormOptions => ({value: id!, label: name}));
  }

  /* COMPANY SIZE SELECTORS */

  @Action(CatalogActions.GetGovernmentAgencyList)
  async getGovernmentAgencyList({patchState, getState}: StateContext<ICatalogState>, {filters}: CatalogActions.GetGovernmentAgencyList): Promise<void> {
    if (getState().governmentAgencyList.length > 0) return;
    const response: DataBaseServiceResponse<IGovernmentAgency[]> = await firstValueFrom(this.baseService.get<IGovernmentAgency[]>(`${this.SERVER}/government-agencies`, filters));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({governmentAgencyList: response.entity ?? []});
  }

  /* DOCUMENT CATEGORY */
  @Action(CatalogActions.GetDocumentCategoryList)
  async GetDocumentCategoryList(
    {patchState}: StateContext<ICatalogState>,
    {filters}: CatalogActions.GetDocumentCategoryList,
  ): Promise<void> {
    const response: DataBaseServiceResponse<IDocumentCategory[]> = await firstValueFrom(this.baseService.get<IDocumentCategory[]>(`${this.SERVER}/document-categories`, filters));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({
      documentCategoryList: response.entity ?? [],
    });
  }

  @Action(CatalogActions.GetDocumentCategoryList)
  async GetDocumentCategoryCount(
    {patchState}: StateContext<ICatalogState>,
    {filters}: CatalogActions.GetDocumentCategoryList,
  ): Promise<void> {
    const response: DataBaseServiceResponse<number> = await firstValueFrom(this.baseService.count<IDocumentCategory>(`${this.SERVER}/document-categories/count`, filters))
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({documentCategoryCount: response.entity ?? 0});
  }

  @Action(CatalogActions.GetDocumentCategoryById)
  async GetDocumentCategoryById(
    {patchState, getState}: StateContext<ICatalogState>,
    {id, filters}: CatalogActions.GetDocumentCategoryById,
  ): Promise<void> {
    const response: DataBaseServiceResponse<IDocumentCategory> = await firstValueFrom(this.baseService.get<IDocumentCategory>(`${this.SERVER}/document-categories/${id}`, filters));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({
      documentCategoryList: UtilityClass.updateOrPushItems<IDocumentCategory>(getState().documentCategoryList, response.entity!, 'id'),
      documentCategorySelectedId: id,
    });
  }

  /* DOCUMENT CATEGORY */

  /* EXTENSION FILE */
  @Action(CatalogActions.GetExtensionFileList)
  async GetExtensionFileList(
    {patchState}: StateContext<ICatalogState>,
    {filters}: CatalogActions.GetExtensionFileList,
  ): Promise<void> {
    const response: DataBaseServiceResponse<IExtensionCatalog[]> = await firstValueFrom(this.baseService.get<IExtensionCatalog[]>(`${this.SERVER}/extension-files`, filters));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({
      extensionFileList: response.entity ?? [],
    });
  }

  @Action(CatalogActions.GetExtensionFileCount)
  async GetExtensionFileCount(
    {patchState}: StateContext<ICatalogState>,
    {filters}: CatalogActions.GetExtensionFileCount,
  ): Promise<void> {
    const response: DataBaseServiceResponse<number> = await firstValueFrom(this.baseService.count<IExtensionCatalog[]>(`${this.SERVER}/extension-files/count`, filters));
    if (response.error) this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({
      extensionFileCount: response.entity ?? 0,
    });
  }

  @Action(CatalogActions.GetExtensionFileById)
  async GetExtensionFileById(
    {patchState, getState}: StateContext<ICatalogState>,
    {id, filters}: CatalogActions.GetExtensionFileById,
  ): Promise<void> {
    const response: DataBaseServiceResponse<IExtensionCatalog> = await firstValueFrom(this.baseService.get<IExtensionCatalog>(`${this.SERVER}/extension-files/${id}`, filters));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({
      extensionFileList: UtilityClass.updateOrPushItems<IExtensionCatalog>(getState().extensionFileList, response.entity!, 'id'),
      extensionFileSelectedId: id,
    });
  }

  /* EXTENSION FILE */

  /* DOCUMENT TEMPLATE */
  @Action(CatalogActions.GetDocumentTemplateList)
  async GetDocumentTemplateList(
    {patchState}: StateContext<ICatalogState>,
    {filters}: CatalogActions.GetDocumentTemplateList
  ): Promise<void> {
    const response: DataBaseServiceResponse<IDocumentTemplateWithRelations[]> = await firstValueFrom(this.baseService.get<IDocumentTemplateWithRelations[]>(`${this.SERVER}/document-templates`, filters));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({
      documentTemplateList: response.entity ?? [],
    });
  }


  @Action(CatalogActions.GetDocumentTemplateCount)
  async GetDocumentTemplateCount(
    {patchState}: StateContext<ICatalogState>,
    {filters}: CatalogActions.GetDocumentTemplateCount
  ): Promise<void> {
    const response: DataBaseServiceResponse<number> = await firstValueFrom(this.baseService.count<IDocumentTemplate>(`${this.SERVER}/document-templates/count`, filters));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({documentTemplateCount: response.entity ?? 0});
  }

  @Action(CatalogActions.GetDocumentTemplateById)
  async GetDocumentTemplateById(
    {patchState, getState}: StateContext<ICatalogState>,
    {id, filters}: CatalogActions.GetDocumentTemplateById
  ): Promise<void> {
    const response: DataBaseServiceResponse<IDocumentTemplateWithRelations> = await firstValueFrom(this.baseService.get<IDocumentTemplateWithRelations>(`${this.SERVER}/document-templates/${id}`, filters));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({
      documentTemplateList: UtilityClass.updateOrPushItems<IDocumentTemplateWithRelations>(getState().documentTemplateList, response.entity!, 'id'),
      documentTemplateSelectedId: id,
    });
  }

  @Action(CatalogActions.PostDocumentTemplate)
  async PostDocumentTemplate(
    {patchState, getState}: StateContext<ICatalogState>,
    {entity}: CatalogActions.PostDocumentTemplate
  ): Promise<void> {
    const response: DataBaseServiceResponse<IDocumentTemplateWithRelations> = await firstValueFrom(this.baseService.post<IDocumentTemplateWithRelations>(`${this.SERVER}/document-templates`, entity));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({
      documentTemplateList: [...getState().documentTemplateList, response.entity!],
      documentTemplateSelectedId: response.entity?.id
    });
  }

  @Action(CatalogActions.PatchDocumentTemplate)
  async PatchDocumentTemplate(
    {patchState, getState}: StateContext<ICatalogState>,
    {id, entity}: CatalogActions.PatchDocumentTemplate
  ): Promise<void> {
    const response: DataBaseServiceResponse<IDocumentTemplateWithRelations> = await firstValueFrom(this.baseService.patch<IDocumentTemplateWithRelations>(`${this.SERVER}/document-templates/${id}`, entity));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({
      documentTemplateList: UtilityClass.updateOrPushItems<IDocumentTemplateWithRelations>(getState().documentTemplateList, response.entity!, 'id'),
      documentTemplateSelectedId: id,
    });
  }

  @Action(CatalogActions.DeleteDocumentTemplateById)
  async DeleteDocumentTemplateById(
    {patchState, getState}: StateContext<ICatalogState>,
    {id}: CatalogActions.DeleteDocumentTemplateById
  ): Promise<void> {
    const response: DataBaseServiceResponse<IDocumentTemplateWithRelations> = await firstValueFrom(this.baseService.delete<IDocumentTemplateWithRelations>(`${this.SERVER}/document-templates/${id}`));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({
      documentTemplateList: UtilityClass.deleteItemByProp<IDocumentTemplateWithRelations>(getState().documentTemplateList, 'id', id),
      documentTemplateSelectedId: null,
    });
  }

  /* DOCUMENT TEMPLATE */

  /* SERVICE */
  @Action(CatalogActions.GetServiceList)
  async GetServiceList(
    {patchState}: StateContext<ICatalogState>,
    {filters}: CatalogActions.GetServiceList,
  ): Promise<void> {
    const response: DataBaseServiceResponse<IService[]> = await firstValueFrom(this.baseService.get<IService[]>(`${this.SERVER}/services`, filters));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({
      serviceList: response.entity ?? [],
    });
  }

  @Action(CatalogActions.GetServiceSubcategoryWithRelationsList)
  async GetServiceSubcategoryWithRelationsList(
    {patchState, getState}: StateContext<ICatalogState>,
    {search, take, skip, concat}: CatalogActions.GetServiceSubcategoryWithRelationsList
  ): Promise<void> {
    const response: DataBaseServiceResponse<IServiceSubcategoryWithRelations[]> = await firstValueFrom(this.baseService.get<IServiceSubcategoryWithRelations[]>(`${this.SERVER}/services/subcategories/search?search=${search}&skip=${skip}&take=${take}`));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);
    patchState({
      serviceSubcategoryWithRelationsList: !concat ? response.entity! : [...getState().serviceSubcategoryWithRelationsList, ...response.entity!],
      thereAreMoreSubcategories: !(response.entity && response.entity.length < PLATFORM_CONFIG.PAGINATOR.ITEMS_PER_SCROLL)
    });
  }

  @Action(CatalogActions.GetServiceCount)
  async GetServiceCount(
    {patchState}: StateContext<ICatalogState>,
    {filters}: CatalogActions.GetServiceCount,
  ): Promise<void> {
    const response: DataBaseServiceResponse<number> = await firstValueFrom(this.baseService.count<IService>(`${this.SERVER}/services/count`, filters));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({serviceCount: response.entity ?? 0});
  }


  @Action(CatalogActions.GetServiceById)
  async GetServiceById(
    {patchState, getState}: StateContext<ICatalogState>,
    {id, filters}: CatalogActions.GetServiceById,
  ): Promise<void> {
    const response: DataBaseServiceResponse<IService> = await firstValueFrom(this.baseService.get<IService>(`${this.SERVER}/services/${id}`, filters));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({
      serviceList: UtilityClass.updateOrPushItems<IService>(getState().serviceList, response.entity!, 'id'),
      serviceSelectedId: id,
    });
  }

  /* SERVICE */

  /* MEASUREMENT UNIT */
  @Action(CatalogActions.GetMeasurementUnitList)
  async GetMeasurementUnitList(
    {patchState}: StateContext<ICatalogState>,
    {filters}: CatalogActions.GetMeasurementUnitList,
  ): Promise<void> {
    const response: DataBaseServiceResponse<IMeasurementUnit[]> = await firstValueFrom(this.baseService.get<IMeasurementUnit[]>(`${this.SERVER}/measurement-units`, filters));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({
      measurementUnitList: response.entity ?? [],
    });
  }

  @Action(CatalogActions.GetMeasurementUnitCount)
  async GetMeasurementUnitCount(
    {patchState}: StateContext<ICatalogState>,
    {filters}: CatalogActions.GetMeasurementUnitCount,
  ): Promise<void> {
    const response: DataBaseServiceResponse<number> = await firstValueFrom(this.baseService.count<IMeasurementUnit>(`${this.SERVER}/measurement-units/count`, filters));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({measurementUnitCount: response.entity ?? 0});
  }

  @Action(CatalogActions.GetMeasurementUnitById)
  async GetMeasurementUnitById(
    {patchState, getState}: StateContext<ICatalogState>,
    {id, filters}: CatalogActions.GetMeasurementUnitById,
  ): Promise<void> {
    const response: DataBaseServiceResponse<IMeasurementUnit> = await firstValueFrom(this.baseService.get<IMeasurementUnit>(`${this.SERVER}/measurement-units/${id}`, filters));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({
      measurementUnitList: UtilityClass.updateOrPushItems<IMeasurementUnit>(getState().measurementUnitList, response.entity!, 'id'),
      measurementUnitSelectedId: id,
    });
  }

  /* MEASUREMENT UNIT */

  /* CURRENCY REDUCERS */
  @Action(CatalogActions.GetCurrencyList)
  async GetCurrencyList(
    {patchState}: StateContext<ICatalogState>,
    {filters}: CatalogActions.GetCurrencyList,
  ): Promise<void> {
    const response: DataBaseServiceResponse<ICurrency[]> = await firstValueFrom(this.baseService.get<ICurrency[]>(`${this.SERVER}/currencies`, filters));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({
      currencyList: response.entity ?? [],
    });
  }

  @Action(CatalogActions.GetCurrencyCount)
  async GetCurrencyCount(
    {patchState}: StateContext<ICatalogState>,
    {filters}: CatalogActions.GetCurrencyCount,
  ): Promise<void> {
    const response: DataBaseServiceResponse<number> = await firstValueFrom(this.baseService.count<ICurrency>(`${this.SERVER}/currencies/count`, filters));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({currencyCount: response.entity ?? 0});
  }

  @Action(CatalogActions.GetCurrencyById)
  async GetCurrencyById(
    {patchState, getState}: StateContext<ICatalogState>,
    {id, filters}: CatalogActions.GetCurrencyById,
  ): Promise<void> {
    const response: DataBaseServiceResponse<ICurrency> = await firstValueFrom(this.baseService.get<ICurrency>(`${this.SERVER}/currencies/${id}`, filters));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({
      currencyList: UtilityClass.updateOrPushItems<ICurrency>(getState().currencyList, response.entity!, 'id'),
      currencySelectedId: id,
    });
  }

  /* CURRENCY REDUCERS */

  /* CERTIFICATION CATEGORY */
  @Action(CatalogActions.GetCertificationCategoryList)
  async GetCertificationCategoryList(
    {patchState}: StateContext<ICatalogState>,
    {filters}: CatalogActions.GetCertificationCategoryList,
  ): Promise<void> {
    const response: DataBaseServiceResponse<ICertificationCategory[]> = await firstValueFrom(this.baseService.get<ICertificationCategory[]>(`${this.SERVER}/certification-categories`, filters));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({
      certificationCategoryList: response.entity ?? [],
    });
  }

  @Action(CatalogActions.GetCertificationCategoryCount)
  async GetCertificationCategoryCount(
    {patchState}: StateContext<ICatalogState>,
    {filters}: CatalogActions.GetCertificationCategoryCount,
  ): Promise<void> {
    const response: DataBaseServiceResponse<number> = await firstValueFrom(this.baseService.count<ICertificationCategory>(`${this.SERVER}/certification-categories/count`, filters))
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({certificationCategoryCount: response.entity ?? 0});
  }

  @Action(CatalogActions.GetCertificationCategoryById)
  async GetCertificationCategoryById(
    {patchState, getState}: StateContext<ICatalogState>,
    {id, filters}: CatalogActions.GetCertificationCategoryById,
  ): Promise<void> {
    const response: DataBaseServiceResponse<ICertificationCategory> = await firstValueFrom(this.baseService.get<ICertificationCategory>(`${this.SERVER}/certification-categories/${id}`, filters));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({
      certificationCategoryList: UtilityClass.updateOrPushItems<ICertificationCategory>(getState().certificationCategoryList, response.entity!, 'id'),
      certificationCategorySelectedId: id,
    });
  }

  /* CERTIFICATION CATEGORY */

  /* CERTIFICATION */
  @Action(CatalogActions.GetCertificationList)
  async GetCertificationList(
    {patchState}: StateContext<ICatalogState>,
    {filters}: CatalogActions.GetCertificationList,
  ): Promise<void> {
    const response: DataBaseServiceResponse<ICertification[]> = await firstValueFrom(this.baseService.get<ICertification[]>(`${this.SERVER}/certifications`, filters));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({
      certificationList: response.entity ?? [],
    });
  }


  @Action(CatalogActions.GetCertificationCount)
  async GetCertificationCount(
    {patchState}: StateContext<ICatalogState>,
    {filters}: CatalogActions.GetCertificationCount,
  ): Promise<void> {
    const response: DataBaseServiceResponse<number> = await firstValueFrom(this.baseService.count<ICertification>(`${this.SERVER}/certifications/count`, filters));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({certificationCount: response.entity ?? 0});
  }

  @Action(CatalogActions.GetCertificationById)
  async GetCertificationById(
    {patchState, getState}: StateContext<ICatalogState>,
    {id, filters}: CatalogActions.GetCertificationById,
  ): Promise<void> {
    const response: DataBaseServiceResponse<ICertification> = await firstValueFrom(this.baseService.get<ICertification>(`${this.SERVER}/certifications/${id}`, filters));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({
      certificationList: UtilityClass.updateOrPushItems<ICertification>(getState().certificationList, response.entity!, 'id'),
      certificationSelectedId: id,
    });
  }

  /* CERTIFICATION */

  /* JOB TITLE */
  @Action(CatalogActions.GetJobTitleList)
  async GetJobTitleList(
    {patchState, getState}: StateContext<ICatalogState>,
    {filters}: CatalogActions.GetJobTitleList,
  ): Promise<void> {
    if (getState().jobTitleList.length > 0) return;
    const response: DataBaseServiceResponse<IJobTitle[]> = await firstValueFrom(this.baseService.get<IJobTitle[]>(`${this.SERVER}/job-titles`, filters));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({jobTitleList: response.entity ?? []});
  }

  @Action(CatalogActions.GetJobTitleById)
  async GetJobTitleById(
    {patchState, getState}: StateContext<ICatalogState>,
    {id, filters}: CatalogActions.GetJobTitleById,
  ): Promise<void> {
    const response: DataBaseServiceResponse<IJobTitle> = await firstValueFrom(this.baseService.get<IJobTitle>(`${this.SERVER}/job-titles/${id}`, filters));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({
      jobTitleList: UtilityClass.updateOrPushItems<IJobTitle>(getState().jobTitleList, response.entity!, 'id'),
      jobTitleSelectedId: id,
    });
  }

  @Action(CatalogActions.GetJobTitleCount)
  async GetJobTitleCount(
    {patchState}: StateContext<ICatalogState>,
    {filters}: CatalogActions.GetJobTitleCount,
  ): Promise<void> {
    const response: DataBaseServiceResponse<number> = await firstValueFrom(this.baseService.count<IJobTitle>(`${this.SERVER}/job-titles/count`, filters));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({jobTitleCount: response.entity ?? 0});
  }

  /* JOB TITLE */

  /* SECTOR */
  @Action(CatalogActions.GetSectorList)
  async GetSectorList(
    {patchState}: StateContext<ICatalogState>,
    {filters}: CatalogActions.GetSectorList,
  ): Promise<void> {
    const response: DataBaseServiceResponse<ISector[]> = await firstValueFrom(this.baseService.get<ISector[]>(`${this.SERVER}/sectors`, filters));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse); //'Error al obtener los sectores'

    patchState({
      sectorList: response.entity ?? [],
    });
  }

  @Action(CatalogActions.GetSectorCount)
  async GetSectorCount(
    {patchState}: StateContext<ICatalogState>,
    {filters}: CatalogActions.GetSectorCount,
  ): Promise<void> {
    const response: DataBaseServiceResponse<number> = await firstValueFrom(this.baseService.count<ISector>(`${this.SERVER}/sectors/count`, filters));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({sectorCount: response.entity ?? 0});
  }

  @Action(CatalogActions.GetSectorById)
  async GetSectorById(
    {patchState, getState}: StateContext<ICatalogState>,
    {id, filters}: CatalogActions.GetSectorById,
  ): Promise<void> {
    const response: DataBaseServiceResponse<ISector> = await firstValueFrom(this.baseService.get<ISector>(`${this.SERVER}/sectors/${id}`, filters));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({
      sectorList: UtilityClass.updateOrPushItems<ISector>(getState().sectorList, response.entity!, 'id'),
      sectorSelectedId: id,
    });
  }

  /* SECTOR */

  /* SUB SECTOR */
  @Action(CatalogActions.GetSubSectorList)
  async GetSubSectorList(
    {patchState}: StateContext<ICatalogState>,
    {sectorId, filters}: CatalogActions.GetSubSectorList,
  ): Promise<void> {
    const response: DataBaseServiceResponse<ISubSector[]> = await firstValueFrom(this.baseService.get<ISubSector[]>(`${this.SERVER}/sectors/${sectorId}/subsectors`));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse) // Error al obtener los sub sectores;

    patchState({
      subSectorList: response.entity ?? [],
    });
  }

  @Action(CatalogActions.GetSubSectorCount)
  async GetSubSectorCount(
    {patchState}: StateContext<ICatalogState>,
    {sectorId, filters}: CatalogActions.GetSubSectorCount,
  ): Promise<void> {
    const response: DataBaseServiceResponse<number> = await firstValueFrom(this.baseService.count<ISector>(`${this.SERVER}/sectors/${sectorId}/sub-sectors/count`, filters));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({subSectorCount: response.entity ?? 0});
  }

  @Action(CatalogActions.GetSubSectorById)
  async GetSubSectorById(
    {patchState, getState}: StateContext<ICatalogState>,
    {sectorId, id, filters}: CatalogActions.GetSubSectorById,
  ): Promise<void> {
    const response: DataBaseServiceResponse<ISubSector> = await firstValueFrom(this.baseService.get<ISubSector>(`${this.SERVER}/sectors/${sectorId}/sub-sectors/${id}`, filters));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({
      subSectorList: UtilityClass.updateOrPushItems<ISubSector>(getState().subSectorList, response.entity!, 'id'),
      subSectorSelectedId: id,
    });
  }

  /* SUB SECTOR */

  /* COUNTRY */
  @Action(CatalogActions.GetCountryList)
  async GetCountryList(
    {patchState, getState}: StateContext<ICatalogState>,
    {filters, isAddedTo}: CatalogActions.GetCountryList,
  ): Promise<void> {
    const response: DataBaseServiceResponse<ICountry[]> = await firstValueFrom(this.baseService.get<ICountry[]>(`${this.SERVER}/countries`, filters));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);
    const countries: ICountry[] = isAddedTo ? [...getState().countryList].concat(response.entity ?? []) : response.entity ?? [];
    patchState({
      countryList: [...new Map(countries.map((obj: ICountry) => [obj.id, obj])).values()],
    });
  }

  @Action(CatalogActions.AddCountryToList)
  async AddCountryToList(
    {patchState, getState}: StateContext<ICatalogState>,
    {country}: CatalogActions.AddCountryToList,
  ): Promise<void> {

    patchState({
      countryList: UtilityClass.updateOrPushItems<ICountry>(getState().countryList, country, 'id'),
    });
  }

  /* COUNTRY */

  /* STATE */
  @Action(CatalogActions.GetStateList)
  async GetStateList(
    {patchState}: StateContext<ICatalogState>,
    {filters}: CatalogActions.GetStateList,
  ): Promise<void> {
    const response: DataBaseServiceResponse<IState[]> = await firstValueFrom(this.baseService.get<IState[]>(`${this.SERVER}/states`, filters));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({
      stateList: response.entity ?? [],
    });
  }

  @Action(CatalogActions.GetStateById)
  async getStateById(
    {patchState, getState}: StateContext<ICatalogState>,
    {id, filters}: CatalogActions.GetSectorById,
  ): Promise<void> {
    const response: DataBaseServiceResponse<IState> = await firstValueFrom(this.baseService.get<IState>(`${this.SERVER}/states/${id}`, filters));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({
      stateList: UtilityClass.updateOrPushItems<IState>(getState().stateList, response.entity!, 'id'),
    });
  }
  /* STATE */

  /* CITY */
  @Action(CatalogActions.GetCityList)
  async GetCityList(
    {patchState, getState}: StateContext<ICatalogState>,
    {filters, isAddedTo, _cityStoreUID}: CatalogActions.GetCityList,
  ): Promise<void> {

    const response: DataBaseServiceResponse<ICity[]> = await firstValueFrom(this.baseService.get<ICity[]>(`${this.SERVER}/cities`, filters));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    if (_cityStoreUID) {
      const cityStore: ICityStore[] = [...getState().cityStore]
      const cityStoreIndex: number = cityStore.findIndex(({cityStoreUID}: ICityStore): boolean => cityStoreUID === _cityStoreUID);
      if (cityStoreIndex === -1) {
        cityStore.push({
          cityStoreUID: _cityStoreUID,
          cityList: response.entity!,
           therAreMoreCities: !(response.entity && response.entity.length < PLATFORM_CONFIG.PAGINATOR.ITEMS_PER_SCROLL),
        })
      } else {
        const cityList: ICity[] = isAddedTo ? [... cityStore[cityStoreIndex].cityList]!.concat(response.entity ?? []) : response.entity ?? [];
        cityStore[cityStoreIndex].cityList =  [...new Map(cityList.map((obj: ICity) => [obj.id, obj])).values()];
        cityStore[cityStoreIndex].therAreMoreCities = !(response.entity && response.entity.length < PLATFORM_CONFIG.PAGINATOR.ITEMS_PER_SCROLL);
      }

      patchState({
        cityStore
      });
      return;
    }

    const cityList: ICity[] = isAddedTo ? [...getState().cityList]!.concat(response.entity ?? []) : response.entity ?? [];

    patchState({
      cityList: [...new Map(cityList.map((obj: ICity) => [obj.id, obj])).values()],
    });
  }

  @Action(CatalogActions.GetCityById)
  async GetCityById(
    {patchState, getState}: StateContext<ICatalogState>,
    {id, filters, _cityStoreUID}: CatalogActions.GetCityById,
  ): Promise<void> {
    const response: DataBaseServiceResponse<ICity> = await firstValueFrom(this.baseService.get<ICity>(`${this.SERVER}/cities/${id}`, filters));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    if (_cityStoreUID) {
      const cityStore = [...getState().cityStore]
      const cityStoreIndex: number = cityStore.findIndex(({cityStoreUID}: ICityStore): boolean => cityStoreUID === _cityStoreUID);
      if (cityStoreIndex === -1) {
        cityStore.push({
          cityStoreUID: _cityStoreUID,
          cityList: [response.entity!],
        })
      } else {
        cityStore[cityStoreIndex].cityList = UtilityClass.updateOrPushItems<ICity>(cityStore[cityStoreIndex].cityList, response.entity!, 'id')
      }

      patchState({
        cityStore
      });
      return;
    }
    patchState({
      cityList: UtilityClass.updateOrPushItems<ICity>(getState().cityList, response.entity!, 'id'),
    });
  }

  /* CITY */

  /* PRODUCT CATEGORY REDUCERS */

  @Action(CatalogActions.GetProductCategoryList)
  async GetProductCategoryList(
    {patchState}: StateContext<ICatalogState>,
    {filters}: CatalogActions.GetProductCategoryList,
  ): Promise<void> {
    const response: DataBaseServiceResponse<IProductCategory[]> = await firstValueFrom(this.baseService.get<IProductCategory[]>(`${this.SERVER}/products/categories`, filters));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);
    patchState({
      productCategoryList: response.entity!,
    });
  }

  @Action(CatalogActions.GetProductSubcategoryWithRelationsList)
  async GetProductSubcategoryWithRelationsList(
    {patchState, getState}: StateContext<ICatalogState>,
    {search, take, skip, concat}: CatalogActions.GetProductSubcategoryWithRelationsList
  ): Promise<void> {
    const response: DataBaseServiceResponse<IProductSubcategoryWithRelations[]> = await firstValueFrom(this.baseService.get<IProductSubcategoryWithRelations[]>(`${this.SERVER}/products/subcategories/search?search=${search}&skip=${skip}&take=${take}`));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);
    patchState({
      productSubcategoryWithRelationsList: !concat ? response.entity! : [...getState().productSubcategoryWithRelationsList, ...response.entity!],
      thereAreMoreSubcategories: !(response.entity && response.entity.length < PLATFORM_CONFIG.PAGINATOR.ITEMS_PER_SCROLL)
    });
  }

  @Action(CatalogActions.GetProductCategoryById)
  async GetProductCategoryById(
    {patchState, getState}: StateContext<ICatalogState>,
    {id}: CatalogActions.GetProductCategoryById,
  ): Promise<void> {
    const response: DataBaseServiceResponse<IProductCategory> = await firstValueFrom(this.baseService.get<IProductCategory>(`${this.SERVER}/products/categories/${id}`));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);
    patchState({
      productCategoryList: UtilityClass.updateOrPushItems<IProductCategory>(getState().productCategoryList, response.entity!, 'id'),
      productCategorySelectedId: id,
    });
  }

  @Action(CatalogActions.CountProductCategory)
  async CountProductCategory({patchState}: StateContext<ICatalogState>, {filters}: CatalogActions.CountProductCategory): Promise<void> {
    const response: DataBaseServiceResponse<number> = await firstValueFrom(this.baseService.count<IProductCategory>(`${this.SERVER}/products/categories/count`, filters));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);
    patchState({productCategoryCount: response.entity ?? 0});
  }

  /* PRODUCT CATEGORY REDUCERS */

  /* PRODUCT SUBCATEGORY REDUCERS */

  @Action(CatalogActions.GetProductSubcategoryList)
  async GetProductSubcategoryList(
    {patchState, getState}: StateContext<ICatalogState>,
    {filters, categoryId, concat}: CatalogActions.GetProductSubcategoryList,
  ): Promise<void> {
    const response: DataBaseServiceResponse<IProductSubcategory[]> = await firstValueFrom(this.baseService.get<IProductSubcategory[]>(`${this.SERVER}/products/categories/${categoryId}/subcategories`, filters));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);
    patchState({
      productSubcategoryList: !concat ? response.entity! : [...getState().productSubcategoryList, ...response.entity!],
    });
  }

  @Action(CatalogActions.RemoveLocalProductSubcategoryByCategoryId)
  async removeLocalProductSubcategoryByCategoryId(
    {patchState, getState}: StateContext<ICatalogState>,
    {categoryId}: CatalogActions.RemoveLocalProductSubcategoryByCategoryId
  ): Promise<void> {
    patchState({
      productSubcategoryList: getState().productSubcategoryList.filter(({productCategoryId}: IProductSubcategory): boolean => productCategoryId !== categoryId),
    });
  }

  @Action(CatalogActions.RemoveLocalServiceSubcategoryByCategoryId)
  async removeLocalServiceSubcategoryByCategoryId(
    {patchState, getState}: StateContext<ICatalogState>,
    {categoryId}: CatalogActions.RemoveLocalServiceSubcategoryByCategoryId
  ): Promise<void> {
    patchState({
      serviceSubcategoryList: getState().serviceSubcategoryList.filter(({serviceCategoryId}: IServiceSubcategory): boolean => serviceCategoryId !== categoryId),
    });
  }

  @Action(CatalogActions.GetProductSubcategoryById)
  async GetProductSubcategoryById(
    {patchState, getState}: StateContext<ICatalogState>,
    {id, categoryId}: CatalogActions.GetProductSubcategoryById,
  ): Promise<void> {
    const response: DataBaseServiceResponse<IProductSubcategory> = await firstValueFrom(this.baseService.get<IProductSubcategory>(`${this.SERVER}/products/categories/${categoryId}/subcategories/${id}`));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);
    patchState({
      productSubcategoryList: UtilityClass.updateOrPushItems<IProductSubcategory>(getState().productSubcategoryList, response.entity!, 'id'),
      productSubcategorySelectedId: id,
    });
  }

  @Action(CatalogActions.CountProductSubcategory)
  async CountProductSubcategory(
    {patchState}: StateContext<ICatalogState>,
    {filters, categoryId}: CatalogActions.CountProductSubcategory,
  ): Promise<void> {
    const response: DataBaseServiceResponse<number> = await firstValueFrom(this.baseService.count<IProductSubcategory>(`${this.SERVER}/products/categories/${categoryId}/subcategories/count`, filters));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);
    patchState({productSubcategoryCount: response.entity ?? 0});
  }

  /* PRODUCT SUBCATEGORY REDUCERS */

  /* SERVICE CATEGORY REDUCERS */

  @Action(CatalogActions.GetServiceCategoryList)
  async getServiceCategoryList(
    {patchState}: StateContext<ICatalogState>,
    {filters}: CatalogActions.GetServiceCategoryList,
  ): Promise<void> {
    const response: DataBaseServiceResponse<IServiceCategory[]> = await firstValueFrom(this.baseService.get<IServiceCategory[]>(`${this.SERVER}/services/categories`, filters));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);
    patchState({
      serviceCategoryList: response.entity!,
    });
  }

  @Action(CatalogActions.GetServiceCategoryById)
  async getServiceCategoryById(
    {patchState, getState}: StateContext<ICatalogState>,
    {id}: CatalogActions.GetServiceCategoryById,
  ): Promise<void> {
    const response: DataBaseServiceResponse<IServiceCategory> = await firstValueFrom(this.baseService.get<IServiceCategory>(`${this.SERVER}/services/categories/${id}`));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);
    patchState({
      serviceCategoryList: UtilityClass.updateOrPushItems<IServiceCategory>(getState().serviceCategoryList, response.entity!, 'id'),
      serviceCategorySelectedId: id,
    });
  }

  @Action(CatalogActions.CountServiceCategory)
  async countServiceCategory({patchState}: StateContext<ICatalogState>, {filters}: CatalogActions.CountServiceCategory): Promise<void> {
    const response: DataBaseServiceResponse<number> = await firstValueFrom(this.baseService.count<IServiceCategory>(`${this.SERVER}/services/categories/count`, filters));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);
    patchState({serviceCategoryCount: response.entity ?? 0});
  }

  /* SERVICE CATEGORY REDUCERS */

  /* SERVICE SUBCATEGORY REDUCERS */

  @Action(CatalogActions.GetServiceSubcategoryList)
  async getServiceSubcategoryList(
    {patchState, getState}: StateContext<ICatalogState>,
    {filters, categoryId, concat}: CatalogActions.GetServiceSubcategoryList,
  ): Promise<void> {
    const response: DataBaseServiceResponse<IServiceSubcategory[]> = await firstValueFrom(this.baseService.get<IServiceSubcategory[]>(`${this.SERVER}/services/categories/${categoryId}/subcategories`, filters));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);
    patchState({
      serviceSubcategoryList: !concat ? response.entity! : [...getState().serviceSubcategoryList, ...response.entity!],
    });
  }

  @Action(CatalogActions.GetServiceSubcategoryById)
  async getServiceSubcategoryById(
    {patchState, getState}: StateContext<ICatalogState>,
    {id, categoryId}: CatalogActions.GetServiceSubcategoryById,
  ): Promise<void> {
    const response: DataBaseServiceResponse<IServiceSubcategory> = await firstValueFrom(this.baseService.get<IServiceSubcategory>(`${this.SERVER}/services/categories/${categoryId}/subcategories/${id}`));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);
    patchState({
      serviceSubcategoryList: UtilityClass.updateOrPushItems<IServiceSubcategory>(getState().serviceSubcategoryList, response.entity!, 'id'),
      serviceSubcategorySelectedId: id,
    });
  }

  @Action(CatalogActions.CountServiceSubcategory)
  async countServiceSubcategory(
    {patchState}: StateContext<ICatalogState>,
    {filters, categoryId}: CatalogActions.CountProductSubcategory,
  ): Promise<void> {
    const response: DataBaseServiceResponse<number> = await firstValueFrom(this.baseService.count<IProductSubcategory>(`${this.SERVER}/services/categories/${categoryId}/subcategories/count`, filters));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);
    patchState({serviceSubcategoryCount: response.entity ?? 0});
  }

  /* SERVICE SUBCATEGORY REDUCERS */

  /* COMPANY SIZE REDUCERS */
  @Action(CatalogActions.GetCompanySizeList)
  async GetCompanySizeList(
    {patchState}: StateContext<ICatalogState>,
    {filters}: CatalogActions.GetCompanySizeList
  ): Promise<void> {
    const response: DataBaseServiceResponse<ICompanySize[]> = await firstValueFrom(this.baseService.get<ICompanySize[]>(`${this.SERVER}/company-sizes`, filters));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);
    patchState({
      companySizeList: response.entity ?? [],
    });
  }

  @Action(CatalogActions.GetCompanySizeCount)
  async GetCompanySizeCount(
    {patchState}: StateContext<ICatalogState>,
    {filters}: CatalogActions.GetCompanySizeCount
  ): Promise<void> {
    const response: DataBaseServiceResponse<number> = await firstValueFrom(this.baseService.count<ICompanySize>(`${this.SERVER}/company-sizes/count`, filters));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({companySizeCount: response.entity ?? 0});
  }

  @Action(CatalogActions.GetCompanySizeById)
  async GetCompanySizeById(
    {patchState, getState}: StateContext<ICatalogState>,
    {id, filters}: CatalogActions.GetCompanySizeById
  ): Promise<void> {
    const response: DataBaseServiceResponse<ICompanySize> = await firstValueFrom(this.baseService.get<ICompanySize>(`${this.SERVER}/company-sizes/${id}`, filters));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({
      companySizeList: UtilityClass.updateOrPushItems<ICompanySize>(getState().companySizeList, response.entity!, 'id'),
      companySizeSelectedId: id,
    });
  }

  @Action(CatalogActions.Reset)
  reset({setState}: StateContext<ICatalogState>): void {
    setState(_DEFAULT_DATA);
  }
}
