export enum EventGuestRoleEnum {
  SPONSOR = 'SPONSOR',
  MAIN_SPEAKER = 'MAIN_SPEAKER',
  PARTNER = 'PARTNER',
  EXHIBITOR = 'EXHIBITOR',
  PANELIST = 'PANELIST',
  VIP_GUEST = 'VIP_GUEST',
  BUYER = 'BUYER',
  SUPPLIER = 'SUPPLIER',
}
