import {Pipe, PipeTransform} from "@angular/core"
import {differenceInYears as _differenceInYears} from "date-fns"
import {ICurrency} from '@platform/catalog/interfaces';

@Pipe({
  name: 'getCurrencyName',
  standalone: true,
})

export class GetCurrencyNamePipe implements PipeTransform {
  transform(currencyId: string, currencies: ICurrency[]): string {
    return currencies.find((currency: ICurrency): boolean => currency.id === currencyId)?.shortName ?? 'MXN';
  }
}
