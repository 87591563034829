import {Injectable} from '@angular/core';
import {StorageService} from '@shared/services';
import {firstValueFrom} from 'rxjs';
import {Store} from '@ngxs/store';
import {OrganizationActions, OrganizationState} from '@organization/state';
import {
  IOrganizationProfile,
  IOrganizationSupplierNumberCreateDto,
  IOrganizationSupplierNumberUpdateDto,
  IOrganizationSupplierNumberWithRelations,
  IOrganizationWithRelations,
} from '@organization/interfaces';
import {ExceptionHandler, Loader} from '@shared/decorators';
import {PLATFORM_MESSAGES} from '@shared/config';
import {NzMessageService} from 'ng-zorro-antd/message';
import {IStorageOrganization} from '@shared/services/storage/interfaces';
import {InternalClientException, NotFoundException} from '@shared/class/errors';
import {OrganizationTypeEnum, OrganizationValidationStatusEnum} from '@organization/enums';
import {IMedia} from '@shared/interfaces';
import {ImageDescriptionEnum} from '@shared/enums';

@Injectable()
export class OrganizationService {
  constructor(
    private storageService: StorageService,
    private store: Store,
    private nzMessageService: NzMessageService
  ) {}

  isSupplier(organization?: IOrganizationWithRelations | IStorageOrganization | null): boolean {
    if (!organization) return false;
    return [OrganizationTypeEnum.SUPPLIER, OrganizationTypeEnum.BOTH].includes(organization.organizationType);
  }
  isBuyer(organization?: IOrganizationWithRelations | IStorageOrganization | null): boolean {
    if (!organization) return false;

    return [OrganizationTypeEnum.BUYER, OrganizationTypeEnum.BOTH].includes(organization.organizationType);
  }

  async userBelongsToAnyOrganization(): Promise<boolean> {
    try {
      await firstValueFrom(this.store.dispatch(new OrganizationActions.UserBelongsTo()));
      const organization: IStorageOrganization | null = this.storageService.getOrganization();
      return !!organization;
    } catch (e) {
      throw e;
    }
  }

  async isValid(): Promise<void> {
    const organization: IStorageOrganization | null = this.storageService.getOrganization();
    if (!organization) throw new NotFoundException(PLATFORM_MESSAGES.ORGANIZATION.NOT_FOUND);

    // Rejected
    if (
      [
        OrganizationValidationStatusEnum.REJECTED_BY_THE_SYSTEM,
        OrganizationValidationStatusEnum.MANUALLY_REJECTED
      ].includes(organization.validationStatus as OrganizationValidationStatusEnum)
    ) {
      throw new InternalClientException(PLATFORM_MESSAGES.ORGANIZATION.BLOCKED);
    }

    // Pending
    if (organization.validationStatus === OrganizationValidationStatusEnum.TO_BE_VALIDATED) {
      throw new InternalClientException(PLATFORM_MESSAGES.ORGANIZATION.VALIDATION_PERIOD);
    }

    // Accepted
  }

  async accountCheck(): Promise<string[]> {
    const organization: IStorageOrganization | null = this.storageService.getOrganization();
    if (!organization) throw new NotFoundException(PLATFORM_MESSAGES.ORGANIZATION.NOT_FOUND);
    await firstValueFrom(this.store.dispatch(new OrganizationActions.AccountCheck(organization.id)));
    return await firstValueFrom(this.store.select(OrganizationState.getMissingData));
  }

  /**
   * check if the organization has not been deleted
   */
  isActiveOrganization(): boolean {
    const organization: IStorageOrganization | null = this.storageService.getOrganization();
    if (!organization) return false;
    return !organization.deletedAt;
  }

  @Loader()
  @ExceptionHandler()
  async uploadOrganizationLogo(file: File): Promise<void> {
    const organization: IOrganizationWithRelations | undefined = await firstValueFrom(this.store.selectOnce(OrganizationState.getOrganizationSelected));
    if (!organization) return;
    await firstValueFrom(this.store.dispatch(new OrganizationActions.UploadLogo(organization.id!, file)));
    this.nzMessageService.success(PLATFORM_MESSAGES.ORGANIZATION.LOGO_IMAGE_UPDATED);
  }

  @Loader()
  @ExceptionHandler()
  async uploadOrganizationBanner(file: File): Promise<void> {
    const organization: IOrganizationWithRelations | undefined = await firstValueFrom(this.store.selectOnce(OrganizationState.getOrganizationSelected));
    if (!organization) return;
    await firstValueFrom(this.store.dispatch(new OrganizationActions.UploadBanner(organization.id!, file)));
    this.nzMessageService.success(PLATFORM_MESSAGES.ORGANIZATION.BANNER_IMAGE_UPDATED);
  }

  async getById(id: string): Promise<IOrganizationWithRelations> {
    const organization: IOrganizationWithRelations | undefined = this.store.selectSnapshot(OrganizationState.getOrganizationSelected);
    if (organization) return organization;

    await firstValueFrom(this.store.dispatch(new OrganizationActions.GetById(id)));
    return this.store.selectSnapshot(OrganizationState.getOrganizationSelected)!;
  }

  convertOrganizationSupplierNumberToCreate(value: IOrganizationSupplierNumberWithRelations): IOrganizationSupplierNumberCreateDto {
    return {
      organizationId: value.organizationId,
      number: value.number,
      yearOfEnrollment: value.yearOfEnrollment,
      stateId: value.stateId,
      expirationAt: value.expirationAt
    };
  }

  convertOrganizationSupplierNumberToUpdate(value: IOrganizationSupplierNumberWithRelations): IOrganizationSupplierNumberUpdateDto {
    return {
      id: value.id!,
      stateId: value.stateId,
      number: value.number,
      yearOfEnrollment: value.yearOfEnrollment,
      expirationAt: value.expirationAt
    };
  }

  convertToOrganizationProfileDto(organization: IOrganizationWithRelations | IOrganizationProfile): IOrganizationProfile {
    return {
      id: organization.id!,
      tradeName: organization.tradeName,
      legalName: organization.legalName,
      shortDescription: organization.shortDescription!,
      webPortal: organization.webPortal,
      logoUrl: organization.logoUrl ?? organization.Media?.find((m: IMedia): boolean => m.description === ImageDescriptionEnum.ORGANIZATION_LOGO)?.filePath ?? '',
      bannerUrl: organization['bannerUrl'] ?? organization.Media?.find((m: IMedia): boolean => m.description === ImageDescriptionEnum.ORGANIZATION_BANNER)?.filePath ?? '',
      OrganizationCertification: organization.OrganizationCertification ?? [],
      rfc: organization.rfc,
      organizationType: organization.organizationType,
      sectorName: organization.sectorName ?? organization.SubSector?.Sector?.name ?? '',
      subSectorName: organization.subSectorName ?? organization.SubSector?.name ?? '',
      validationStatus: organization.validationStatus,
      isDeleted: organization.isDeleted,
    }
  }
}
