import {Action, Selector, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {LoaderActions} from '@loader/state/loader-actions.state';

export interface ILoaderState {
  active: boolean;
}

@State<ILoaderState>({
  name: 'LoaderState',
  defaults: {
    active: false,
  }
})
@Injectable()
export class LoaderState {
  constructor() { }

  @Selector()
  static active({active}: ILoaderState): boolean {
    return active;
  }


  @Action(LoaderActions.Show)
  async show({patchState}: StateContext<ILoaderState>): Promise<void> {
    patchState({active: true});
  }

  @Action(LoaderActions.Hide)
  async hide({patchState}: StateContext<ILoaderState>): Promise<void> {
    patchState({active: false});
  }

}
