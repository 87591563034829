import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Observable, Observer, of, RetryConfig} from 'rxjs';
import {catchError, retry} from 'rxjs/operators';
import {DataBaseServiceResponse} from '@shared/services/base/interfaces/data-base-service-response.interface';
import {PrismaCountFilter, PrismaFilter} from '@shared/services/base/interfaces/prisma-filter.interface';
import {IAnyObject} from '@shared/interfaces/any-object.interface';
import {HttpOptions} from '@shared/services/base/interfaces/http-options.interface';
import {RemoveArray} from '@shared/services/base/types/prisma-helper.type';
import {RETRY_STRATEGY_CONST} from '@shared/services/base/const/retry-strategy.const';
import {isObject as _isObject} from 'lodash';
import {omit as _omit} from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class BaseService {

  constructor(private httpClient: HttpClient) {
  }

  private stringifyFilters<T>(filter?: PrismaFilter<T>, params?: IAnyObject): IAnyObject<string> {
    const stringifyFilters: IAnyObject = {};

    if (filter) {
      for (const [key, value] of Object.entries(filter ?? {})) {
        stringifyFilters[key] = _isObject(value) ? JSON.stringify(value) : value;
      }
    }

    if (params) {
      for (const [key, value] of Object.entries(params ?? {})) {
        stringifyFilters[key] = _isObject(value) ? JSON.stringify(value) : value;
      }
    }

    return stringifyFilters;
  }

  private buildHttpOptions<T>(
    httpOptions?: Omit<HttpOptions, 'observe'>,
    filters?: PrismaFilter<RemoveArray<T>>,
    authorization?: string,
  ): HttpOptions {
    const options: HttpOptions = httpOptions ?? {};
    // Filters
    options.params = this.stringifyFilters<RemoveArray<T>>(filters, httpOptions?.params);

    // Observe
    options.observe = 'body';

    // Authorization
    if (authorization !== void 0 && authorization.length > 0) {
      options.headers = new HttpHeaders({
        ...httpOptions?.headers ?? {},
        'Content-Type': 'application/json',
        'Authorization': authorization,
      });
    }
    return options;
  }

  private responseStructure<T>(response: T | HttpErrorResponse): DataBaseServiceResponse<T> {
    const serverResponse: IAnyObject<any> = ((response ?? {}) as IAnyObject<any>) ;

    // Custom errors
    if (response instanceof Error) {
      return {
        error: true,
        code: 400,
        message: response.message,
        entity: null,
        serverResponse: {
          ...response,
          status: serverResponse['status'] || serverResponse['httpCode'] || serverResponse['code'] || serverResponse['statusCode'],
        },
      };
    }

    // Server errors
    if (response instanceof TypeError) {
      return {
        error: true,
        code: 500,
        message: response.message,
        entity: null,
        serverResponse: {
          ...response,
          status: serverResponse['status'] || serverResponse['httpCode'] || serverResponse['code'] || serverResponse['statusCode'],
        },
      };
    }

    if (response instanceof HttpErrorResponse) {
      return {
        error: true,
        code: response.status,
        message: response.error.message ?? response.error,
        entity: null,
        serverResponse: {
          ...response,
          status: serverResponse['status'] || serverResponse['httpCode'] || serverResponse['code'] || serverResponse['statusCode'],
        },
      }
    }

    return {
      error: false,
      code: 200,
      message: 'ok',
      serverResponse: response,
      entity: response,
    };
  }

  get<T>(
    path: string,
    filters?: PrismaFilter<RemoveArray<T>>,
    httpOptions?: Omit<HttpOptions, 'observe'>,
    authorization?: string,
    retryStrategy: RetryConfig = RETRY_STRATEGY_CONST,
  ): Observable<DataBaseServiceResponse<T>> {
    return new Observable((observer: Observer<DataBaseServiceResponse<T>>): void => {
      const options: HttpOptions = this.buildHttpOptions(httpOptions, filters, authorization);

      this.httpClient
        .get(path, options)
        .pipe(retry(retryStrategy), catchError((error) => of(error)))
        .subscribe(async (response: T | HttpErrorResponse): Promise<void> => {
          observer.next(this.responseStructure<T>(response));
          observer.complete();
        });
    });
  }

  download(
    path: string,
    mimeType: string = 'application/octet-stream',
    authorization?: string,
    retryStrategy: RetryConfig = RETRY_STRATEGY_CONST,
  ): Observable<DataBaseServiceResponse<Blob>> {
    return new Observable((observer: Observer<DataBaseServiceResponse<Blob>>): void => {
      this.httpClient
        .get(path, {
          observe: 'body',
          responseType: 'blob',
          headers: new HttpHeaders({
            Authorization: `Bearer ${authorization}`,
            'Content-Type': mimeType,
            'Accept': mimeType,
          }),
        })
        .pipe(retry(retryStrategy), catchError((error) => of(error)))
        .subscribe(async (response: Blob | HttpErrorResponse): Promise<void> => {
          observer.next(this.responseStructure<Blob>(response));
          observer.complete();
        });
    });
  }

  count<T>(
    path: string,
    filters?: PrismaCountFilter<RemoveArray<T>>,
    httpOptions?: Omit<HttpOptions, 'observe'>,
    authorization?: string,
    retryStrategy: RetryConfig = RETRY_STRATEGY_CONST,
  ): Observable<DataBaseServiceResponse<number>> {
    return new Observable((observer: Observer<DataBaseServiceResponse<number>>): void => {
      const options: HttpOptions = this.buildHttpOptions(httpOptions, _omit(filters, ['take', 'skip', 'include']), authorization);

      this.httpClient
        .get(path, options)
        .pipe(retry(retryStrategy), catchError((error) => of(error)))
        .subscribe(async (response: number | HttpErrorResponse): Promise<void> => {
          observer.next(this.responseStructure<number>(response));
          observer.complete();
        });
    });
  }

  post<T>(
    path: string,
    entity: any,
    filters?: PrismaFilter<RemoveArray<T>>,
    httpOptions?: Omit<HttpOptions, 'observe'>,
    authorization?: string,
    retryStrategy: RetryConfig = RETRY_STRATEGY_CONST,
  ): Observable<DataBaseServiceResponse<T>> {
    return new Observable(
      (observer: Observer<DataBaseServiceResponse<T>>): void => {
        const options: HttpOptions = this.buildHttpOptions(httpOptions, filters, authorization);

        this.httpClient
          .post(path, entity, options)
          .pipe(retry(retryStrategy), catchError((error) => of(error)))
          .subscribe(async (response: T | HttpErrorResponse): Promise<void> => {
            observer.next(this.responseStructure(response));
            observer.complete();
          });
      }
    );
  }

  put<T>(
    path: string,
    entity: any,
    filters?: PrismaFilter<RemoveArray<T>>,
    httpOptions?: Omit<HttpOptions, 'observe'>,
    authorization?: string,
    retryStrategy: RetryConfig = RETRY_STRATEGY_CONST,
  ): Observable<DataBaseServiceResponse<T>> {
    return new Observable(
      (observer: Observer<DataBaseServiceResponse<T>>): void => {
        const options: HttpOptions = this.buildHttpOptions(httpOptions, filters, authorization);

        this.httpClient
          .put(path, entity, options)
          .pipe(retry(retryStrategy), catchError((error) => of(error)))
          .subscribe((response: T | HttpErrorResponse) => {
            observer.next(this.responseStructure(response));
            observer.complete();
          });
      }
    );
  }

  patch<T>(
    path: string,
    entity: any,
    filters?: PrismaFilter<RemoveArray<T>>,
    httpOptions?: Omit<HttpOptions, 'observe'>,
    authorization?: string,
    retryStrategy: RetryConfig = RETRY_STRATEGY_CONST,
  ): Observable<DataBaseServiceResponse<T>> {
    return new Observable(
      (observer: Observer<DataBaseServiceResponse<T>>): void => {
        const options: HttpOptions = this.buildHttpOptions(httpOptions, filters, authorization);

        this.httpClient
          .patch(path, entity, options)
          .pipe(retry(retryStrategy), catchError((error) => of(error)))
          .subscribe((response: T | HttpErrorResponse): void => {
            observer.next(this.responseStructure(response));
            observer.complete();
          });
      }
    );
  }

  delete<T>(
    path: string,
    filters?: PrismaFilter<RemoveArray<T>>,
    httpOptions?: Omit<HttpOptions, 'observe'>,
    authorization?: string,
    retryStrategy: RetryConfig = RETRY_STRATEGY_CONST,
  ): Observable<DataBaseServiceResponse<T>> {
    return new Observable((observer: Observer<DataBaseServiceResponse<T>>): void => {
        const options: HttpOptions = this.buildHttpOptions(httpOptions, filters, authorization);

        this.httpClient
          .delete(path, options)
          .pipe(retry(retryStrategy), catchError((error) => of(error)))
          .subscribe((response): void => {
            observer.next(this.responseStructure(response));
            observer.complete();
          });
      }
    );
  }

}
