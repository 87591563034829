import {EventEmitter, Injectable, signal, WritableSignal} from '@angular/core';

export enum BreakPointEnum {
  'XS' = 'XS',
  'SM' = 'SM',
  'MD' = 'MD',
  'LG' = 'LG',
  'XL' = 'XL',
  '2XL' = '2XL'
}

@Injectable({
  providedIn: 'root',
})
export class ResponsiveService {
  private interval: any = null;
  private timer: number = 300;

  size: WritableSignal<number>;
  breakPoint: WritableSignal<BreakPointEnum>;

  size$: EventEmitter<number> = new EventEmitter<number>();
  breakPoint$: EventEmitter<BreakPointEnum> = new EventEmitter<BreakPointEnum>();

  constructor() {
    this.size = signal(window.innerWidth);
    this.breakPoint = signal(this.getBreakPoint(window.innerWidth));
    this.onResizeListener();
  }

  setTimer(timer: number): void {
    this.timer = timer;
  }

  private onResizeListener(): void {
    window.addEventListener('resize', (e: Event): void => {
      if (this.interval) clearInterval(this.interval);

      this.interval = setTimeout((): void => {
        const size: number = (e.target as Window).innerWidth;
        const breakPoint: BreakPointEnum = this.getBreakPoint((e.target as Window).innerWidth);

        this.size.set(size);
        this.breakPoint.set(breakPoint);

        this.size$.emit(size);
        this.breakPoint$.emit(breakPoint);
      }, this.timer);
    });
  }

  private getBreakPoint(size: number): BreakPointEnum {
    switch (true) {
      case size < 640: return BreakPointEnum.XS;
      case size < 768: return BreakPointEnum.SM;
      case size < 1024: return BreakPointEnum.MD;
      case size < 1248: return BreakPointEnum.LG;
      case size < 1536: return BreakPointEnum.XL;
      default: return BreakPointEnum['2XL'];
    }
  }
}
