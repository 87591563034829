export enum TagColorEnum {
  BLUE = 'blue',
  GREEN = 'green',
  RED = 'red',
  PURPLE = 'purple',
  ORANGE = 'orange',
  ROSE = 'rose',
  WZ1 = 'wz1',
  WZ2 = 'wz2',
  WZ3 = 'wz3',
  WZ4 = 'wz4',
}
