import {Pipe, PipeTransform} from '@angular/core';
import {IEventDateGroup, IEventDateTime} from '@event/interfaces';
import {GetTimeGroupFormat} from '@event/components/event-dates/event-dates.component';

export interface IEventDateGroupTimes {
  date: string | Date;
  allDay: boolean;
  times: string[];
}

@Pipe({
  name: 'eventDateGroup',
  standalone: true,
})

export class EventDateGroupPipe implements PipeTransform {
  constructor(private getTimeGroupFormat: GetTimeGroupFormat) {}

  transform(value: IEventDateGroup[]): IEventDateGroupTimes[] {
    return value.map((group: IEventDateGroup): IEventDateGroupTimes => {
      return {
        date: group.startDate,
        allDay: group.allDay,
        times: group.timeGroup.map((time : IEventDateTime) => this.getTimeGroupFormat.transform(time))
      }
    });
  }
}
