import {Pipe, PipeTransform} from "@angular/core"
import {IOpportunityWithRelations} from '@opportunity/interfaces';
import {OpportunityStatusEnum} from '@opportunity/enums';

@Pipe({
  name: 'isOpportunityComplete',
  standalone: true,
})

export class IsOpportunityCompletePipe implements PipeTransform {
  transform(opportunity?: IOpportunityWithRelations): boolean {
    if (!opportunity || !opportunity.id) return true;

    return [
      OpportunityStatusEnum.COMPLETED_BY_USER,
      OpportunityStatusEnum.COMPLETED_SUCCESSFUL,
      OpportunityStatusEnum.COMPLETED_NOT_FOUND
    ].includes(opportunity.status!) || !!opportunity.OpportunityResolution;
  }
}
