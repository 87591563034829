import {Pipe, PipeTransform} from '@angular/core';
@Pipe({
  name: 'getHtmlText',
  standalone: true,
})
export class GetHtmlTextPipe implements PipeTransform {
  transform(htmlString: string, options?: {maxLength?: number, maxNodes?: number}): string  {
    let div: HTMLDivElement = document.createElement('div');
    let divToReturn: HTMLDivElement = document.createElement('div');
    div.innerHTML = htmlString;
    let children: any = div.querySelectorAll('*:not(img, video)');
    let nodesCount: number = 0;
    let charsCount: number = 0;
    for(let node of children) {
      if (options && options.maxLength && charsCount >= options.maxLength) break;
      if (options && options.maxNodes && nodesCount >= options.maxNodes) break;
      if(node.innerText) node.innerText += ' ';
      divToReturn.appendChild(node);
      charsCount += node.innerText.length;
      nodesCount += 1;
    }
    return [divToReturn.innerText].toString().replace(/ +/g,' ');
  }
}
