import {Store} from '@ngxs/store';
import {LoaderActions} from '@loader/state/loader-actions.state';
import {firstValueFrom} from 'rxjs';

export function Loader(timeout: number = 0) {
  return function(target: any, propertyKey: string, propertyDescriptor: PropertyDescriptor): void {
    const method = propertyDescriptor.value;

    propertyDescriptor.value = async function (...args: any[]): Promise<void> {

      const store: Store = ((this as any)['store'] as Store);
      if (!store) {
        console.warn('Inject NGXS as store');
      }

      if (store) {
        await firstValueFrom(store.dispatch(new LoaderActions.Show()));
      }

      try {
        await method.apply(this, args);
      } catch (ex: any) {
        throw ex;
      } finally {
        if (store) {
          setTimeout(async (): Promise<void> => {
            await firstValueFrom(store.dispatch(new LoaderActions.Hide()));
          }, timeout);
        }
      }
    }
  }
}
