import {HttpRequest, HttpResponse} from '@angular/common/http';

export class CachedRequest {
  url = '';
  request: HttpRequest<any> | undefined;
  response: HttpResponse<any> | undefined;
  responseTime: number;
  constructor(req: HttpRequest<any>, res: HttpResponse<any>, responseTime: number) {
    this.url = req.urlWithParams;
    this.request = req;
    this.response = res;
    this.responseTime = responseTime;
  }
}
