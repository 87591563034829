import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from "@sentry/angular-ivy";
import { AppModule } from './app/app.module';

Sentry.init({
  dsn: "https://120bf5fb4d02b5afd640cffa82b67bbe@o4506661099667456.ingest.sentry.io/4506661109301248",
  integrations: [
    Sentry.replayIntegration({maskAllText: true, blockAllMedia: false}),
    Sentry.browserTracingIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0,
  // Session Replay
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  tracePropagationTargets: ["localhost", /^https:\/\/(bi-stg\.plataformabi\.wizerg\.dev|server\.bizgto\.com)/],
});

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
