import {ErrorException} from '@shared/class/errors/error.exception';
import {ErrorTypeEnum} from '@shared/class/errors/enums/error-type.enum';

export class InternalServerException extends ErrorException {
  constructor(message: string = 'Ups, ha ocurrido un error. Vuelve a intentarlo mas tarde.') {
    super(message);
    this.name = ErrorTypeEnum.INTERNAL_SERVER;
    this.internalCode = 'SERVER-001';
  }
}
