import {Injectable} from '@angular/core';
import {BaseService} from '@shared/services/base/base.service';
import {environment} from '@env/environment';
import {firstValueFrom} from 'rxjs';
import {DataBaseServiceResponse} from '@shared/services/base/interfaces/data-base-service-response.interface';
import {BadRequestException} from '@shared/class/errors/bad-request.exception';
import {PLATFORM_MESSAGES} from '@shared/config';
import {NzUploadFile} from 'ng-zorro-antd/upload';
import {FileExtensionsEnum} from '@shared/components/ui-elements/upload/enums/file-extensions.enum';

@Injectable({
  providedIn: 'root',
})
export class UploadService {
  protected readonly SERVER: string = environment.SERVER;
  constructor(
    private baseService: BaseService,
  ) {}

  async upload<T>(url: string, file: File): Promise<DataBaseServiceResponse<T>> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    const response: DataBaseServiceResponse<any> = await firstValueFrom(this.baseService.post(`${this.SERVER}/${url}`, formData));
    if (response.error) {
      throw new BadRequestException(PLATFORM_MESSAGES.GENERAL.TRY_LATER);
    }
    return response;
  }

  async uploadRecordDocument<T>(url: string, file: File, documentTemplateId: string, organizationId: string, userId?: string): Promise<DataBaseServiceResponse<T>> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('organizationId', organizationId);
    formData.append('documentTemplateId', documentTemplateId);
    if (userId) {
      formData.append('userId', userId);
    }
    const response: DataBaseServiceResponse<any> = await firstValueFrom(this.baseService.post(`${this.SERVER}/${url}`, formData));
    if (response.error) {
      throw new BadRequestException(PLATFORM_MESSAGES.GENERAL.TRY_LATER);
    }
    return response;
  }

  /**
   * Build accept property
   */
  buildAccept(extensions: FileExtensionsEnum[]): string {
    if (extensions.length === 0) return '';
    return extensions.map((extension: FileExtensionsEnum): string => `.${extension},`).join('');
  }

  /**
   *
   * @param file
   * @param extensions
   * @private
   */
  validateExtension(file: File, extensions: FileExtensionsEnum[]): boolean {
    if (extensions.length === 0) return true;
    return !!extensions.find((extension: FileExtensionsEnum) => file.name?.includes(extension.toLowerCase()));
  }

  /**
   * Validates the maximum size of the file in MB
   * @param file
   * @param maxFileSizeMb
   * @private
   */
  validateMaxFileSizeMb(file: File, maxFileSizeMb: number): boolean {
    return (file.size! / 1024 / 1024) < maxFileSizeMb;
  }

  /**
   * Validate limit files
   * @param fileList
   * @param limit
   */
  validateLimitFiles(fileList: NzUploadFile[], limit: number): boolean {
    if (!limit) return true;
    return fileList.length < limit;
  }

  /**
   * Check if the file is an image
   * @param type
   */
  fileIsImage(type: string): boolean {
    return !![
      FileExtensionsEnum.JPG,
      FileExtensionsEnum.PNG,
      FileExtensionsEnum.JPEG,
    ].find((extension: FileExtensionsEnum) => {
      return (type?.split('/') ?? [])[1].includes(extension.toLowerCase());
    });

  }

  /**
   * Convert file to base 64
   * @param img
   * @private
   */
  getBase64(img: File): Promise<string> {
    return new Promise((resolve: (result: string) => void): void => {
      const reader: FileReader = new FileReader();
      reader.addEventListener('load', () => resolve(reader.result!.toString()));
      reader.readAsDataURL(img);
    });
  }
}
