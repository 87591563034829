import {NzDropDownModule} from 'ng-zorro-antd/dropdown';
import {Component, OnInit} from "@angular/core";
import {RouterModule} from "@angular/router";
import {PageHandler} from '@shared/interfaces/handlers/page-handler.interface';
import {Select, Store} from '@ngxs/store';
import {firstValueFrom, Observable} from 'rxjs';
import {AuthActions} from '@auth/state/auth.state';
import {WzLoaderComponent} from '@shared/components/ui-elements/loader/wz-loader.component';
import {MainMenuComponent} from '@shared/components/ui-elements/menu/main-menu.component';
import {SidebarContentComponent} from '@shared/components/ui-content/sidebar-content/sidebar-content.component';
import {SidebarComponent} from '@shared/components/ui-elements/sidebar/sidebar.component';
import {OrganizationActions, OrganizationState} from '@organization/state';
import {MenuService} from '@menu/services/menu.service';
import {MenuActions} from '@menu/state/menu.state';
import {MessagesModule} from '@shared/components/ui-elements/messages/messages.module';
import {OrganizationTypeEnum, OrganizationValidationStatusEnum} from '@organization/enums';
import {StorageService} from '@shared/services';
import {StorageItemEnum} from '@shared/services/storage/enums/storage-item.enum';
import {WZChatBot} from '@shared/components/ui-content/chatbot/chatbot.component';
import {IStorageOrganization} from '@shared/services/storage/interfaces';
import {CommonModule} from '@angular/common';
import {AccountCheckModule} from '@shared/components/ui-elements/account-check/account-check.module';

@Component({
  selector: 'dashboard-template',
  templateUrl: './dashboard.template.html',
  styleUrls: ['./dashboard.template.scss'],
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    NzDropDownModule,
    WzLoaderComponent,
    MainMenuComponent,
    SidebarContentComponent,
    SidebarComponent,
    MessagesModule,
    WZChatBot,
    AccountCheckModule
  ],
})
export class DashboardTemplate implements OnInit, PageHandler {
  @Select(OrganizationState.getOrganizationType) organizationType$!: Observable<OrganizationTypeEnum>;
  organization?: IStorageOrganization | null;
  constructor(
    private menuService: MenuService,
    private store: Store,
    private storageService: StorageService
  ) { }

  ngOnInit(): void {
    this.init();
    this.request().then(() => {
      this.listeners();
    });
  }

  listeners(): void {
    this.organization = this.storageService.getOrganization();
  }

  init(): void {}

  async request(): Promise<void> {
    await firstValueFrom(this.store.dispatch(new AuthActions.SetStorage()));
    this.storageService.setOrganizationType();
    const currentType: OrganizationTypeEnum | null = this.storageService.get<OrganizationTypeEnum>(StorageItemEnum.ORGANIZATION_TYPE);
    await firstValueFrom(this.store.dispatch(new OrganizationActions.SetOrganizationType(currentType!)));
    await firstValueFrom(this.store.dispatch(new MenuActions.Set(
      this.menuService.buildDefaultMainMenu(),
      this.menuService.buildDefaultSidebarMenu(),
    )));
  }

  protected readonly OrganizationValidationStatusEnum = OrganizationValidationStatusEnum;
  protected readonly OrganizationTypeEnum = OrganizationTypeEnum;
}
