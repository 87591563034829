import {IOrganizationAddressWithRelations} from "@organization/interfaces/organization-address.interface";
import {IAddress, IPhoneNumber} from "@shared/interfaces";
import {Injectable} from '@angular/core';
import {Action, Selector, State, StateContext, Store} from '@ngxs/store';
import {
  IOrganization,
  IOrganizationCreateUpdateDto, IOrganizationPhoneNumber, IOrganizationPhoneNumberWithRelations,
  IOrganizationProfile,
  IOrganizationSupplierNumberWithRelations,
  IOrganizationWithRelations,
} from '@organization/interfaces';
import {BaseService, StorageService, UploadService} from '@shared/services';
import {ErrorHandlerService} from '@shared/services/error-handler.service';
import {DataBaseServiceResponse} from '@shared/services/base/interfaces/data-base-service-response.interface';
import {firstValueFrom} from 'rxjs';
import {environment} from '@env/environment';
import {PrismaCountFilter, PrismaFilter} from '@shared/services/base/interfaces/prisma-filter.interface';
import {UtilityClass} from '@shared/class/utils/utility.class';
import {Router} from '@angular/router';
import {AddressBuilder} from '@shared/components/ui-elements/information/addresses/address-builder.util';
import {OrganizationTypeEnum} from '@organization/enums';
import {OrganizationSupplierNumberActions} from '@organization/state/organization-supplier-number.state';
import {omit as _omit} from 'lodash';
import {StorageItemEnum} from '@shared/services/storage/enums/storage-item.enum';
import {NzMessageService} from 'ng-zorro-antd/message';
import {PhoneBuilder} from '@shared/components/ui-elements/information/phones/phone-builder.util';
import {IUserProfilePhoneNumber} from '@user/interfaces';
import {IUserState, UserActions} from '@user/state';

export interface IOrganizationState {
  list: IOrganizationWithRelations[];
  selectedId: string;
  count: number;

  profileList: IOrganizationProfile[];
  profileCount: number;
  profileSelectedId: string;

  organizationType: OrganizationTypeEnum | null;

  supplierNumbers: IOrganizationSupplierNumberWithRelations[];
  supplierNumberSelectedId: string;
  rfcIsAvailable: boolean,
  missingData: string[],

}

const _DEFAULT_DATA: IOrganizationState = {
  list: [],
  selectedId: '',
  count: 0,

  profileList: [],
  profileCount: 0,
  profileSelectedId: '',

  organizationType: null,
  supplierNumbers: [],
  supplierNumberSelectedId: '',
  rfcIsAvailable: true,
  missingData: [],
}

export namespace OrganizationActions {
  export class GetProfiles {
    static readonly type: string = '[Organization] Get organization list';
    constructor(public filter?: PrismaFilter<IOrganization>, public concat: boolean = false) {}
  }

  export class GetProfileById {
    static readonly type: string = '[Organization] Get Organization Profile';
    constructor(public id: string, public filter?: PrismaFilter<IOrganizationWithRelations>) {}
  }

  export class GetProfilesCount {
    static readonly type: string = '[Organization] Get Profile Count';
    constructor(public filters?: PrismaCountFilter<IOrganization>) {
    }
  }

  export class GetSupplierList {
    static readonly type: string = '[Organization] Get organization supplier list';
    constructor(public filter?: PrismaFilter<IOrganization>) {}
  }

  export class GetSupplierCount {
    static readonly type: string = '[Organization] Supplier count';
    constructor(public filters?: PrismaCountFilter<IOrganization>) {
    }
  }

  export class GetBuyerList {
    static readonly type: string = '[Organization] Get buyer list';
    constructor(public filter?: PrismaFilter<IOrganization>) {}
  }

  export class GetBuyerCount {
    static readonly type: string = '[Organization] Buyer count';
    constructor(public filters?: PrismaCountFilter<IOrganization>) {
    }
  }


  export class GetById {
    static readonly type: string = '[Organization] Get by id';
    constructor(public id: string, public filter?: PrismaFilter<IOrganizationWithRelations>) {}
  }

  export class Create {
    static readonly type: string = '[Organization] Create organization';
    constructor(public organization: IOrganizationCreateUpdateDto) {}
  }

  export class Update {
    static readonly type: string = '[Organization] Update organization';
    constructor(public id: string, public organization: Partial<IOrganization>, public withGovernmentAgency: boolean = false) {}
  }

  export class DeletePatent {
    static readonly type: string = '[Organization] Delete Patent organization';
    constructor(public _id: string, public organization: Partial<IOrganization>) {}
  }

  export class Delete {
    static readonly type: string = '[Organization] Delete organization';
    constructor(public id: string) {}
  }

  export class Reset {
    static readonly type: string = '[Organization] Reset';
  }

  export class PostAddress {
    static readonly type: string = '[Organization] Post address';
    constructor(public organizationId: string, public address: IAddress,  public addressBuilder: AddressBuilder) {}
  }

  export class PatchAddress {
    static readonly type: string = '[Organization] Patch address';
    constructor(public organizationId: string, public address: IAddress, public addressId: string) {}
  }

  export class DeleteAddress {
    static readonly type: string = '[Organization] Delete address';
    constructor(public organizationId: string, public addressId: string, public addressBuilder: AddressBuilder) {}
  }

  export class UserBelongsTo {
    static readonly type: string = '[Organization] User Belongs To';
    constructor() {}
  }

  export class AcceptTermsAndConditions {
    static readonly type: string = '[Organization] Accept Terms And Conditions';
    constructor(public organizationId: string) {}
  }

  export class UploadLogo {
    static readonly type: string = '[Organization] Upload Logo';
    constructor(public id: string, public file: any) {
    }
  }

  export class UploadBanner {
    static readonly type: string = '[Organization] Upload Banner';
    constructor(public id: string, public file: any) {
    }
  }

  export class SetOrganizationType {
    static readonly type: string = '[Organization] Set Organization Type';
    constructor(public organizationType: OrganizationTypeEnum) {
    }
  }

  export class ConvertToBothType {
    static readonly type: string = '[Organization] Convert To Buyer Supplier Type';
    constructor(public id: string) {
    }
  }

  export class GetOrganizationRFCExists {
    static readonly type: string = '[Organization] Get Organization RFC Exists';
    constructor(public rfc: string, public organizationId?: string) {
    }
  }

  export class AccountCheck {
    static readonly type: string = '[Organization] Account Check';
    constructor(public organizationId?: string) {
    }
  }

  export class PostPhone {
    static readonly type: string = '[Organization] Post Phone';

    constructor(public organizationId: string, public phone: IPhoneNumber, public phoneBuilder: PhoneBuilder) {
    }
  }

  export class PatchPhone {
    static readonly type: string = '[Organization] Patch Phone';

    constructor(public organizationId: string, public phone: IPhoneNumber, public phoneBuilder: PhoneBuilder) {
    }
  }

  export class DeletePhone {
    static readonly type: string = '[Organization] Delete phone';
    constructor(public organizationId: string, public phoneId: string, public phoneBuilder: PhoneBuilder) {
    }
  }
}

@State<IOrganizationState>({
  name: 'OrganizationState',
  defaults: _DEFAULT_DATA
})
@Injectable()
export class OrganizationState {
  private readonly SERVER: string = environment.SERVER;
  constructor(
    private baseService: BaseService,
    private errorHandlerService: ErrorHandlerService,
    private router: Router,
    private store: Store,
    private storageService: StorageService,
    private uploadService: UploadService,
    private nzMessageService: NzMessageService
  ) {
  }

  @Selector()
  static getOrganizationList(state: IOrganizationState): IOrganization[] {
    return state.list;
  }

  @Selector()
  static getOrganizationSelectedId(state: IOrganizationState): string {
    return state.selectedId;
  }

  @Selector()
  static getOrganizationSelected({list, selectedId}: IOrganizationState): IOrganizationWithRelations | undefined {
    return list.find((organization: IOrganization): boolean => organization.id === selectedId);
  }

  @Selector()
  static getOrganizationProfileSelected({list, selectedId}: IOrganizationState): any {
    return list.find((organization: IOrganization): boolean => organization.id === selectedId);
  }

  @Selector()
  static getCount({count}: IOrganizationState): number {
    return count;
  }

  @Selector()
  static getOrganizationType({organizationType}: IOrganizationState): OrganizationTypeEnum | null {
    return organizationType;
  }

  @Selector()
  static getRFCIsAvailable({rfcIsAvailable}: IOrganizationState): boolean {
    return rfcIsAvailable;
  }

  @Selector()
  static getMissingData({missingData}: IOrganizationState): string[] {
    return missingData;
  }

  @Action(OrganizationActions.GetProfiles)
  async getProfiles({patchState, getState}: StateContext<IOrganizationState>, actions: OrganizationActions.GetProfiles): Promise<void> {
    const response: DataBaseServiceResponse<IOrganizationProfile[]> = await firstValueFrom(this.baseService.get<IOrganizationProfile[]>(`${this.SERVER}/organizations/profiles`, actions.filter));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    const profileList: IOrganizationProfile[] = actions.concat ? getState().profileList.concat(response.entity ?? []) : response.entity!;
    patchState({ profileList });
  }

  @Action(OrganizationActions.GetProfileById)
  async getProfileById({patchState, getState}: StateContext<IOrganizationState>, {id, filter}: OrganizationActions.GetProfileById): Promise<void> {
    const response: DataBaseServiceResponse<any> = await firstValueFrom(this.baseService.get<any>(`${this.SERVER}/organizations/${id}/profile`, filter));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({
      profileList: [response.entity],
      profileSelectedId: id
    });
  }

  @Action(OrganizationActions.GetProfilesCount)
  async countProfilesCount(
    {patchState}: StateContext<IOrganizationState>,
    {filters}: OrganizationActions.GetProfilesCount
  ): Promise<void> {
    const response: DataBaseServiceResponse<number> = await firstValueFrom(this.baseService.count<IOrganization>(`${this.SERVER}/organizations/profiles/count`, filters));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({profileCount: response.entity ?? 0});
  }

  @Action(OrganizationActions.GetSupplierList)
  async getSupplierList({patchState}: StateContext<IOrganizationState>, actions: OrganizationActions.GetSupplierList): Promise<void> {
    const response: DataBaseServiceResponse<IOrganizationWithRelations[]> = await firstValueFrom(this.baseService.get<IOrganizationWithRelations[]>(`${this.SERVER}/organizations/suppliers`, actions.filter));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);
    patchState({
      list: response.entity ?? []
    });
  }

  @Action(OrganizationActions.GetSupplierCount)
  async supplierCount(
    {patchState}: StateContext<IOrganizationState>,
    {filters}: OrganizationActions.GetSupplierCount
  ): Promise<void> {
    const response: DataBaseServiceResponse<number> = await firstValueFrom(this.baseService.count<IOrganizationWithRelations>(`${this.SERVER}/organizations/suppliers/count`, filters));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({count: response.entity ?? 0});
  }

  @Action(OrganizationActions.GetBuyerList)
  async getBuyerList({patchState}: StateContext<IOrganizationState>, actions: OrganizationActions.GetBuyerList): Promise<void> {
    const response: DataBaseServiceResponse<IOrganizationWithRelations[]> = await firstValueFrom(this.baseService.get<IOrganizationWithRelations[]>(`${this.SERVER}/organizations/buyers`, actions.filter));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);
    patchState({
      list: response.entity ?? []
    });
  }

  @Action(OrganizationActions.GetBuyerCount)
  async buyerCount(
    {patchState}: StateContext<IOrganizationState>,
    {filters}: OrganizationActions.GetBuyerCount
  ): Promise<void> {
    const response: DataBaseServiceResponse<number> = await firstValueFrom(this.baseService.count<IOrganizationWithRelations>(`${this.SERVER}/organizations/buyers/count`, filters));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({count: response.entity ?? 0});
  }

  @Action(OrganizationActions.GetById)
  async getById({patchState, getState}: StateContext<IOrganizationState>, {id, filter}: OrganizationActions.GetById): Promise<void> {
    const response: DataBaseServiceResponse<IOrganizationWithRelations> = await firstValueFrom(this.baseService.get(`${this.SERVER}/organizations/${id}`, filter));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);
    patchState({
      list: UtilityClass.updateOrPushItems<IOrganizationWithRelations>(getState().list, response.entity!, 'id'),
      selectedId: id,
    });
    this.storageService.setOrganizationType();
    await firstValueFrom(this.store.dispatch(new OrganizationSupplierNumberActions.SetList(response.entity!.SupplierNumber!)));
  }

  @Action(OrganizationActions.Create)
  async create({patchState, getState}: StateContext<IOrganizationState>, {organization}: OrganizationActions.Create): Promise<void> {
    const response: DataBaseServiceResponse<IOrganization> = await firstValueFrom(this.baseService.post(`${this.SERVER}/organizations`, organization));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);
    patchState({
      list: UtilityClass.updateOrPushItems<IOrganization>(getState().list, response.entity!, 'id'),
      selectedId: response.entity?.id
    });
  }

  @Action(OrganizationActions.Update)
  async update({patchState, getState}: StateContext<IOrganizationState>, {id, organization, withGovernmentAgency}: OrganizationActions.Update): Promise<void> {
    let response: DataBaseServiceResponse<IOrganization>;
    if (withGovernmentAgency) {
      response = await firstValueFrom(this.baseService.patch(`${this.SERVER}/organizations/government-agencies/${id}`, organization));
    } else {
      response = await firstValueFrom(this.baseService.patch(`${this.SERVER}/organizations/${id}`, organization));
    }

    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({
      selectedId: id
    });
  }

  @Action(OrganizationActions.DeletePatent)
  async deletePatent({patchState, getState}: StateContext<IOrganizationState>, {_id, organization}: OrganizationActions.DeletePatent): Promise<void> {
    let response: DataBaseServiceResponse<IOrganization> = await firstValueFrom(this.baseService.patch(`${this.SERVER}/organizations/${_id}`, organization));

    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    const organizationFoundIndex: number = getState().list.findIndex(({id}) => id === _id);
    if (organizationFoundIndex === -1) return;
    const list: IOrganizationWithRelations[] = [...getState().list];
    list[organizationFoundIndex].registrationsAndPatents = organization.registrationsAndPatents;
    patchState({
      list,
      selectedId: _id
    });
  }

  @Action(OrganizationActions.Delete)
  async delete(_: StateContext<IOrganizationState>, {id}: OrganizationActions.Delete): Promise<void> {
    const response: DataBaseServiceResponse<IOrganization> = await firstValueFrom(this.baseService.delete(`${this.SERVER}/organizations/${id}`));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);
  }

  @Action(OrganizationActions.Reset)
  reset({setState}: StateContext<IOrganizationState>): void {
    setState(_DEFAULT_DATA);
  }

  @Action(OrganizationActions.PostAddress)
  async postAddress(
    _: StateContext<IOrganizationState>,
    {organizationId, address, addressBuilder}: OrganizationActions.PostAddress
  ): Promise<void> {
    const response: DataBaseServiceResponse<IOrganizationAddressWithRelations> = await firstValueFrom(this.baseService.post<IOrganizationAddressWithRelations>(`${this.SERVER}/organizations/${organizationId}/addresses`, _omit(address, ['id'])));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);
    addressBuilder.replaceIdBy(address.id!, response.entity?.addressId ?? '');
  }

  @Action(OrganizationActions.PatchAddress)
  async patchAddress(
    _: StateContext<IOrganizationState>,
    {organizationId, address, addressId}: OrganizationActions.PatchAddress
  ): Promise<void> {
    const value = UtilityClass.deleteNullOrUndefinedProsFromObject(address);
    const response: DataBaseServiceResponse<IAddress> = await firstValueFrom(this.baseService.patch<IAddress>(`${this.SERVER}/organizations/${organizationId}/addresses/${addressId}`, value));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    // await firstValueFrom(this.store.dispatch(new AddressActions.PostPatch(address)));
  }

  @Action(OrganizationActions.DeleteAddress)
  async deleteAddress(
    _: StateContext<IOrganizationState>,
    {organizationId, addressId, addressBuilder}: OrganizationActions.DeleteAddress
  ): Promise<void> {
    const response: DataBaseServiceResponse<IAddress> = await firstValueFrom(this.baseService.delete<IAddress>(`${this.SERVER}/organizations/${organizationId}/addresses/${addressId}`));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);
    addressBuilder.deleteById(addressId);
  }

  @Action(OrganizationActions.UserBelongsTo)
  async userBelongsTo(_: StateContext<IOrganizationState>): Promise<void> {
    type belongs = {successful: boolean; user: boolean};
    const response: DataBaseServiceResponse<IOrganizationWithRelations | belongs> = await firstValueFrom(this.baseService.get<IOrganizationWithRelations | belongs>(`${this.SERVER}/organizations/belongs`));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse); // Si truena limpiar localstorage y se da por hecho que no está autenticado (mandamos a login)
    if (response.entity?.hasOwnProperty('user')) {
      if (!(response.entity as belongs).user) {
        this.storageService.remove(StorageItemEnum.CREDENTIALS);
        this.storageService.remove(StorageItemEnum.ORGANIZATION);
        return;
      }
    }
    if (response.entity?.hasOwnProperty('successful')) {
      // Si viene la propiedad successful se da por hecho que no tiene una organización (mandamos a crear organización)
      this.storageService.remove(StorageItemEnum.ORGANIZATION);
      return;
    }
    this.storageService.setOrganization(response.entity! as IOrganizationWithRelations); // Si si tiene organización le damos acceso a la plataforma
  }

  @Action(OrganizationActions.AcceptTermsAndConditions)
  async acceptTermsAndConditions(_: StateContext<IOrganizationState>, {organizationId}: OrganizationActions.AcceptTermsAndConditions): Promise<void> {
    const response: DataBaseServiceResponse<any> = await firstValueFrom(this.baseService.post<any>(`${this.SERVER}/organizations/${organizationId}/accept-terms-and-conditions`, void 0));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse, 'Error al aceptar términos y condiciones');
  }

  @Action(OrganizationActions.UploadLogo)
  async uploadLogo(_: StateContext<IOrganizationState>, {id, file}: OrganizationActions.UploadLogo): Promise<void> {
    const response: DataBaseServiceResponse<any> = await this.uploadService.upload<string>(`organizations/${id}/upload-logo`, file as File);
  }

  @Action(OrganizationActions.UploadBanner)
  async uploadBanner(_: StateContext<IOrganizationState>, {id, file}: OrganizationActions.UploadBanner): Promise<void> {
    const response: DataBaseServiceResponse<any> = await this.uploadService.upload<string>(`organizations/${id}/upload-banner`, file as File);
  }

  @Action(OrganizationActions.SetOrganizationType)
  async setOrganizationType({ patchState }: StateContext<IOrganizationState>, {organizationType}: OrganizationActions.SetOrganizationType): Promise<void> {
    patchState({
      organizationType
    });
  }

  @Action(OrganizationActions.ConvertToBothType)
  async convertToBothType({ patchState, getState }: StateContext<IOrganizationState>, {id}: OrganizationActions.ConvertToBothType): Promise<void> {
    const response: DataBaseServiceResponse<IOrganizationWithRelations> = await firstValueFrom(this.baseService.patch<IOrganizationWithRelations>(`${this.SERVER}/organizations/${id}/both-type`, void 0));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);
    patchState({
      list: UtilityClass.updateOrPushItems<IOrganization>(getState().list, response.entity!, 'id'),
      selectedId: id
    });
    this.storageService.setOrganization(response.entity!);
  }

  @Action(OrganizationActions.GetProfileById)
  async getOrganizationProfile({ patchState, getState }: StateContext<IOrganizationState>, {id}: OrganizationActions.GetProfileById): Promise<void> {
    const response: DataBaseServiceResponse<IOrganizationProfile> = await firstValueFrom(this.baseService.get<IOrganizationProfile>(`${this.SERVER}/organizations/${id}/profile`, void 0));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);
    patchState({
      list: UtilityClass.updateOrPushItems<IOrganization>(getState().list, response.entity!, 'id'),
      selectedId: id
    });
  }

  @Action(OrganizationActions.GetOrganizationRFCExists)
  async getOrganizationRFCExists({ patchState, getState }: StateContext<IOrganizationState>, {rfc, organizationId}: OrganizationActions.GetOrganizationRFCExists): Promise<void> {
    const response: DataBaseServiceResponse<{ available: boolean }> = await firstValueFrom(this.baseService.get<{ available: boolean }>(`${this.SERVER}/organizations/rfc?rfc=${rfc.toUpperCase()}${organizationId ? ('&organizationId='+organizationId) : ''}`, void 0));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);
    patchState({
      rfcIsAvailable: response.entity?.available
    });
  }

  @Action(OrganizationActions.AccountCheck)
  async accountCheck({ patchState, getState }: StateContext<IOrganizationState>, {organizationId}: OrganizationActions.AccountCheck): Promise<void> {
    const response: DataBaseServiceResponse<{ missing: string[] }> = await firstValueFrom(this.baseService.get<{ missing: string[] }>(`${this.SERVER}/organizations/${organizationId}/account-check`, void 0));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);
    patchState({
      missingData: response.entity?.missing
    });
  }

  @Action(OrganizationActions.PostPhone)
  async postProfilePhone(
    _: StateContext<IUserState>,
    {phone, organizationId, phoneBuilder}: OrganizationActions.PostPhone
  ): Promise<void> {
    const response: DataBaseServiceResponse<IOrganizationPhoneNumberWithRelations> = await firstValueFrom(this.baseService.post<IOrganizationPhoneNumberWithRelations>(`${this.SERVER}/organizations/${organizationId}/phones`, _omit(phone, ['id'])));
    if (response.error) return;
    phoneBuilder.replaceIdBy(phone.id!, response.entity?.phoneNumberId ?? '');
  }

  @Action(OrganizationActions.PatchPhone)
  async patchProfilePhone(
    _: StateContext<IUserState>,
    {phone, organizationId, phoneBuilder}: OrganizationActions.PatchPhone
  ): Promise<void> {
    const response: DataBaseServiceResponse<IPhoneNumber> = await firstValueFrom(this.baseService.patch<IPhoneNumber>(`${this.SERVER}/organizations/${organizationId}/phones/${phone.id}`, phone));
    if (!response.error) return;
  }


  @Action(OrganizationActions.DeletePhone)
  async deleteProfilePhone(
    _: StateContext<IUserState>,
    {organizationId, phoneId, phoneBuilder}: OrganizationActions.DeletePhone,
  ): Promise<void> {
    const response: DataBaseServiceResponse<IPhoneNumber> = await firstValueFrom(this.baseService.delete<IPhoneNumber>(`${this.SERVER}/organizations/${organizationId}/phones/${phoneId}`));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);
    phoneBuilder.deleteById(phoneId);
  }
}
