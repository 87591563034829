import {Component} from '@angular/core';
import {Select} from '@ngxs/store';
import {AccountCheckState} from '@shared/components/ui-elements/account-check/state/account-check.state';
import {Observable} from 'rxjs';
import {AccountCheckModalEnum} from '@shared/components/ui-elements/account-check/enums/account-check-modal.enum';

@Component({
  selector: 'account-check',
  template: `
    <address-account-check-modal [isVisible]="(modal$ | async) === AccountCheckModalEnum.ADDRESS"></address-account-check-modal>
    <!-- ... -->
  `,
})
export class AccountCheckComponent {
  protected readonly AccountCheckModalEnum: typeof AccountCheckModalEnum = AccountCheckModalEnum;
  @Select(AccountCheckState.getModal) modal$!: Observable<AccountCheckModalEnum | null>;

  constructor() { }

}
