import {Injectable} from '@angular/core';
import {Store} from '@ngxs/store';
import {AbstractControl, AsyncValidatorFn, ValidationErrors} from '@angular/forms';
import {firstValueFrom, Observable} from 'rxjs';
import {CatalogActions} from '@platform/state/catalog.state';
import {environment} from '@env/environment';
import {GOVERNMENT_AGENCY_RFC} from '@shared/config';
import {OrganizationActions, OrganizationState} from '@organization/state';

@Injectable({
  providedIn: 'root'
})
export class AsyncValidatorsService {
  constructor(private store: Store) {}

  validateRFCExists(organizationId?: string, required: boolean = true): AsyncValidatorFn {
    return async (control: AbstractControl): Promise<ValidationErrors | null> => {
      if (!control.value) return null;

      if (required) {
        const rfcNaturalRegex: RegExp = new RegExp('^[A-Z]{4}[0-9]{6}[A-Z0-9]{3}$');
        const rfcCorporateRegex: RegExp = new RegExp('^[A-Z]{3}[0-9]{6}[A-Z0-9]{3}$');
        if (!rfcNaturalRegex.test(control.value.toUpperCase()) &&
          !rfcCorporateRegex.test(control.value.toUpperCase())) {
          return { rfc: true };
        }
        if (GOVERNMENT_AGENCY_RFC === control.value.toUpperCase()) {
          return null;
        }
      }

      await firstValueFrom(this.store.dispatch(new OrganizationActions.GetOrganizationRFCExists(control.value, organizationId)));
      const response: boolean = await firstValueFrom(this.store.selectOnce(OrganizationState.getRFCIsAvailable));
      if (response) {
        return null;
      } else {
        return { rfcAlreadyExists: true };
      }
    };
  }
}
