import {NzMessageService, NzMessageType} from 'ng-zorro-antd/message';
import {GeneralException} from '@shared/class/errors';

export function ExceptionHandler(messageType: NzMessageType = 'error', exception: string[] = []) {
  return function(target: any, propertyKey: string, propertyDescriptor: PropertyDescriptor): void {
    const method = propertyDescriptor.value;

    propertyDescriptor.value = async function (...args: any[]): Promise<void> {

      const nzMessageService: NzMessageService = ((this as any)['nzMessageService'] as NzMessageService);
      if (!nzMessageService) {
        console.warn('Inject NzMessageService as nzMessageService in ', this.constructor.name, ' class.');
      }

      try {
        await method.apply(this, args);
      } catch (ex: any) {
        if (nzMessageService && !exception.find((e: string) => ex.toString().toLowerCase().includes(e.toLowerCase()))) {
          nzMessageService.create(messageType, ex ? ex.message : new GeneralException().message);
        }
      }
    }
  }
}
