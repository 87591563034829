import {Component, EventEmitter, Input, Output} from '@angular/core';
import {fadeAnimation} from '@core/animations/fade.animation';

@Component({
  selector: 'warning-message',
  template: `
    <div class="p-2 bg-[#FFF9E9] border border-[#FFC12E] flex items-center justify-between rounded text-sm text-wz-message-gray" [class.is-global]="global">
      <div class="flex items-center gap-2">
        <span nz-icon nzType="warning" nzTheme="fill" class="text-2xl flex text-[#FFC12E]"></span>
        <span>{{ this.message }}</span>
      </div>
      <i *ngIf="closable" class="las la-times text-xl text-wz-message-gray cursor-pointer" (click)="onClose()"></i>
    </div>
  `,
  styles: [``]
})
export class WarningMessageComponent {
  @Input() icon: string = 'la-info-circle';
  @Input({required: true}) message!: string;
  @Input() closable: boolean = false;
  @Input() global: boolean = false;
  @Output() closeChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {
  }

  onClose(): void {
    this.closeChange.emit(true);
  }
}
