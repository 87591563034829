import {Pipe, PipeTransform} from "@angular/core"
import {differenceInYears as _differenceInYears} from "date-fns"
import {IState} from '@platform/catalog/interfaces';
import {IOrganization, IOrganizationWithRelations} from '@organization/interfaces';
import {IOrganizationOpportunityMatchStructure} from '@opportunity/interfaces';

@Pipe({
  name: 'omitByProperty',
  standalone: true,
})

export class OmitByPropertyPipe implements PipeTransform {
  transform(values: any[], property: string, omitValues: string[]): any[] {
    return values.filter((value: any) => !omitValues.includes(value[property]!));
  }
}
