export class DocumentUtilClass {

  constructor() {}
  static download(blob: Blob, fileName: string): void {
    let link: HTMLAnchorElement = document.createElement('a');
    link.download = `${fileName.toLowerCase().replaceAll(' ', '-')}.${blob.type.split('/')[1]}`;
    link.href = window.URL.createObjectURL(blob);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    link.remove();
  }

  static createObjectURL(blob: Blob): string {
    return window.URL.createObjectURL(blob);
  }
}
