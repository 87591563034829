import {Injectable} from '@angular/core';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {
  ICreateOrUpdateCertificationList,
  IOrganization,
  IOrganizationCertification,
  IOrganizationCertificationWithRelations,
} from '@organization/interfaces';
import {BaseService} from '@shared/services';
import {ErrorHandlerService} from '@shared/services/error-handler.service';
import {DataBaseServiceResponse} from '@shared/services/base/interfaces/data-base-service-response.interface';
import {firstValueFrom} from 'rxjs';
import {environment} from '@env/environment';
import {PrismaCountFilter, PrismaFilter} from '@shared/services/base/interfaces/prisma-filter.interface';
import {UtilityClass} from '@shared/class/utils/utility.class';
import {omit as _omit} from 'lodash';

export interface IOrganizationCertificationState {
  list: IOrganizationCertificationWithRelations[];
  certificationSelectedId: string;
  certificationCount: number;
}

const _DEFAULT_DATA: IOrganizationCertificationState = {
  list: [],
  certificationSelectedId: '',
  certificationCount: 0
}

export namespace OrganizationCertificationActions {
  export class GetList {
    static readonly type: string = '[OrganizationCertification] Get Organization Certification List Action';
    constructor(public organizationId: string, public filter?: PrismaFilter<IOrganizationCertification>) {}
  }

  export class GetCount {
    static readonly type: string = '[OrganizationCertification] Get Organization Certification Count';
    constructor(public organizationId: string, public filters?: PrismaCountFilter<IOrganizationCertification>) {
    }
  }

  export class GetById {
    static readonly type: string = '[OrganizationCertification] Get Organization Certification By Id Action';
    constructor(public organizationId: string, public id: string, public filter?: PrismaFilter<IOrganizationCertificationWithRelations>) {}
  }

  export class Create {
    static readonly type: string = '[OrganizationCertification] Create Organization Certification Action';
    constructor(public organizationId: string, public certification: IOrganizationCertification) {}
  }

  export class Update {
    static readonly type: string = '[OrganizationCertification] Update Organization Certification Action';
    constructor(public organizationId: string, public id: string, public certification: Partial<IOrganizationCertification>) {}
  }

  export class Delete {
    static readonly type: string = '[OrganizationCertification] Delete Organization Certification Action';
    constructor(public organizationId: string, public id: string) {}
  }

  export class SetOrganizationCertificationId {
    static readonly type: string = '[OrganizationCertification] Set Organization Certification Id Action';
    constructor(public id: string) {}
  }

  export class Reset {
    static readonly type: string = '[OrganizationCertification] Reset';
  }

  export class PostPatch{
    static readonly type: string = '[OrganizationCertification] Post Patch';
    constructor(public organizationId: string, public certificationList: ICreateOrUpdateCertificationList) {
    }
  }

}

@State<IOrganizationCertificationState>({
  name: 'OrganizationCertificationState',
  defaults: _DEFAULT_DATA
})
@Injectable()
export class OrganizationCertificationState {
  private readonly SERVER: string = environment.SERVER;
  constructor(
    private baseService: BaseService,
    private errorHandlerService: ErrorHandlerService,
  ) {
  }

  @Selector()
  static getCertificationList(state: IOrganizationCertificationState): IOrganizationCertificationWithRelations[] {
    return state.list;
  }

  @Selector()
  static getCertificationSelectedId(state: IOrganizationCertificationState): string {
    return state.certificationSelectedId;
  }

  @Selector()
  static getCertificationSelected({list, certificationSelectedId}: IOrganizationCertificationState): IOrganizationCertificationWithRelations | undefined {
    return list.find((organization: IOrganizationCertificationWithRelations): boolean => organization.id === certificationSelectedId);
  }

  @Selector()
  static getCount({certificationCount}: IOrganizationCertificationState): number {
    return certificationCount;
  }

  @Action(OrganizationCertificationActions.GetList)
  async getList({patchState}: StateContext<IOrganizationCertificationState>, {organizationId, filter}: OrganizationCertificationActions.GetList): Promise<void> {
    const response: DataBaseServiceResponse<IOrganizationCertificationWithRelations[]> = await firstValueFrom(this.baseService.get<IOrganizationCertificationWithRelations[]>(`${this.SERVER}/organizations/${organizationId}/certifications`, filter ?? {}));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);
    patchState({
      list: response.entity ?? []
    })
  }

  @Action(OrganizationCertificationActions.GetCount)
  async GetCount(
    {patchState}: StateContext<IOrganizationCertificationState>,
    {organizationId ,filters}: OrganizationCertificationActions.GetCount
  ): Promise<void> {
    const response: DataBaseServiceResponse<number> = await firstValueFrom(this.baseService.count<IOrganizationCertification>(`${this.SERVER}/organizations/${organizationId}/certifications/count`, filters));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({certificationCount: response.entity ?? 0});
  }

  @Action(OrganizationCertificationActions.GetById)
  async getById({patchState, getState}: StateContext<IOrganizationCertificationState>, {organizationId, id, filter}: OrganizationCertificationActions.GetById): Promise<void> {
    const response: DataBaseServiceResponse<IOrganizationCertificationWithRelations> = await firstValueFrom(this.baseService.get(`${this.SERVER}/organizations/${organizationId}/certifications/${id}`, filter));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({
      list: UtilityClass.updateOrPushItems<IOrganizationCertification>(getState().list, response.entity!, 'id'),
      certificationSelectedId: id
    });
  }

  @Action(OrganizationCertificationActions.Create)
  async create({patchState, getState}: StateContext<IOrganizationCertificationState>, {organizationId, certification}: OrganizationCertificationActions.Create): Promise<void> {
    const response: DataBaseServiceResponse<IOrganizationCertification> = await firstValueFrom(this.baseService.post(`${this.SERVER}/organizations/${organizationId}/certifications`, certification));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);
    patchState({
      list: UtilityClass.updateOrPushItems<IOrganizationCertification>(getState().list, response.entity!, 'id'),
      certificationSelectedId: response.entity?.id
    });
  }

  @Action(OrganizationCertificationActions.Update)
  async update({patchState, getState}: StateContext<IOrganizationCertificationState>, {organizationId, id, certification}: OrganizationCertificationActions.Update): Promise<void> {
    const response: DataBaseServiceResponse<IOrganizationCertification> = await firstValueFrom(this.baseService.patch(`${this.SERVER}/organizations/${organizationId}/certifications/${id}`, certification));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);
    patchState({
      list: UtilityClass.updateOrPushItems<IOrganizationCertification>(getState().list, response.entity!, 'id'),
      certificationSelectedId: id
    });
  }

  @Action(OrganizationCertificationActions.Delete)
  async delete({patchState, getState}: StateContext<IOrganizationCertificationState>, {organizationId, id}: OrganizationCertificationActions.Delete): Promise<void> {
    const response: DataBaseServiceResponse<IOrganization> = await firstValueFrom(this.baseService.delete(`${this.SERVER}/organizations/${organizationId}/certifications/${id}`));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);
    patchState({list: UtilityClass.deleteItemByProp<IOrganizationCertification>(getState().list, 'id', id)});
  }

  @Action(OrganizationCertificationActions.SetOrganizationCertificationId)
  setOrganizationCertificationId({patchState}: StateContext<IOrganizationCertificationState>, {id}: OrganizationCertificationActions.SetOrganizationCertificationId): void {
    patchState({
      certificationSelectedId: id
    });
  }

  @Action(OrganizationCertificationActions.Reset)
  reset({setState}: StateContext<IOrganizationCertificationState>): void {
    setState(_DEFAULT_DATA);
  }

  @Action(OrganizationCertificationActions.PostPatch)
  async postPatch(
    {patchState, getState, dispatch}: StateContext<IOrganizationCertificationState>,
    {organizationId, certificationList}: OrganizationCertificationActions.PostPatch
  ): Promise<void> {
    const createPromise: Promise<IOrganizationCertificationWithRelations | null>[] = certificationList.create.map((certification: IOrganizationCertification) => {
      return new Promise(async (resolve: (value: IOrganizationCertificationWithRelations | null) => void): Promise<void> => {
        const data = UtilityClass.deleteNullOrUndefinedProsFromObject(certification);
        const organizationAddress: DataBaseServiceResponse<IOrganizationCertification> = await firstValueFrom(this.baseService.post<IOrganizationCertification>(`${this.SERVER}/organizations/${organizationId}/certifications`, data));
        if (organizationAddress.error) {
          return resolve(null);
        }

        resolve({
          ...organizationAddress.entity!,
        });
      });
    });

    const updatePromise: Promise<IOrganizationCertificationWithRelations | null>[] = certificationList.update.map((certification: IOrganizationCertification) => {
      return new Promise(async (resolve: (value: IOrganizationCertificationWithRelations | null) => void): Promise<void> => {
        const data = UtilityClass.deleteNullOrUndefinedProsFromObject(certification);
        const organizationAddress: DataBaseServiceResponse<IOrganizationCertification> = await firstValueFrom(this.baseService.patch<IOrganizationCertification>(`${this.SERVER}/organizations/${organizationId}/certifications/${certification.id}`, _omit(data, ['id', 'organizationId'])));
        if (organizationAddress.error) {
          return resolve(null);
        }

        resolve({
          ...organizationAddress.entity!,
        });
      });
    });
    const promises: Promise<IOrganizationCertificationWithRelations | null>[] = [...createPromise, ...updatePromise];
    if (promises.length === 0) return;

    const responses: (IOrganizationCertificationWithRelations | null)[] = await Promise.all(promises);
    const successResponses: IOrganizationCertificationWithRelations[] = responses.filter((item: IOrganizationCertificationWithRelations | null): boolean => item !== null) as IOrganizationCertificationWithRelations[];

    let list: IOrganizationCertification[] = [...getState().list];

    successResponses.forEach((certification: IOrganizationCertification, i): void => {
      list = UtilityClass.updateOrPushItems<IOrganizationCertification>(list, certification, 'id');
    });

    patchState({list});
  }

}
