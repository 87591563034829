import {Pipe, PipeTransform} from '@angular/core';
import {IMediaWithRelations} from '@shared/interfaces';
import {ImageDescriptionEnum} from '@shared/enums';
import {IEventFeaturedGuestWithRelations} from '@event/interfaces';
import {OrganizationTypeEnum} from '@organization/enums';

@Pipe({
  name: 'getOrganizationsByType',
  standalone: true,
})
export class GetOrganizationsByTypePipe implements PipeTransform {
  transform(eventsFeaturedGuest: IEventFeaturedGuestWithRelations[], type: OrganizationTypeEnum): IEventFeaturedGuestWithRelations[] {
    if (!eventsFeaturedGuest || !type) return [];
    return eventsFeaturedGuest.filter((eventFG: IEventFeaturedGuestWithRelations): boolean => eventFG.Organization?.organizationType === type);
  }
}
