import {INotification} from '../interfaces/notification.interface';
import {PrismaFilter} from '@shared/services/base/interfaces/prisma-filter.interface';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {environment} from '@env/environment';
import {BaseService} from '@shared/services';
import {ErrorHandlerService} from '@shared/services/error-handler.service';
import {DataBaseServiceResponse} from '@shared/services/base/interfaces/data-base-service-response.interface';
import {firstValueFrom} from 'rxjs';
import {IServiceState, ServiceActions} from '@service/state/service.state';

export interface INotificationState {
  list: INotification[];
  count: number;
}

const _DEFAULT_NOTIFICATION: INotificationState = {
  list: [
    {
      text: 'TeleTech quiere contactarte',
      createdAt: 'Hace 4 horas',
      link: 'www.google.com',
      status: 'noSeen'
    },
    {
      text: 'Tu producto "Zapatos de piel" ha sido publicado',
      createdAt: 'Hace 4 horas',
      link: 'www.google.com',
      status: 'seen'
    },
    {
      text: 'Has sido invitado al evento "Juntos por Gto"',
      createdAt: 'Hace 4 horas',
      link: 'www.google.com',
      status: 'seen'
    },
    {
      text: 'Tu producto "Servilletas biodegradables" ha sido publicado',
      createdAt: 'Hace 4 horas',
      link: 'www.google.com',
      status: 'noSeen'
    },
  ],
  count: 0,
}

export namespace  NotificationActions {
  export class Find {
    static readonly type: string = '[Notification] Find';
    constructor(public filters: PrismaFilter<INotification>) {}
  }

  export class Count {
    static readonly type: string = '[Notification] Count';
    constructor(public filters: PrismaFilter<INotification>) {}
  }

  export class Reset {
    static readonly type: string = '[Notification] Reset';
    constructor() {}
  }
}

@State<INotificationState>({
  name: 'NotificationState',
  defaults: _DEFAULT_NOTIFICATION
})
@Injectable()
export class NotificationState {
  private readonly  SERVER: string = environment.SERVER;

  constructor(
    private baseService: BaseService,
    private errorHandlerService: ErrorHandlerService,
  ) {}

  @Selector()
  static getNotifications({list}: INotificationState): INotification[] {
    return list;
  }

  @Selector()
  static getCount({count}: INotificationState): number {
    return count;
  }

  @Action(NotificationActions.Find)
  async find({patchState}: StateContext<INotificationState>, {filters}: NotificationActions.Find): Promise<void> {
    const response: DataBaseServiceResponse<INotification[]> = await firstValueFrom(this.baseService.get<INotification[]>(`${this.SERVER}/notifications`, filters));
    if (response.error) throw  this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({
      list: response.entity!,
    });
  }

  @Action(NotificationActions.Count)
  async count({patchState}: StateContext<INotificationState>, {filters}: NotificationActions.Count): Promise<void> {
    const response: DataBaseServiceResponse<number> = await firstValueFrom(this.baseService.count<INotification>(`${this.SERVER}/notifications/count`, filters));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({count: response.entity ?? 0});
  }

  @Action(NotificationActions.Reset)
  async reset({setState}: StateContext<INotificationState>, _: NotificationActions.Reset): Promise<void> {
    setState(_DEFAULT_NOTIFICATION);
  }
}
