export class ErrorException extends Error {
  custom: boolean;
  httpCode?: number;
  internalCode?: string;

  constructor(message: string = '', httpCode?: number, internalCode?: string) {
    super(message);
    this.custom = true;
    this.httpCode = httpCode;
    this.internalCode = internalCode;
  }
}
