import {Injectable} from '@angular/core';
import {Action, State, StateContext} from '@ngxs/store';
import {ISupplier} from '@supplier/interfaces/supplier.interface';
import {BaseService} from '@shared/services';
import {ErrorHandlerService} from '@shared/services/error-handler.service';
import {environment} from '@env/environment';

export interface ISupplierState {
  list: ISupplier[];
  supplierSelectedId: string;
}

const _DEFAULT_DATA: ISupplierState = {
  list: [],
  supplierSelectedId: '',
}

export namespace SupplierActions {
  export class Reset {
    static readonly type: string = '[Supplier] Reset';
  }
}

@State({
  name: 'SupplierState',
  defaults: _DEFAULT_DATA
})
@Injectable()
export class SupplierState {
  private readonly SERVER: string = environment.SERVER;
  constructor(
    private baseService: BaseService,
    private errorHandlerService: ErrorHandlerService,
  ) {
  }

  @Action(SupplierActions.Reset)
  reset({setState}: StateContext<ISupplierState>): void {
    setState(_DEFAULT_DATA);
  }
}
