import {Injectable} from '@angular/core';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {IMenuItem} from '@menu/interfaces/menu-item.interface';
import {ISidebarItem} from '@sidebar/interfaces';

export interface IMenuState {
  items: IMenuItem[];
  sidebarItems: ISidebarItem[];
}

export namespace MenuActions {
  export class Reset {
    static readonly type: string = '[Menu] Reset';
  }

  export class Set {
    static readonly type: string = '[Menu] Set';
    constructor(public items: IMenuItem[], public sidebarItems: ISidebarItem[]) {}
  }
}

const _DEFAULT_DATA: IMenuState = {
  items: [],
  sidebarItems: [],
}

@State<IMenuState>({
  name: 'MenuState',
  defaults: _DEFAULT_DATA
})
@Injectable()
export class MenuState {

  @Selector()
  static getItems({items}: IMenuState): IMenuItem[] {
    return items;
  }

  @Selector()
  static getSidebarItems({sidebarItems}: IMenuState): ISidebarItem[] {
    return sidebarItems;
  }
  @Action(MenuActions.Set)
  set({patchState}: StateContext<IMenuState>, {items, sidebarItems}: MenuActions.Set): void {
    patchState({items, sidebarItems});
  }

  @Action(MenuActions.Reset)
  reset({setState}: StateContext<IMenuState>): void {
    setState(_DEFAULT_DATA);
  }
}
