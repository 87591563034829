import {ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot} from '@angular/router';
import {inject} from '@angular/core';
import {OrganizationTypeEnum} from '@organization/enums';
import {StorageService} from '@shared/services';
import {StorageItemEnum} from '@shared/services/storage/enums/storage-item.enum';

export const checkIsBuyerGuard: CanActivateFn = async(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> => {
  const storageService: StorageService = inject(StorageService);
  const router: Router = inject(Router);

  const organizationType: OrganizationTypeEnum | null = storageService.get<OrganizationTypeEnum>(StorageItemEnum.ORGANIZATION_TYPE);
  if (organizationType && organizationType === OrganizationTypeEnum.BUYER) {
    return true;
  } else {
    router.navigate(['/', 'profile']).then();
    return true;
  }
}
