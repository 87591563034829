import {NzModalRef, NzModalService} from 'ng-zorro-antd/modal';

export function Delete(title: string = 'Eliminar', description: string = '¿Está seguro de que desea eliminar?', config?: {buttonOkText?: string, buttonCancelText?: string}) {
  return (target: any, propertyKey: string, propertyDescriptor: PropertyDescriptor): void => {
    const originalMethod = propertyDescriptor.value;

    propertyDescriptor.value = async function (...args: any[]): Promise<void> {
      const modalService: NzModalService = ((this as any)['nzModalService'] as NzModalService);
      if (!modalService) {
        console.warn('Inject NzModalService as nzModalService');
        return;
      }

      const modal: NzModalRef = modalService.confirm({
        nzOkDanger: true,
        nzTitle: `<h4 class="font-semibold text-gray-700">${title}</h4>`,
        nzContent: `<p class="text-gray-500">${description}</p>`,
        nzCancelText: config && config.buttonCancelText ? config?.buttonCancelText : 'Cancelar',
        nzOkText: config && config.buttonOkText ? config?.buttonOkText : 'Eliminar',
        nzOnOk: async (): Promise<void> => {
          try {
            modal.close();
            await originalMethod.apply(this, args);
          } catch (e: any) {
            throw e;
          }
        },
        nzOnCancel: (): void => {
          return;
        }
      });
    }
  }
}
