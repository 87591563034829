import {Action, Selector, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {BaseService, StorageService, UploadService} from '@shared/services';
import {ErrorHandlerService} from '@shared/services/error-handler.service';
import {environment} from '@env/environment';
import {DataBaseServiceResponse} from '@shared/services/base/interfaces/data-base-service-response.interface';
import {firstValueFrom} from 'rxjs';
import {PrismaFilter} from '@shared/services/base/interfaces/prisma-filter.interface';
import {IDocumentWithRelations} from '../interfaces/document.interface';
import {IStorageCredentials} from '@shared/services/storage/interfaces';
import {DocumentUtilClass} from '@file-record/document-util.class';
import {UtilityClass} from '@shared/class/utils';
import {IOpportunityState, OpportunityActions} from '@opportunity/state/opportunity.state';

export interface IDocumentState {
  organizations: IDocumentWithRelations[];
  users: IDocumentWithRelations[];

  organizationSelectedId: string;
  userSelectedId: string;

  organizationCount: number;
  userCount: number;
}

const _DEFAULT_DATA: IDocumentState = {
  organizations: [],
  users: [],

  organizationSelectedId: '',
  userSelectedId: '',

  organizationCount: 0,
  userCount: 0,
}

export namespace DocumentActions {

  /* ORGANIZATION ACTIONS */
  export class UploadOrganizationDocument {
    static readonly type: string = '[Document] Upload Organization Document';
    constructor(public documentTemplateId: string, public organizationId: string, public file: any) {
    }
  }

  export class GetOrganizationDocumentList {
    static readonly type: string = '[Document] Get Organization Document List';
    constructor(public id: string, public filters?: PrismaFilter<IDocumentWithRelations>) {
    }
  }

  export class DeleteOrganizationDocument {
    static readonly type: string = '[Document] Delete Organization Document';
    constructor(public organizationId: string, public documentId: string) {
    }
  }

  export class DownloadOrganizationDocument {
    static readonly type: string = '[Document] Download Organization Document';
    constructor(public organizationId: string, public documentId: string, public fileName: string, public mimeType: string) {
    }
  }
  /* ORGANIZATION ACTIONS */

  /* USER ACTIONS  */
  export class UploadUserDocument {
    static readonly type: string = '[Document] Upload User Document';
    constructor(public documentTemplateId: string, public organizationId: string, public userId: string, public file: any) {
    }
  }
  export class GetUserDocumentList {
    static readonly type: string = '[Document] Get User Document List';
    constructor(public id: string, public filters?: PrismaFilter<IDocumentWithRelations>) {
    }
  }

  export class DeleteUserDocument {
    static readonly type: string = '[Document] Delete User Document';
    constructor(public organizationId: string, public documentId: string) {
    }
  }

  export class DownloadUserDocument {
    static readonly type: string = '[Document] Download User Document';
    constructor(public userId: string, public documentId: string, public fileName: string, public mimeType: string) {
    }
  }

  /* USER ACTIONS */
  export class Reset {
    static readonly type: string = '[Opportunity] Reset Opportunity';
    constructor() {}
  }
}

@State<IDocumentState>({
  name: 'DocumentState',
  defaults: _DEFAULT_DATA
})
@Injectable()
export class DocumentState {
  private readonly SERVER: string = environment.SERVER;

  constructor(
    private baseService: BaseService,
    private errorHandlerService: ErrorHandlerService,
    private uploadService: UploadService,
    private storageService: StorageService,
  ) {}

  /* ORGANIZATION SELECTORS */
  @Selector()
  static getOrganizationDocumentList(state: IDocumentState): IDocumentWithRelations[] {
    return state.organizations;
  }

  @Selector()
  static getOrganizationDocumentSelected(state: IDocumentState): IDocumentWithRelations | undefined {
    return state.organizations.find((document: IDocumentWithRelations): boolean => document.id === state.organizationSelectedId);
  }

  @Selector()
  static getOrganizationCount(state: IDocumentState): number {
    return state.organizationCount;
  }
  /* ORGANIZATION SELECTORS */

  /* USER SELECTORS */
  @Selector()
  static getUserDocumentList(state: IDocumentState): IDocumentWithRelations[] {
    return state.users;
  }

  @Selector()
  static getUserDocumentSelected(state: IDocumentState): IDocumentWithRelations | undefined {
    return state.users.find((document: IDocumentWithRelations): boolean => document.id === state.userSelectedId);
  }

  @Selector()
  static getUserDocumentCount(state: IDocumentState): number {
    return state.userCount;
  }
  /* USER SELECTORS */

  /* ORGANIZATION METHODS */
  @Action(DocumentActions.UploadOrganizationDocument)
  async uploadOrganizationDocument({patchState, getState}: StateContext<IDocumentState>, {documentTemplateId, organizationId, file}: DocumentActions.UploadOrganizationDocument): Promise<void> {
    const response: DataBaseServiceResponse<any> = await this.uploadService.uploadRecordDocument<string>(`documents`, (file as File), documentTemplateId, organizationId);
    patchState({
      organizations: UtilityClass.updateOrPushItems(getState().organizations, response.entity, 'id'),
      organizationSelectedId: response.entity.id
    });
  }

  @Action(DocumentActions.GetOrganizationDocumentList)
  async getRecordDocuments({ patchState }: StateContext<IDocumentState>, {id, filters}: DocumentActions.GetOrganizationDocumentList): Promise<void> {
    const response: DataBaseServiceResponse<any> = await firstValueFrom(this.baseService.get<IDocumentWithRelations[]>(`${this.SERVER}/organizations/${id}/documents`, filters));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);
    patchState({
      organizations: response.entity
    });
  }

  @Action(DocumentActions.DeleteOrganizationDocument)
  async deleteRecordDocument({ patchState, getState }: StateContext<IDocumentState>, {organizationId, documentId}: DocumentActions.DeleteOrganizationDocument): Promise<void> {
    const response: DataBaseServiceResponse<any> = await firstValueFrom(this.baseService.delete<IDocumentWithRelations>(`${this.SERVER}/organizations/${organizationId}/documents/${documentId}`));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({
      organizations: UtilityClass.deleteItemByProp<IDocumentWithRelations>(getState().organizations, 'id', documentId ),
      organizationSelectedId: undefined,
    });
  }

  @Action(DocumentActions.DownloadOrganizationDocument)
  async downloadOrganizationDocument(
    _: StateContext<IDocumentState>,
    {organizationId, documentId, mimeType, fileName}: DocumentActions.DownloadOrganizationDocument
  ): Promise<void> {
    const response: DataBaseServiceResponse<Blob> = await firstValueFrom(this.baseService.download(`${this.SERVER}/organizations/${organizationId}/documents/${documentId}/download`, mimeType));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    DocumentUtilClass.download(response.entity!, fileName);
  }
  /* ORGANIZATION METHODS */

  /* USER METHODS */
  @Action(DocumentActions.UploadUserDocument)
  async uploadUserDocument({patchState, getState}: StateContext<IDocumentState>, {documentTemplateId, organizationId, userId, file}: DocumentActions.UploadUserDocument): Promise<void> {
    const response: DataBaseServiceResponse<any> = await this.uploadService.uploadRecordDocument<string>(`documents`, (file as File), documentTemplateId, organizationId, userId);
    patchState({
      users: UtilityClass.updateOrPushItems(getState().users, response.entity, 'id'),
      userSelectedId: response.entity.id
    });
  }
  @Action(DocumentActions.GetUserDocumentList)
  async getUserDocumentList({ patchState }: StateContext<IDocumentState>, {id, filters}: DocumentActions.GetUserDocumentList): Promise<void> {
    const response: DataBaseServiceResponse<any> = await firstValueFrom(this.baseService.get<IDocumentWithRelations[]>(`${this.SERVER}/users/${id}/documents`, filters));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);
    patchState({
      users: response.entity
    });
  }

  @Action(DocumentActions.DeleteUserDocument)
  async deleteUserDocument({ patchState, getState }: StateContext<IDocumentState>, {organizationId, documentId}: DocumentActions.DeleteUserDocument): Promise<void> {
    const response: DataBaseServiceResponse<any> = await firstValueFrom(this.baseService.delete<IDocumentWithRelations>(`${this.SERVER}/users/${organizationId}/documents/${documentId}`));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({
      users: UtilityClass.deleteItemByProp<IDocumentWithRelations>(getState().users, 'id', documentId ),
      userSelectedId: undefined,
    });
  }

  @Action(DocumentActions.DownloadUserDocument)
  async downloadUserDocument(
    _: StateContext<IDocumentState>,
    {userId, documentId, mimeType, fileName}: DocumentActions.DownloadUserDocument
  ): Promise<void> {
    const response: DataBaseServiceResponse<Blob> = await firstValueFrom(this.baseService.download(`${this.SERVER}/users/${userId}/documents/${documentId}/download`, mimeType));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    DocumentUtilClass.download(response.entity!, fileName);
  }
  /* USER METHODS */
  @Action(DocumentActions.Reset)
  async resetDocuments({setState}: StateContext<IDocumentState>): Promise<void> {
    setState(_DEFAULT_DATA);
  }
}
