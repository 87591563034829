import {NzMessageService} from 'ng-zorro-antd/message';
import {GeneralException} from '@shared/class/errors';
import {OrganizationService} from '@organization/services/organization.service';

export function IsValidated() {
  return function(target: any, propertyKey: string, propertyDescriptor: PropertyDescriptor): void {
    const method = propertyDescriptor.value;

    propertyDescriptor.value = async function (...args: any[]): Promise<void> {

      const nzMessageService: NzMessageService = ((this as any)['nzMessageService'] as NzMessageService);
      if (!nzMessageService) {
        console.warn('Inject NzMessageService as nzMessageService in ', this.constructor.name, ' class.');
      }

      const organizationService: OrganizationService = ((this as any)['organizationService'] as OrganizationService);
      if (!organizationService) {
        console.warn('Inject OrganizationService as organizationService in ', this.constructor.name, ' class.');
      }


      try {
        await organizationService?.isValid();
        await method.apply(this, args);
      } catch (ex: any) {
        nzMessageService?.create('info', ex ? ex.message : new GeneralException().message);
      }
    }
  }
}
