import {Pipe, PipeTransform} from "@angular/core"

@Pipe({
  name: 'addHTTPS',
  standalone: true,
})

export class AddHTTPSPipe implements PipeTransform {
  transform(url: string): string {
    // Verificar si la URL ya comienza con "http://" o "https://"
    if (!/^https?:\/\//i.test(url)) {
      // Si no, agregar "https://"
      return 'https://' + url;
    }
    // Si ya tiene "http://" o "https://", devolver la URL sin cambios
    return url;
  }
}
