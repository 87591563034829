import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'success-message',
  template: `
    <div class="p-2 bg-wz-light-green border border-wz-green flex items-center justify-between rounded text-wz-message-gray text-sm">
      <div class="flex items-center gap-2">
        <span nz-icon nzType="check-circle" nzTheme="fill" class="text-2xl flex text-wz-green"></span>
        <span>{{ this.message }}</span>
      </div>
      <i *ngIf="enableClose" class="las la-times text-xl text-wz-message-gray cursor-pointer" (click)="onClose()"></i>
    </div>
  `,
})
export class SuccessMessageComponent {
  @Input() icon: string = 'la-info-circle';
  @Input() enableClose: boolean = true;
  @Input({required: true}) message!: string;
  @Output() closeChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {
  }

  onClose(): void {
    this.closeChange.emit(true);
  }
}
