import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from '@shared/services';
import {environment} from '@env/environment';

/**
 *
 * @param route
 * @param state
 */
export const hasCredentialsGuard: CanActivateFn = async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> => {
  const [authService, router]: [AuthService, Router] = [inject(AuthService), inject(Router)];

  const hasSession: boolean = authService.hasCredentials();
  if (hasSession) return true;

  await router.navigate([environment.DEFAULT_PAGE_TO_LOAD]);
  return false;
};
