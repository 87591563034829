import {ErrorException} from '@shared/class/errors/error.exception';
import {ErrorTypeEnum} from '@shared/class/errors/enums/error-type.enum';

export class InternalClientException extends ErrorException {
  constructor(message: string = 'Error interno del cliente') {
    super(message);
    this.name = ErrorTypeEnum.INTERNAL_CLIENT;
    this.internalCode = 'INT-001';
  }
}
