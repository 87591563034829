import {Pipe, PipeTransform} from "@angular/core"
import {IDynamicFormRow} from '@shared/components/ui-elements/form-container/interfaces/dynamic-form.interface';

@Pipe({
  name: 'getVisibleDynamicRows',
  standalone: true,
})

export class GetVisibleDynamicRowsPipe implements PipeTransform {
  transform(rows: IDynamicFormRow[]): IDynamicFormRow[] {
    return rows.filter((row: IDynamicFormRow) => !row.hide);
  }
}
