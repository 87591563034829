import {Injectable} from '@angular/core';
import {Action, State, StateContext} from '@ngxs/store';
import {IBuyer} from '@buyer/interfaces/buyer.interface';

export interface IBuyerState {
  list: IBuyer[];
  buyerSelectedId: string;
}

const _DEFAULT_DATA: IBuyerState = {
  list: [],
  buyerSelectedId: '',
}

export namespace BuyerActions {
  export class Reset {
    static readonly type: string = '[Organization] Reset';
  }
}

@State({
  name: 'BuyerState',
  defaults: _DEFAULT_DATA
})
@Injectable()
export class BuyerState {
  constructor() {
  }

  @Action(BuyerActions.Reset)
  reset({setState}: StateContext<IBuyerState>): void {
    setState(_DEFAULT_DATA);
  }
}
