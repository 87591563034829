import {Pipe, PipeTransform} from '@angular/core';
import {IBudgetRequest, IBudgetRequestWithRelations, IQuotationVersion} from '@opportunity/interfaces';
import {OpportunityTypeEnum} from '@opportunity/enums';

@Pipe({
  name: 'hasBudgetRequest',
  standalone: true,
})
export class HasBudgetRequestPipe implements PipeTransform {
  transform(value: IBudgetRequestWithRelations[], type: OpportunityTypeEnum, id: string): IQuotationVersion | undefined {
    if (!value) return undefined;
    let budget: IBudgetRequestWithRelations | undefined;
    if (type === OpportunityTypeEnum.PRODUCT) {
      budget = value.find((br: IBudgetRequestWithRelations): boolean => br.productId === id);
    } else if (type === OpportunityTypeEnum.SERVICE) {
      budget = value.find((br: IBudgetRequestWithRelations): boolean => br.serviceId === id);
    }
    return budget && budget.QuotationVersion ? budget.QuotationVersion[0] : undefined;
  }
}
