export namespace SERVER_MESSAGE {
  const MESSAGES: string[] = [
    '(US001) User not found.',
    '(US002) User email already exists.',
    '(US003) User cannot be deleted.',
    '(US004) User is already an administrator.',
    '(UPA001) User profile already exists.',
    '(UPA002) User profile not found.',
    '(UPP001) User requiere at least one phone',
    '(UT001) User token not found.',
    '(UT002) User token expired.',
    '(TPL0001) DocumentTemplate requires at least one extension',
    '(DOC0001) Document not found',
    '(DOC0002) Document requieres the user',
    '(DOC0003) Document required',
    '(RSVP001) RsvpOrganization not found',
    '(RSVP002) RsvpOrganization Previously confirmed',
    '(ORG001) Organization not found.',
    '(ORG002) Only one organization per user (owner) is allowed.',
    '(ORG003) Organization already exists.',
    '(ORG004) Only one organization per user (member) is allowed.',
    '(ORG005) User is already member of this organization.',
    '(ORG007) Organization already accepted terms and conditions.',
    '(ORG008) The organization has already been accepted.',
    '(ORG009) The organization has already been rejected.',
    '(ORG010) The organization is blacklisted.',
    '(ORG11) The organization is not a supplier',
    '(ORG12) RFC must be unique',
    '(ORG14) Organization not validated',
    '(ORG15) Organization has already been eliminated',
    '(ORG16) Organization deleted',
    '(ORG17) Requires at least one address.',
    '(ORG18) RFC is required',
    '(ORG19) Organization requires to be corporate person',
    '(OC001) Contact not found.',
    '(CER001) certificationId and customCertificationName cannot be defined at the same time.',
    '(SNUM001) OrganizationSupplierNumber already exists',
    '(SNUM002) OrganizationSupplierNumber Invalid date range',
    '(GTO001) GTO Invalid folio',
    '(GTO002) GTO folio not found',
    '(GTO003) Folio not sent',
    '(MEM001) Organization member not found.',
    '(MEM002) Organization member cannot be deleted.',
    '(MEM003) Organization member belongs to another organization.',
    '(ROL001) Role not found.',
    '(ROL002) Role already exists.',
    '(ROL003) System role cannot be deleted.',
    '(ROL004) Role has users assigned.',
    '(ROL005) Role name may be unique.',
    '(ROL006) System role cannot be updated.',
    '(PH001) Phone number not found.',
    '(ADD001) Address not found.',
    '(ADD002) State must be valid.',
    '(ADD003) Country must be valid.',
    '(ADD004) City must be valid.',
    '(ADD005) State is required.',
    '(ADD006) Country is required.',
    '(ADD007) City is required.',
    '(PRO001) Product not found.',
    '(PRO002) Product could not be published',
    '(PRO003) Product could not be rejected',
    '(PRO004) Product could not be draft',
    '(PRO005) Product could not be updated',
    '(PRO006) Product does not belong to the supplier',
    '(PRO007) Service does not belong to the supplier',
    '(SRV001) Service not found.',
    '(SRV002) Service could not be published',
    '(SRV003) Service could not be rejected',
    '(SRV004) Service could not be draft',
    '(SRV005) Service could not be updated',
    '(OPP001) Delivery date must be at least 2 days from now. Min date: ',
    '(OPP002) Opportunity not found',
    '(OPP003) Finalized Opportunity',
    '(OPH002) OrganizationPhoneNumber cannot be deleted.',
    '(MAT001) OpportunityMatch not found',
    '(REQ001) BudgetRequest not found',
    '(REQ002) BudgetRequest has already been responded to',
    '(REQ003) BudgetRequest was previously requested',
    '(REQ004) BudgetRequest has expired',
    '(QUO001) QuotationVersion not found',
    '(QUO002) QuotationVersion unauthorized response',
    '(QUO003) QuotationVersion has not yet been responded to',
    '(JOIN001) JoinOpportunityRequest previously requested',
    '(JOIN002) Cannot apply for opportunities in your organization',
    '(JOIN003) JoinOpportunityRequest not found',
    '(OR001) OpportunityResolution not found',
    '(OAS001) OpportunityAcceptedSupplier not found',
    '(OAS002) OpportunityAcceptedSupplier already been registered before',
    '(EVE001) Event not found',
    '(EVE002) Event can not published',
    '(EVE003) Event can not archived',
    '(EFG001) EventFeaturedGuest not found',
    '(EFG002) EventFeaturedGuest cannot be deleted.',
    '(EFG003) Duplicate EventFeaturedGuest.',
    '(DAT001) Invalid date range',
    '(DAT002) Overlap in dates',
    '(DAT003) EventDate cannot be deleted.',
    '(DAT004) EventDate can not make changes',
    '(CR001) ConnectionRequest not found',
    '(CR002) ConnectionRequest was terminated previously.',
    '(CR003) ConnectionRequest was canceled previously.',
    '(CR004) meetUrl not sent',
    '(CR005) Opportunity does not belong to the host organization',
    '(CR006) Organizations have to be different',
    '(CR007) Supplier does not belong to the opportunity',
    '(CR008) ConnectionRequest already have an active',
    '(CR009) ConnectionRequest has ended or been canceled',
    '(CR010) ConnectionRequest has not ended or canceled',
    '(CR011) ConnectionRequest type cannot be changed',
    '(CR012) ConnectionRequest does not belong to the user',
    '(CR013) ConnectionRequest can not cancelled',
    '(PCD001) ProposedConnectionDate not available.',
    '(PCD002) ProposedConnectionDate has already been accepted by the organization',
    '(PCD003) Invalid date range',
    '(PCD004) Overlap in dates',
    '(PCD005) ProposedConnectionDate not available in event',
    '(PCD006) ProposedConnectionDate cannot be deleted.',
    '(PCD007) ProposedConnectionDate not found.',
    '(CRF001) ConnectionRequestFeedback has already been responded',
    '(GA001) GovernmentAgency not found',
    '(OGA002) GovernmentAgency belongs to an organization',
    '(OTP001) OtpCode not found',
    '(OTP002) OtpCode is invalid',
    '(TRA001) Training not found',
    '(TRA002) Can not participate',
    '(TRR001) TrainingResponse not found',
    '(TRR002) Already applied to the training',
    '(TRR003) Accepted date not found',
    '(TRR004) Date not available',

    // OTHERS
    '(L001) Invalid credentials.',
    '(L002) Invalid refresh token.',
    '(L003) Expired refresh token.',

    // CUSTOM
    '(US0001) lastName must be longer than or equal to 3 characters',
    '(US0002) name must be longer than or equal to 3 characters',
    '(US0003) password must be longer than or equal to 8 characters',
    '(US0004) password is not strong enough',
    '(ORG121) The rfc must be a valid RFC for a Persona Física.',
    '(ORG122) The rfc must be a valid RFC for a Persona Moral.',
    '(ORG123) The rfc must be a valid RFC (Mexican sat).',
    '(ORG124) email must be an email',
    '(FILE001) File is required',

  ];

  export interface IServerMessage {
    code: string;
    shortCode: string;
    message: string;
    shortMessage: string;
  }

  export const find = (message: string): IServerMessage | null => {
    const serverMessage: string | null = MESSAGES.find((msj: string) => msj.includes(message)) ?? null;
    if (!serverMessage) return null;

    const code: string = serverMessage.substring(serverMessage.indexOf('('), serverMessage.indexOf(')') + 1);
    const shortCode: string = serverMessage.substring(serverMessage.indexOf('(') + 1, serverMessage.indexOf(')'));
    const shortMessage: string = serverMessage.substring(serverMessage.indexOf(')' + 1)).trim();
    return { code, shortCode, message: serverMessage, shortMessage };
  }
}
